import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import * as d3 from 'd3';
import { useNavigate } from "react-router-dom";
import IconMaleOutline from '../../assets/male-outline.svg';
import IconFemaleOutline from '../../assets/female-outline.svg';
import IconUnknownOutline from '../../assets/help-outline.svg';
import logoCatDefault from "../../assets/kappze_logo_without_square_bw.png";
import Scrollbar from 'smooth-scrollbar';

const FamilyTree = ({ currentAnimalId }) => {
  const containerRef = useRef();
  const { data: animals } = useSelector((state) => state.animals);
  const navigate = useNavigate();
  const svgRef = useRef();
  const isSmallScreen = window.innerWidth < 768; // Vous pouvez ajuster cette valeur selon votre préférence.


  const buildHierarchy = (animalId) => {
    const animal = animals.find(a => a.id === animalId);
    const children = animals.filter(a => a.motherAppId === animalId);
    return {
      ...animal,
      children: children.map(c => buildHierarchy(c.id)),
    };
  }

  useEffect(() => {
    d3.select(svgRef.current).selectAll("*").remove();
    const scrollbar = Scrollbar.init(containerRef.current);
    const rootAnimal = currentAnimalId ? findRootAnimal(currentAnimalId) : animals.find(animal => !animal.motherAppId);


    if (!rootAnimal) {
      return;
    }


    let root = d3.hierarchy(buildHierarchy(rootAnimal.id), d => d.children);
    const nodeSize = isSmallScreen ? [60, 120] : [110, 200]; // Des valeurs plus petites pour les petits écrans
    const treeLayout = d3.tree().nodeSize(nodeSize);
    treeLayout(root);

    let minX = Infinity;
    let maxX = -Infinity;
    root.each(node => {
      if (node.x < minX) minX = node.x;
      if (node.x > maxX) maxX = node.x;
    });

    const containerHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;
    const svgWidth = Math.max(viewportWidth);
    const offsetX = (viewportWidth - (maxX - minX)) / 2 - minX;
    const imageDimensions = isSmallScreen ? { width: '30px', height: '30px' } : { width: '50px', height: '50px' };
    const imagePosition = isSmallScreen ? { transform: 'translate(-15, -25)' } : { transform: 'translate(-25, -25)'};
    const sexDimensions = isSmallScreen ? { width: '10px', height: '10px' } : { width: '20px', height: '20px' };
    const sexPosition = isSmallScreen ? { transform: 'translate(10, -5)' } : { transform: 'translate(30, 0)'};
    const textPosition = isSmallScreen ? { x: '-80', y: '5' } : { x: '-75', y: '25' };
    const fontSize = isSmallScreen ? '12px' : '16px'; // Des valeurs plus petites pour les petits écrans




    const svg = d3.select(svgRef.current)
      .attr("width", svgWidth)
      .attr("height", containerHeight)
      .attr('maxWidth', '100%');

    const g = svg.append('g')
      .attr('transform', `translate(${offsetX}, 50)`);

    g.selectAll('.link')
      .data(root.links())
      .enter().append('path')
      .attr('class', 'link')
      .attr('d', d3.linkVertical()
        .x(d => d.x)
        .y(d => d.y));

    const node = g.selectAll('.node')
      .data(root.descendants())
      .enter().append('g')
      .attr('class', d => 'node' + (d.children ? ' node--internal' : ' node--leaf'))
      .attr('class', d => 'text-hover-style')
      .attr('transform', d => `translate(${d.x},${d.y})`);

    node.each(function (d) {
      d3.select(this).append('image')
        .attr('xlink:href', d.data.image.url ? d.data.image.url : logoCatDefault)
        .attr('clip-path', 'url(#clipCircle)')
        .attr('transform', imagePosition.transform)
        .attr('width', imageDimensions.width)
        .attr('height', imageDimensions.height)
        .style('filter', d => d.data.death ? 'grayscale(100%)' : '')
        .on('click', () => {
          navigate(`/animal/${d.data.id}`);
        })
        .style('cursor', 'pointer');

      const icon = d.data.sex === 'Mâle' ? IconMaleOutline : d.data.sex === 'Femelle' ? IconFemaleOutline : IconUnknownOutline;
      d3.select(this).append('image')
        .attr('xlink:href', icon)
        .attr('transform', sexPosition.transform)
        .attr('width', sexDimensions.width)
        .attr('height', sexDimensions.height)
      d3.select(this)
        .append('foreignObject')
        .attr('x', textPosition.x)
        .attr('y', textPosition.y) 
        .attr('width', '150')
        .attr('height', '50')
        .append('xhtml:div')  // On ajoute une div HTML à l'intérieur de l'élément foreignObject
        .style('background-color', d => d.data.id === currentAnimalId ? 'transparent' : 'transparent')
        .style('padding', '5px 5px')  // Ajout du padding autour du texte
        .style('font-family', 'Wix Madefor Display')
        .style('font-weight', '600')
        .style('font-size', fontSize)
        .style('cursor', 'pointer')
        .style('color', d => d.data.id === currentAnimalId ? '#d15e41' : d.data.death ? '#777'  : '#fff')
        .style('text-align', 'center')
        .text(d => d.data.name)
        .on('click', () => {
          navigate(`/animal/${d.data.id}`);
        })

    });
    return () => {
      scrollbar.destroy();
    };
  }, [animals, currentAnimalId]);

  const findRootAnimal = (animalId) => {
    let currentAnimal = animals.find(animal => animal.id === animalId);
    while (currentAnimal && currentAnimal.motherAppId) {
      currentAnimal = animals.find(animal => animal.id === currentAnimal.motherAppId);
    }
    return currentAnimal;
  };



  return (
    <div ref={containerRef} className="container-genealogy" style={{ width: '100%', height: '100%', overflow: 'auto', background: 'transparent', borderRadius: '2px' }}>
      <svg ref={svgRef}></svg>
    </div>
  );
}



export default FamilyTree;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchComments, addComment, deleteComment, updateComment } from '../../features/animals/commentsSlice';
import Swal from 'sweetalert2';
import EditableField from './EditableField';
import { createAndSendNotification } from '../../features/notifications/notificationSlice'
import { useTranslation } from 'react-i18next';


const CommentForm = ({ animalId, animalName, licenceStatus, canalId, canalName }) => {
    const { t } = useTranslation();
    const { surname, uid } = useSelector((state) => state.auth);
    const { data: users } = useSelector((state) => state.canalUsers)
    const dispatch = useDispatch();
    const [comment, setComment] = useState('');
    const [userIsAdmin, setUserRole] = useState({});

    useEffect(() => {
        // Initialiser userRoles avec les rôles actuels des utilisateurs
        users.forEach(user => {

            if (user.id === uid) {
                setUserRole(user.isAdmin);
            }
        });


    }, [users]);


    const handleInputChange = (e) => {
        setComment(e.target.value);
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();

        if (comment.trim()) {
            dispatch(addComment({ animalId, comment: { texte: comment, auteur: surname }, uid }));
            setComment('');
            const message = `${t('notifications.newCommentOn')}` + animalName + ' sur le canal ' + canalName;
            const userIds = [];
            const subject = 'Un nouveau commentaire publique a été posté sur Kappze - ' + canalName;
            // const authorized = true;
            users.forEach(user => {
                if(user.licenceStatus === 'active' || (user.isAdmin === true && user.notificationsAdminPreferences?.[canalId] === true)) {
                    userIds.push(user?.id)
                }
            });
            dispatch(createAndSendNotification({ userIds, message, subject }));
        }


    };

    // if ((!licenceStatus && !userIsAdmin)) {
    //     return (
    //         <div className='form_kappze-premium' style={{ position: 'relative', background: 'transparent' }}>
    //             <form style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column', width: '100%' }}>
    //                 <textarea
    //                     placeholder={`${t('addAComment')}`}
    //                     value={comment}
    //                     onChange={handleInputChange}
    //                     rows="4" cols="50"
    //                     style={{ borderRadius: '4px', width: '100%', boxSizing: 'border-box' }}
    //                 />
    //                 <button type="submit" style={{ alignSelf: 'flex-end', marginRight: '0', background: '#122' }}>
    //                     Commenter
    //                 </button>
    //             </form>

    //             <div className="overlay" style={{
    //                 position: 'absolute',
    //                 top: 0,
    //                 left: 0,
    //                 right: 0,
    //                 bottom: 0,
    //                 backgroundColor: 'rgba(0, 0, 0, 0.9)',
    //                 display: 'flex',
    //                 justifyContent: 'center',
    //                 alignItems: 'center',
    //                 zIndex: 2
    //             }}>
    //                 <div className="overlay-message" style={{ color: 'white', fontSize: '20px', textAlign: 'center' }}>
    //                     Ce contenu est réservé aux abonnés Kappze+.
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }
    return (
        <form style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column', width: '100%' }} onSubmit={handleFormSubmit}>
            <textarea
                placeholder={`${t('addAComment')}`}
                value={comment}
                onChange={handleInputChange}
                rows="4" cols="50"
                style={{ borderRadius: '4px', width: '100%', boxSizing: 'border-box' }}
            />
            <button type="submit" style={{ alignSelf: 'flex-end', marginRight: '0' }}>
                Commenter
            </button>
        </form>
    );
};

const CommentList = ({ animalId }) => {
    const { t } = useTranslation();
    const { uid } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const comments = useSelector(state => state.comments);
    const { data: users } = useSelector((state) => state.canalUsers);
    const [userIsAdmin, setUserRole] = useState({});

    useEffect(() => {
        // Initialiser userRoles avec les rôles actuels des utilisateurs
        users.forEach(user => {

            if (user.id === uid) {
                setUserRole(user.isAdmin);
            }
        });


    }, [users]);

    useEffect(() => {
        dispatch(fetchComments(animalId));
    }, [dispatch, animalId]);

    const handleDelete = (commentId) => {
        Swal.fire({
            icon: 'warning',
            title: `${t('modal.attention')}`,
            text: `${t('modal.deleteCommentText')}`,
            showCancelButton: true,
            confirmButtonColor: '#8B0000',
            cancelButtonColor: '#2F4F4F',
            confirmButtonText: 'Supprimer',
            cancelButtonText: 'Annuler'
        })
            .then((result) => {
                if (result.isConfirmed) {
                    dispatch(deleteComment({ animalId, commentId }));
                }
            })
            .catch((error) => {
                // console.log(error);
                Swal.fire({
                    icon: 'error',
                    title: `${t('modal.error')}`,
                    text: `${t('modal.deleteCommentError')} ${error.message}`,
                    confirmButtonColor: '#2F4F4F',
                    confirmButtonText: 'OK'
                })
            });
    };

    const handleUpdateComment = (commentId, updatedText) => {
        dispatch(updateComment({ animalId, commentId, updatedText }));
    };

    if (comments.length === 0) {
        return (
            <>
                <p>Il n'y a pas encore de commentaires.</p>
            </>
        )
    }
    return (
        <ul style={{ color: 'black', display: 'flex', flexDirection: 'column', rowGap: '1rem', margin: '0', padding: '0' }}>
            {comments.map((comment) => (
                <li style={{ listStyle: 'none', color: '#000', backgroundColor: '#2F2F2F', borderRadius: '3px', padding: 15, marginLeft: 'none', fontSize: '14px' }} key={comment.id}>
                    <EditableField
                        label="Modifier le commentaire"
                        value={comment.texte}
                        onSave={(value) => handleUpdateComment(comment.id, value)}
                        isAdmin={userIsAdmin}
                        currentUserId={uid}
                        commentAuthorId={comment.authorId}
                    />
                    <small style={{ color: '#fff' }}>Par {comment.auteur}, </small>
                    <small style={{ color: '#fff' }}>le {new Date(comment.horodatage).toLocaleString()}</small>

                    {(comment.authorId === uid || userIsAdmin) && (
                        <button style={{ fontSize: '11px' }} onClick={() => handleDelete(comment.id)}>Supprimer</button>
                    )}
                </li>
            ))}
        </ul>
    );
}
const CommentSection = ({ animalId, animalName, licenceStatus, userIsAdmin }) => (
    <div style={{ backgroundColor: 'transparent', display: 'flex', flexDirection: 'column', rowGap: '1rem', width: '100%', marginTop: '1rem' }}>
        <CommentList animalId={animalId} animalName={animalName} />
        <CommentForm animalId={animalId} animalName={animalName} licenceStatus={licenceStatus?.status} />


    </div>
);

export default CommentSection;

import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { current } from '@reduxjs/toolkit';

const AssociationSelect = ({
  idPrefix,
  selectedAssociationCat,
  onAssociationChange,
  needsLabel,
  isEditMode,
  currentSituationAnimal,
  associations,
  animalId
}) => {

    const [selectedAssociation, setSelectedAssociation] = useState(null)
    const { t } = useTranslation();

  //   const associationOptions = [
  //     { value: '', label: 'Non-défini' }, // Option "Non-défini"
  //     ...associations.map(assoc => ({
  //         value: assoc.rna || assoc.siretAsso, // Priorité au RNA, sinon on prend le SIRET
  //         label: assoc.name
  //     }))
  // ];
  
    // Fonction pour filtrer les doublons par 'rna' ou 'siretAsso'
    const uniqueAssociations = associations.reduce((acc, assoc) => {
      const uniqueKey = assoc.rna || assoc.siretAsso; // Priorité au RNA, sinon on prend le SIRET
      if (!acc.find(item => (item.rna || item.siretAsso) === uniqueKey)) {
        acc.push(assoc);
      }
      return acc;
    }, []);
  
    const associationOptions = [
      { value: '', label: 'Non-défini' }, // Option "Non-défini"
      ...uniqueAssociations.map(assoc => ({
        value: assoc.rna || assoc.siretAsso,
        label: assoc.name
      }))
    ];
      



      


  const handleAssociationChange = (selectedOption) => {
    setSelectedAssociation(selectedOption); // Mise à jour de l'état local
    onAssociationChange(selectedOption?.value, selectedOption?.label);
    // Ajouter tout callback nécessaire si vous devez remonter l'info à un parent ou déclencher des actions
  };

  return (
    <div style={{ maxWidth: '500px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
      {needsLabel && <label htmlFor={`${idPrefix}-association`}>Sélectionner une association</label>}
      <Select
        id={`${idPrefix}-association`}
        options={associationOptions}
        value={selectedAssociation}
        onChange={handleAssociationChange}
        placeholder="Choisir une association"
        styles={customStyles}
        className={`select-update ${isEditMode ? 'select-visible' : 'select-hidden'}`}
      />
  
      <p className={`select-update ${isEditMode ? 'select-hidden' : 'select-visible'}`} style={{ color: '#000' }}>
        {currentSituationAnimal ? currentSituationAnimal : t('undefined')}
      </p>
    </div>
  );
};

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'black',
    backgroundColor: state.isSelected ? '#2F2F2F' : 'white',
    '&:hover': {
      backgroundColor: state.isSelected ? '#2F2F2F' : 'lightgray',
    },

  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'black',
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: '2px', // ou toute autre valeur que vous préférez
  }),
};

export default AssociationSelect;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { store } from './store/store';
import './i18n';
import ErrorBoundary from './components/general/ErrorBoundary';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));


if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js').then(registration => {
      console.log('Service Worker registered with scope:', registration.scope);
    }, error => {
      console.log('Service Worker registration failed:', error);
    });
  });
}


const renderApp = () => {
  if (process.env.NODE_ENV === 'production') {
    console.log('Running in production mode with ErrorBoundary');
    return (
   
        // <ErrorBoundary>
          <App />
        // </ErrorBoundary>
   
    );
  } else {
    console.log('Running in development mode without ErrorBoundary');
    return (

        // <ErrorBoundary>
          <App />
        // </ErrorBoundary>
    );
 
  }
};

root.render(
  // <React.StrictMode>
  renderApp()
  // </React.StrictMode>
);

const firebaseConfig = {
  apiKey: "AIzaSyDIOJzdyCTv51JQCP_E6Lo3tGxR2yXVijM",
  authDomain: "kappze.firebaseapp.com",
  projectId: "kappze",
  storageBucket: "kappze.appspot.com",
  messagingSenderId: "169421691212",
  appId: "1:169421691212:web:c3f8045cf6e7342655d7e9"
};

firebase.initializeApp(firebaseConfig);

firebase.auth().onAuthStateChanged(user => {
  if (user) {
    // l'utilisateur est connecté
    // Dispatch actions to update redux state here
    store.dispatch({ type: 'AUTH_STATE_UPDATED', payload: user });
  } else {
    // l'utilisateur n'est pas connecté ou le token a expiré
    store.dispatch({ type: 'AUTH_STATE_LOGGED_OUT' });
  }
});

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  .then(() => {
    // Écoute les changements d'état d'authentification
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        // l'utilisateur est connecté, dispatch vers Redux
        store.dispatch({ type: 'AUTH_STATE_UPDATED', payload: user });
      } else {
        // l'utilisateur est déconnecté, dispatch vers Redux
        store.dispatch({ type: 'AUTH_STATE_LOGGED_OUT' });
      }
    });
  })
  .catch(error => {
    console.error("Erreur lors de la mise en place de la persistance d'authentification", error);
  });


// export const firestore = firebase.firestore();
export const firebaseInstance = firebase;


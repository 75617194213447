import AddCityForm from '../../components/citiesSector/AddCity.jsx'


const CityForm = () => {
    return (
        <div style={{padding: '3rem'}} className=' kappze-page kappze-paw' >
            <AddCityForm needsReturn={true}/>
        </div>
    );
};

export default CityForm;
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addCitySector } from '../../features/citiesSector/citySectorSlice';
import Swal from 'sweetalert2';
import iconcitySector from '../../assets/trail-sign-outline.svg';
import iconadd from '../../assets/icon-add.svg';
import sectorOutline from '../../assets/trail-sign-outline-black.svg'
import { useTranslation } from 'react-i18next';


const AddCitySectorModal = (props) => {
    const { t } = useTranslation();
    const { uid } = useSelector((state) => state.auth);
    const [showSectorPopup, setShowSectorPopup] = useState(false);
    const [animationClass, setAnimationClass] = useState('');
    const [sectorName, setSectorName] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleShowSectorPopup = () => {

        setAnimationClass('popup-entering');
        setShowSectorPopup(true);;
        setTimeout(() => {
            setAnimationClass('');
        }, 500);
    };

    const handleCloseSectorPopup = () => {

        setAnimationClass('popup-exiting');

        setTimeout(() => {


            setShowSectorPopup(false);


        }, 450);

        setTimeout(() => {


            setAnimationClass(null);

        }, 500);


    };


    const handleConfirm = () => {
        if (!sectorName) {
            alert(`Veuillez entrer le Nom du secteur`);
        } else {

            handleSubmit(sectorName);

            handleCloseSectorPopup();
        }
    };

    const handleSubmit = (name) => {
        const data = {
            userId: uid,
            name: name,
            canalId: props.canalId
        };

        dispatch(addCitySector(data))
            .then(() => {
                if (props.needsReturn) {

                    Swal.fire({
                        icon: 'success',
                        title: 'Succès',
                        text: "Le canal a été ajouté avec succès.",
                        confirmButtonColor: '#2F4F4F',
                        confirmButtonText: 'OK',
                        showClass: {
                            popup: 'animate__animated animate__fadeIn'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__fadeOut'
                        }
                    }).then(() => {
                        navigate(-1, { from: navigate.location });
                    });
                } else {
                    Swal.fire({
                        icon: 'success',
                        title: 'Succès',
                        text: "Le canal a été ajouté avec succès.",
                        confirmButtonColor: '#2F4F4F',
                        confirmButtonText: 'OK',
                        showClass: {
                            popup: 'animate__animated animate__fadeIn'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__fadeOut'
                        }
                    }).then(() => {
                        handleCloseSectorPopup();
                    });

                }
            })
            .catch((error) => {
                console.error('Error adding canal: ', error);
            });
    };


    return (
        <>
            <button className="button-general" onClick={handleShowSectorPopup} disabled={!props.licenceStatus || props.licenceStatus === 'inactive'}>
                <span className='button-general-link'>
                    <span style={{ marginRight: '16px' }}>{t("addSector")}</span>
                    <img style={{ width: '22px', marginLeft: '5px' }} src={iconcitySector} alt="Icon CitySector" className="button-icon" />
                    <img style={{ width: '10px', margin: '0px' }} src={iconadd} alt="Icon Add" className="button-icon" />
                </span></button>


            {showSectorPopup && (
                <div className={`popup-container ${animationClass}`}>
                    <div className="popup">
                        <img src={sectorOutline} style={{ maxWidth: '50px', marginBottom: 0, paddingBottom: 0, marginTop: '10px' }} />
                        <h2 className='editable-fields-label' style={{ marginTop: 0 }}>Ajouter un secteur</h2>
                        <div className="popup-singleinput" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                            <input id="citySector-name"
                                value={sectorName}
                                onChange={(e) => setSectorName(e.target.value)} placeholder='Nom du secteur' style={{ padding: '10px' }} />
                        </div>
                        <div className="popup-btns" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <button className="btn-cancel" onClick={handleCloseSectorPopup}>Annuler</button>
                            <button className="btn-validate" onClick={handleConfirm}>Valider</button>
                        </div>
                    </div>
                </div>
            )}

        </>
    )
};

export default AddCitySectorModal;

import { onSnapshot, doc } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';
import { setSubscriptionId, logout, setLicenceStatus } from '../features/user/userSlice';

const subscriptionListenerMiddleware = (store) => {
  let unsubscribe = null; // Stocker la référence à l'écouteur pour le nettoyer plus tard

  return (next) => (action) => {
    // Détecter quand l'utilisateur est connecté ou l'état réinitialisé
    if (action.type === 'auth/loginSuccess') {
      const state = store.getState();
      const userId = state.auth.uid;

      if (userId) {
        const db = getFirestore();
        const userDocRef = doc(db, 'users', userId);

        // Mettre en place l'écouteur en temps réel
        unsubscribe = onSnapshot(userDocRef, (doc) => {
          if (doc.exists()) {
            const data = doc.data();
            store.dispatch(setSubscriptionId(data.subscriptionId || null));
            store.dispatch(setLicenceStatus(data.licenceStatus || null));
          }
        });
      }
    }

    // Nettoyer l'écouteur lors de la déconnexion
    if (action.type === 'auth/logout' || action.type === 'RESET_STORE') {
      if (unsubscribe) {
        unsubscribe(); // Désactiver l'écouteur Firestore
        unsubscribe = null;
      }
    }

    return next(action);
  };
};

export default subscriptionListenerMiddleware;

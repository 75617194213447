import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ComparePostalCodeDataAsso } from '../../features/siret/siretSlice.js';

const AssosListByCp = ({ handleConfirm, canals, users }) => {
  const [codePostal, setCodePostal] = useState('');
  const [assosDisponibles, setAssosDisponibles] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Nouvel état pour le chargement
  const dispatch = useDispatch();

  const handleChangeCodePostal = (e) => {
    const newCodePostal = e.target.value;
    setCodePostal(newCodePostal);
    setIsLoading(true); // Commence le chargement

    if (newCodePostal.length === 5) {
      dispatch(ComparePostalCodeDataAsso(newCodePostal))
        .then(response => {
          const assosAccessibles = response.payload.filter(canal => canal.associationReachable);
          setAssosDisponibles(assosAccessibles);
          setIsLoading(false); // Arrête le chargement

        
        })
        .catch(error => {
          console.error("Error fetching associations:", error);
          setAssosDisponibles([]);
          setIsLoading(false); // Arrête le chargement en cas d'erreur
        });
    } else {
      setAssosDisponibles([]); // Ensure a reset when the postal code is invalid
      setIsLoading(false); // Pas de chargement si le code postal est invalide
    }
  };

  // Check if the user is already a member of the canal
  const isUserAlreadyInCanal = (codeCommune) => {
    return users.some(user => user.communeCodeAsso === codeCommune);
  };

  return (
    <div className="canalsListContainer">
      <input
        type="text"
        value={codePostal}
        onChange={handleChangeCodePostal}
        placeholder="Ecrivez le code postal ici"
        className='citySelectPostalCode citiesListByCpInput'
      />
      <div className="canalsListCities">
        {isLoading ? (
          <div style={{alignSelf: 'center'}}><div className="loaderPayment"></div></div>
        ) : assosDisponibles.length > 0 ? (
          assosDisponibles.map((user, index) => (
            <div key={index} className="canalItem">
              <span style={{color: '#000'}}>{user.associationName} - {user.communeLibelleAsso} ({user.postalCodeAsso})</span>
              <button
                onClick={(e) => handleConfirm(e, user.communeCodeAsso, user.id)}
                disabled={isUserAlreadyInCanal(user.communeCodeAsso)}
                className={isUserAlreadyInCanal(user.communeCodeAsso) ? 'joinedButton' : 'joinButton'}
              >
                {isUserAlreadyInCanal(user.communeCodeAsso) ? 'Déjà ajouté' : 'Ajouter'}
              </button>
            </div>
          ))
        ) : codePostal.length === 5 ? (
            <div><p style={{color:'#000'}}>Aucune association trouvée pour ce code postal.</p></div>
          ) : (
            <div><p style={{color:'#000', textAlign: 'center', fontWeight: '500'}}>Les résultats s'afficheront ici.</p></div>
        )}
        <p style={{color: '#000'}}>Votre association n'est pas dans la liste ? Parlez-en à votre association !</p>
      </div>
    </div>
  );
};

export default AssosListByCp;

// Dans votre composant React pour la page /email-verified
import React, { useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';

const EmailVerified = () => {
  useEffect(() => {
    const verifyEmailStatus = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        await user.reload();
        if (user.emailVerified) {
          const db = getFirestore();
          const userRef = doc(db, 'users', user.uid);
          await updateDoc(userRef, {
            emailWasVerified: true
          });
        }
      }
    };

    verifyEmailStatus();
  }, []);

  return (
    <div>
      <p style={{color: '#fff'}}>Votre email a été vérifié avec succès. Merci de confirmer.</p>
    </div>
  );
};

export default EmailVerified;

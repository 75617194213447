import React, { useState, useEffect } from 'react';
import CanalsListByCp from '../../components/general/CitiesListByCp';// Assurez-vous que le chemin d'importation est correct
import iconhouseBlack from '../../assets/icon-house-black.svg'; // Assurez-vous d'importer correctement les ressources nécessaires

const JoinCanalPopup = ({ show, handleClose, handleConfirm, canals, animation }) => {
    

    const [nomCommune, setNomCommune] = useState('');
    const [codeCommune, setCodeCommune] = useState('');
    const [postalCode, setPostalCode] = useState('')
    const [nameCanal, setNameCanal] = useState('');
        // Mise à jour de nameCanal lorsque nomCommune change
    useEffect(() => {
        if (!nameCanal || nomCommune === nameCanal) {
            setNameCanal(nomCommune);
        }
    }, [nomCommune, nameCanal]);

    useEffect(() => {
        // Met à jour nameCanal pour refléter le nouveau nomCommune chaque fois que nomCommune change.
        // Vous pouvez également ajouter une logique supplémentaire ici si nécessaire pour gérer les changements de codeCommune.
        setNameCanal(nomCommune);
    }, [nomCommune]); // Dépendance uniquement à nomCommune ici, puisque c'est ce qui déclenche la mise à jour.
    

    const handleCodePostalChange = (newCodePostal) => {
        setPostalCode(newCodePostal);
      };

    // const handleAliasChange = (e) => {
    //     setCanalAlias(e.target.value);
    // };

        // Mise à jour de nameCanal lorsque nomCommune change
        useEffect(() => {
            if (!nameCanal || nomCommune === nameCanal) {
                setNameCanal(nomCommune);
            }
        }, [nomCommune, nameCanal]);

    return show ? (
        <div className={`popup-container ${animation}`}>
        <div className="popup">
            <span className="popup-header" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img src={iconhouseBlack} style={{ maxWidth: '50px', marginBottom: 0, paddingBottom: 0, marginTop: '10px' }} />

                <h2 className='editable-fields-label'>Rejoindre un canal</h2>
            </span>

            <form id="form-create" className="popup-singleinput" >

            <CanalsListByCp canals={canals} handleConfirm={handleConfirm} setCodeCommune={setCodeCommune} setNomCommune={setNomCommune} onCodePostalChange={handleCodePostalChange} />

            </form>
            <div className="popup-btns">
            <button className="btn-cancel" onClick={handleClose}>Fermer</button>
            </div>
        </div>
    </div>
    ) : null;
};

export default JoinCanalPopup;

import { HAIRS } from '../../data/hair';

const HairSelect = ({ selectedHairs, onChange }) => {
    const handleHairChange = (event) => {
        if (onChange) {
            onChange(event);
        }
    };

return (
    <div style={{ paddingTop: '1rem', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
        {Array.from(new Set(Object.values(HAIRS))).map((hairValue, index) => {
            return (
                <span style={{ width: '30%', paddingRight: '1rem', display: 'flex', alignItems: 'center' }} key={index}>
                    <input
                        type="checkbox"
                        id={`color-${hairValue}`}
                        value={hairValue}
                        checked={selectedHairs && selectedHairs.includes(hairValue)}
                        onChange={handleHairChange}
                    />
                    <label style={{ color: 'black', marginLeft: '0.5rem' }} htmlFor={`color-${hairValue}`}>
                        {hairValue.charAt(0).toUpperCase() + hairValue.slice(1)}
                    </label>
                </span>
            );
        })}
    </div>
);
};




export default HairSelect;
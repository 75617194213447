import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { SITUATION } from '../../data/situation';
import { useTranslation } from 'react-i18next';

const SituationSelect = ({
  idPrefix,
  selectedSituationCat,
  onSituationChange,
  needsLabel,
  isEditMode,
  currentSituationAnimal
}) => {

  const [selectedSituation, setSelectedSituation] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedSituationCat) {
      const situation = SITUATION.find(s => s.value === selectedSituationCat);
      setSelectedSituation(situation);
    }
  }, [selectedSituationCat]);

  const handleSituationChange = (selectedOption) => {
    setSelectedSituation(selectedOption); // Mise à jour de l'état local
    onSituationChange(selectedOption.value, selectedOption.label);
  };

  return (
    <div style={{ maxWidth: '250px', display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '1rem', flexWrap: 'wrap' }}>
      {needsLabel && <label htmlFor={`${idPrefix}-animal`} style={{ marginBottom: '1rem' }}>Sélectionner une situation</label>}

      <Select
        id={`${idPrefix}-animal`}
        options={SITUATION}
        value={selectedSituation}
        onChange={handleSituationChange}
        placeholder="Choisir une situation"
        styles={customStyles}
        className={`select-update ${isEditMode ? 'select-visible' : 'select-hidden'}`}
      />


      <p className={`select-update ${isEditMode ? 'select-hidden' : 'select-visible'}`} style={{ color: '#000' }}>{currentSituationAnimal ? currentSituationAnimal : t('undefined')}</p>

    </div>
  );
};

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'black',
    backgroundColor: state.isSelected ? '#2F2F2F' : 'white',
    '&:hover': {
      backgroundColor: state.isSelected ? '#2F2F2F' : 'lightgray',
    },

  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'black',
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: '2px', // ou toute autre valeur que vous préférez
  }),
};

export default SituationSelect;

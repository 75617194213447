import React, { useState, useEffect } from 'react';
import iconhouseBlack from '../../assets/icon-house-black.svg'; // Assurez-vous d'importer correctement les ressources nécessaires
import iconperson from '../../assets/person-circle-outline.svg'

const JoinCanalByEmailPopup = ({ show, handleClose, handleConfirm, canalId, animation }) => {
    const [email, setEmail] = useState('');
    const [nameCanal, setNameCanal] = useState('');

    // Fonction pour gérer le changement d'email
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    // Fonction pour confirmer l'ajout de l'utilisateur
    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (email && canalId) {
            handleConfirm(email, canalId); // Passe l'email à la fonction handleConfirm
        }
    };

    return show ? (
        <div className={`popup-container ${animation}`}>
            <div className="popup">
                <span className="popup-header" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img src={iconperson} style={{ maxWidth: '50px', marginBottom: 0, paddingBottom: 0, marginTop: '10px' }} />

                    <h2 className='editable-fields-label'>Inviter un membre</h2>
                </span>

                <p style={{color: 'black'}}>Le rôle d'administrateur lui sera attribué.</p>
                <p style={{color: 'black', fontSize: '12px', marginTop: 0}}>(L'utilisateur doit être inscrit sur la plateforme.)</p>

                <form id="form-create" className="popup-singleinput" onSubmit={handleFormSubmit}>
                    <div className="input-container">
                        <label htmlFor="email" className="editable-fields-label">Email :</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={handleEmailChange}
                            placeholder="Entrez l'adresse email de l'utilisateur"
                            required
                        />
                    </div>
                    <div className="popup-btns">
                        <button type="submit" className="btn-confirm">Confirmer</button>
                        <button type="button" className="btn-cancel" onClick={handleClose}>Fermer</button>
                    </div>
                </form>
            </div>
        </div>
    ) : null;
};

export default JoinCanalByEmailPopup;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getFirestore, collection, query, orderBy, limit, getDocs, where, addDoc, doc, getDoc } from 'firebase/firestore';

const fetchNotifications = createAsyncThunk(
  'notifications/fetchNotifications',
  async (_, { rejectWithValue }) => {
    try {
      const db = getFirestore();
      const notificationsRef = collection(db, 'notifications');
      const oneMonthAgo = new Date();
      oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

      const q = query(notificationsRef, where('date', '>=', oneMonthAgo), orderBy('date', 'desc'), limit(20));
      const querySnapshot = await getDocs(q);

      const notifications = [];
      querySnapshot.forEach((doc) => {
        notifications.push(doc.data());
      });

      return notifications;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: { items: [], status: 'idle', error: null },
  reducers: {
  resetNotificationState: (state) => {
    state.items = [];
    state.statusNotification = 'idle';
    state.errorNotification = null;
  }
},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotifications.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Add notifications to the state array
        state.items = action.payload;
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(createAndSendNotification.pending, (state) => {
        state.loading = true;
        state.errorNotification = null;
        state.statusNotification = false;
      })
      .addCase(createAndSendNotification.fulfilled, (state) => {
        state.loading = false;
        state.statusNotification = true;
      })
      .addCase(createAndSendNotification.rejected, (state, action) => {
        state.loading = false;
        state.errorNotification = action.payload;
        state.statusNotification = false;
      });
  },
});


export const createAndSendNotification = createAsyncThunk(
  "notifications/createAndSend",
  async ({ userIds, message, subject, authorized, isAdmin }, thunkAPI) => {

    if(!userIds) {
      return;
    }
    try {
      const db = getFirestore();
      const uniqueUserIds = new Set(); // Utiliser un Set pour stocker des userIds uniques

      // Parcourir chaque userId et vérifier la préférence de notification
      for (let userId of userIds) {
        const userRef = doc(db, "users", userId);
        const userDoc = await getDoc(userRef);
        const userData = userDoc.data();

        if ((userData && userData.notificationsEnabled === true) || (userData && isAdmin === true) || (authorized === true)) {
          uniqueUserIds.add(userId); // Ajouter userId au Set
        }
      }

      // Convertir le Set en tableau
      const notificationsEnabledUsers = Array.from(uniqueUserIds);

      // Créer la notification dans Firestore uniquement pour les utilisateurs qui ont activé les notifications
      const notificationsCollection = collection(db, "notifications");
      await addDoc(notificationsCollection, {
        notificationsEnabledUsers,
        message,
        subject,
        view: false,
        date: Date.now(),
      });
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(error.toString());
    }
  }
);

export { fetchNotifications };
export const { resetNotificationState } = notificationsSlice.actions;
export default notificationsSlice.reducer;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getFirestore, collection, query, where, getDocs, addDoc, doc, deleteDoc, getDoc, updateDoc } from 'firebase/firestore';

export const fetchUserById = createAsyncThunk(
    'users/fetchUserById',
    async (userId, { rejectWithValue }) => {
        const db = getFirestore();
        const userRef = doc(db, 'users', userId);
        try {
            const userDoc = await getDoc(userRef);
            if (userDoc.exists()) {
                return { id: userDoc.id, ...userDoc.data() };
            } else {
                return rejectWithValue('Utilisateur non trouvé');
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);


/* Getting members of an association */ 
// export const fetchUsersByAssociation = createAsyncThunk(
//     'users/fetchUsersByAssociation',
//     async ({ linkedAssociationId, isLinkedToAnAssociation }) => {
//         const db = getFirestore();
//         const q = query(
//             collection(db, 'users'),
//             where('linkedAssociationId', '==', linkedAssociationId),
//             where('isLinkedToAnAssociation', '==', isLinkedToAnAssociation)
//         );
//         const querySnapshot = await getDocs(q);
//         const members = [];
//         querySnapshot.forEach(doc => {
//             members.push({ id: doc.id, ...doc.data() });
//         });
//         return members;
//     }
// );

export const fetchUsersByAssociation = createAsyncThunk(
    'users/fetchUsersByAssociation',
    async ({ linkedAssociationId, isLinkedToAnAssociation }) => {
        const db = getFirestore();
        const q = query(
            collection(db, 'users'),
            where('linkedAssociationIds', 'array-contains', linkedAssociationId),
            where('isLinkedToAnAssociation', '==', isLinkedToAnAssociation)
        );
        const querySnapshot = await getDocs(q);
        const members = [];
        querySnapshot.forEach(doc => {
            members.push({ id: doc.id, ...doc.data() });
        });
        return members;
    }
);


/* Getting associations  */

export const fetchAssociationUsers = createAsyncThunk(
    'users/fetchAssociationUsers',
    async (_, { rejectWithValue }) => {
        const db = getFirestore();
        const usersRef = collection(db, 'users');
        const associationsQuery = query(usersRef, where('isAssociation', '==', true));
        try {
            const querySnapshot = await getDocs(associationsQuery);
            const associations = [];
            querySnapshot.forEach(doc => {
                associations.push({ id: doc.id, ...doc.data() });
            });
            return associations;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);



/* Getting associations from a given userId */ 

export const fetchAssociationsFromUserId = createAsyncThunk(
    'users/fetchAssociationsFromUserId',
    async ({ userId }, thunkAPI) => {
        const db = getFirestore();
        const userRef = doc(db, 'users', userId); // Récupérer la référence du document utilisateur par userId
        try {
            const userDoc = await getDoc(userRef);
            if (userDoc.exists()) {
                const userData = userDoc.data();
                const linkedAssociationIds = userData.linkedAssociationIds || [];

                // console.log("linkedAssociationids" , linkedAssociationIds)
                return linkedAssociationIds; // Renvoyer le tableau des IDs d'associations liées
            } else {
                // Gérer le cas où l'utilisateur n'existe pas
                return thunkAPI.rejectWithValue('User not found');
            }
        } catch (error) {
            // Gérer les erreurs de la requête Firestore
            console.error('Error fetching user associations:', error);
            return thunkAPI.rejectWithValue(error.toString());
        }
    }
);


const usersSlice = createSlice({
    name: 'users',
    initialState: {
        userDetails: {},
        members: {},
        associationUsers: [],
        associationsIdsContributor: [],
        status: 'idle',
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserById.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUserById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.userDetails = action.payload;
            })
            .addCase(fetchUserById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(fetchUsersByAssociation.pending, state => {
                state.status = 'loading';
                state.members = {}; // Réinitialise les users avant de charger les nouveaux

            })
            .addCase(fetchUsersByAssociation.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const membersObject = {};
                action.payload.forEach(member => {
                    membersObject[member.id] = member;
                });
                state.members = membersObject;
            })            
            .addCase(fetchUsersByAssociation.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            builder
            .addCase(fetchAssociationUsers.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAssociationUsers.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.associationUsers = action.payload;
            })
            .addCase(fetchAssociationUsers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchAssociationsFromUserId.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            builder
            .addCase(fetchAssociationsFromUserId.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAssociationsFromUserId.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.associationsIdsContributor = action.payload;
                
            })
    }
});

export default usersSlice.reducer;

import React, { useState } from 'react';

const Sidebar = ({ title, customClass, children }) => {
    const [isOpen, setIsOpen] = useState(false);



    return (

        <div className={`section-openable ${isOpen ? 'section-open' : ''} ${customClass}` }>
            <h2 onClick={() => setIsOpen(!isOpen)}>{title}</h2>
            <div className="section-content">
                {children}
            </div>
        </div>
    );
}

export default Sidebar;


import React from 'react';

const ResetButton = ({ text, onClick }) => {
    return (
        <button className="reset-button" onClick={onClick}>
            <span className="reset-text">{text} {" "}</span>
            <span className="undercross"><span className="cross">x</span></span>    
        </button>
    );
};

export default ResetButton;

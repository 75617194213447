import React from 'react';

function TableGeneral() {
    const data = [
        ['', 'Créer un canal', 'Modifier un canal', 'Supprimer un canal', 'Créer un secteur', 'Modifier un secteur', 'Modifier les rôles', 'Supprimer les utilisateurs'],
        ['Créateur', 'X', 'X', 'X', 'X', 'X', 'X', 'X'],
        ['Administrateur', '', 'X', '', 'X', 'X', '', 'X'],
        ['Association', '', 'X', '', 'X', 'X', '', ''],
        ['Contributeur', '', '', '', 'X', 'X', '', ''],
        ['Invité', '', '', '', '', '', '', ''],

    ];

    return (
        <table border="1">
            <tbody>
                {data.map((row, rowIndex) => (
                    <tr key={rowIndex} className='tr-table'>
                        {row.map((cell, cellIndex) => (
                            <td key={cellIndex} className='td-table'>
                                {cell}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default TableGeneral;

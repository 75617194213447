// import React, { useEffect, useState, useRef } from 'react';
// import { MapContainer, TileLayer, Polygon, useMap, Marker, Popup } from 'react-leaflet';
// import 'leaflet/dist/leaflet.css';
// import L from 'leaflet';
// import markerIcon from '../../assets/logos/location-pin-kappze.svg';

// const fetchCommuneData = async (code) => {
//     const response = await fetch(`https://geo.api.gouv.fr/communes/${code}?fields=nom,code,codeParent,codesPostaux,siren,centre,contour,bbox,codeEpci,codeDepartement,codeRegion,population&format=json&geometry=centre`);
//     const data = await response.json();
//     return data;
// };

// const calculateCenter = (coordinates) => {
//     let latSum = 0;
//     let lngSum = 0;
//     const len = coordinates.length;

//     coordinates.forEach(coord => {
//         latSum += coord[1];
//         lngSum += coord[0];
//     });

//     return {
//         lat: latSum / len,
//         lng: lngSum / len
//     };
// };

// const calculateBounds = (coordinates) => {
//     let minLat = Infinity, maxLat = -Infinity, minLng = Infinity, maxLng = -Infinity;

//     coordinates.forEach(coord => {
//         minLat = Math.min(minLat, coord[1]);
//         maxLat = Math.max(maxLat, coord[1]);
//         minLng = Math.min(minLng, coord[0]);
//         maxLng = Math.max(maxLng, coord[0]);
//     });

//     return [
//         [minLat, minLng],
//         [maxLat, maxLng]
//     ];
// };

// const SingleMarkerMap = ({ communeCode, marker, animalImage }) => {
//     const [center, setCenter] = useState({ lat: 48.8566, lng: 2.3522 });
//     const [bounds, setBounds] = useState(null);
//     const [regionCoordinates, setRegionCoordinates] = useState([]);
//     const [locationInfo, setLocationInfo] = useState('Loading address...');
//     const markerRef = useRef(null);

//     const customIcon = new L.Icon({
//         iconUrl: markerIcon,
//         iconSize: [32, 32], // Taille de l'icône
//         iconAnchor: [16, 32], // Point de l'icône qui correspond à la position du marqueur
//         popupAnchor: [0, -32] // Point où la popup apparaît par rapport à l'icône
//     });

//     useEffect(() => {
//         if (communeCode) {
//             fetchCommuneData(communeCode).then(data => {
//                 const coordinates = data.contour.coordinates[0];
//                 const newCenter = calculateCenter(coordinates);
//                 const newBounds = calculateBounds(coordinates);
//                 const formattedCoordinates = coordinates.map(coord => ({
//                     lat: coord[1],
//                     lng: coord[0]
//                 }));

//                 setCenter(newCenter);
//                 setBounds(newBounds);
//                 setRegionCoordinates(formattedCoordinates);
//             });
//         }
//     }, [communeCode]);

//     useEffect(() => {
//         if (marker) {
//             geocodeReverse(marker.lat, marker.lng).then(address => {
//                 setLocationInfo(`Rue : ${address}`);
//                 if (markerRef.current) {
//                     markerRef.current.openPopup();
//                 }
//             });
//         }
//     }, [marker]);

//     const mapBounds = [
//         { lat: 85, lng: -180 },
//         { lat: 85, lng: 180 },
//         { lat: -85, lng: 180 },
//         { lat: -85, lng: -180 },
//         { lat: 85, lng: -180 } // Ferme le polygone
//     ];

//     const UpdateMapView = () => {
//         const map = useMap();
//         useEffect(() => {
//             if (bounds) {
//                 map.fitBounds(bounds);
//                 map.setMaxBounds(bounds);
//                 map.setMinZoom(map.getZoom());
//             } else {
//                 map.setView(center, 13);
//             }
//         }, [map, center, bounds]);
//         return null;
//     };

//     const geocodeReverse = async (lat, lng) => {

//         if(lat && lng){
//             const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`);
//             const data = await response.json();
//             return data?.address?.road || 'Unknown road';
//         }

//         return;

//     };

//     return (
//         <div className="map-container">
//             <MapContainer
//                 center={center}
//                 zoom={13}
//                 maxBounds={bounds}
//                 maxBoundsViscosity={1.0}
//             >
//                 <TileLayer
//                     url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//                     attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
//                 />
//                 <Polygon
//                     positions={[mapBounds, regionCoordinates]}
//                     pathOptions={{ fillColor: 'black', fillOpacity: 0.7, strokeWeight: 0 }}
//                 />
//                 <Polygon
//                     positions={regionCoordinates}
//                     pathOptions={{ weight: 2, color: '#2f4f4f', fillColor: 'black' }}
//                 />
//                 {marker && (
//                     <Marker position={[marker.lat, marker.lng]} icon={customIcon} ref={markerRef}>
//                         <Popup style={{backgroundColor: '#2f2f2f'}}>

//                         {animalImage && (<img src={animalImage} />)}

//                         <p>{locationInfo}</p>
//                         </Popup>
//                     </Marker>
//                 )}
//                 <UpdateMapView />
//             </MapContainer>
//         </div>
//     );
// };

// export default SingleMarkerMap;



// import React, { useEffect, useState, useRef } from 'react';
// import { MapContainer, TileLayer, Polygon, useMap, Marker, Popup, useMapEvents } from 'react-leaflet';
// import 'leaflet/dist/leaflet.css';
// import L from 'leaflet';
// import markerIcon from '../../assets/logos/location-pin-kappze.svg';

// const fetchCommuneData = async (code) => {
//     const response = await fetch(`https://geo.api.gouv.fr/communes/${code}?fields=nom,code,codeParent,codesPostaux,siren,centre,contour,bbox,codeEpci,codeDepartement,codeRegion,population&format=json&geometry=centre`);
//     const data = await response.json();
//     return data;
// };

// const calculateCenter = (coordinates) => {
//     let latSum = 0;
//     let lngSum = 0;
//     const len = coordinates.length;

//     coordinates.forEach(coord => {
//         latSum += coord[1];
//         lngSum += coord[0];
//     });

//     return {
//         lat: latSum / len,
//         lng: lngSum / len
//     };
// };

// const calculateBounds = (coordinates) => {
//     let minLat = Infinity, maxLat = -Infinity, minLng = Infinity, maxLng = -Infinity;

//     coordinates.forEach(coord => {
//         minLat = Math.min(minLat, coord[1]);
//         maxLat = Math.max(maxLat, coord[1]);
//         minLng = Math.min(minLng, coord[0]);
//         maxLng = Math.max(maxLng, coord[0]);
//     });

//     return [
//         [minLat, minLng],
//         [maxLat, maxLng]
//     ];
// };



// const SingleMarkerMap = ({ communeCode, marker, animalImage, isEditMode, onUpdateMarker, onDeleteMarker }) => {
//     const [center, setCenter] = useState({ lat: 48.8566, lng: 2.3522 });
//     const [bounds, setBounds] = useState(null);
//     const [regionCoordinates, setRegionCoordinates] = useState([]);
//     const [locationInfo, setLocationInfo] = useState('Chargement de l\'adresse ...');
//     const [currentMarker, setCurrentMarker] = useState(marker);
//     const [initialMarker, setInitialMarker] = useState(marker); // État pour la position initiale du marqueur
//     const [popupOpen, setPopupOpen] = useState(false); // Nouvel état pour gérer l'ouverture du popup
//     const markerRef = useRef(null);

//     const customIcon = new L.Icon({
//         iconUrl: markerIcon,
//         iconSize: [32, 32], // Taille de l'icône
//         iconAnchor: [16, 32], // Point de l'icône qui correspond à la position du marqueur
//         popupAnchor: [0, -32] // Point où la popup apparaît par rapport à l'icône
//     });

//     useEffect(() => {
//         if (communeCode) {
//             fetchCommuneData(communeCode).then(data => {
//                 const coordinates = data.contour.coordinates[0];
//                 const newCenter = calculateCenter(coordinates);
//                 const newBounds = calculateBounds(coordinates);
//                 const formattedCoordinates = coordinates.map(coord => ({
//                     lat: coord[1],
//                     lng: coord[0]
//                 }));

//                 setCenter(newCenter);
//                 setBounds(newBounds);
//                 setRegionCoordinates(formattedCoordinates);
//             });
//         }
//     }, [communeCode]);

//     useEffect(() => {
//         if (marker) {
//             geocodeReverse(marker.lat, marker.lng).then(address => {
//                 setLocationInfo(`Rue : ${address}`);
//                 if (markerRef.current) {
//                     markerRef.current.openPopup();
//                 }
//             });
//         }
//     }, [marker]);

//     useEffect(() => {
//         if (currentMarker && markerRef.current) {
//             markerRef.current.openPopup();
//         }
//     }, [currentMarker]);

//     const mapBounds = [
//         { lat: 85, lng: -180 },
//         { lat: 85, lng: 180 },
//         { lat: -85, lng: 180 },
//         { lat: -85, lng: -180 },
//         { lat: 85, lng: -180 } // Ferme le polygone
//     ];

//     const UpdateMapView = () => {
//         const map = useMap();
//         useEffect(() => {
//             if (bounds) {
//                 map.fitBounds(bounds);
//                 map.setMaxBounds(bounds);
//                 map.setMinZoom(map.getZoom());
//             } else {
//                 map.setView(center, 13);
//             }
//         }, [map, center, bounds]);
//         return null;
//     };

//     const geocodeReverse = async (lat, lng) => {
//         if (lat && lng) {
//             const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`);
//             const data = await response.json();
//             return data?.address?.road || 'Unknown road';
//         }
//         return;
//     };

//     const handleDragEnd = (event) => {
//         const marker = event.target;
//         const position = marker.getLatLng();
//         setCurrentMarker(position);
//         setPopupOpen(true); // Ouvrir le popup dès que le marqueur est déplacé
//         marker.setLatLng(position, { draggable: 'true' }).bindPopup(position).update();
//     };

//     const handleConfirm = (e) => {
//         e.preventDefault();
//         onUpdateMarker(currentMarker);
//         setInitialMarker(currentMarker); // Mettre à jour la position initiale après confirmation
//         setPopupOpen(false); // Fermer le popup après confirmation
//     };

//     const handleCancel = () => {
//         setCurrentMarker(initialMarker); // Restaurer la position initiale si l'utilisateur annule

//         setPopupOpen(false); // Fermer le popup après annulation
//     };

//     const handleDelete = () => {
//         if (marker) {
//             onDeleteMarker(marker.id);
//             setPopupOpen(false); // Fermer le popup après suppression
//         }
//         return
//     };

//     const AddMarker = ({ onUpdateMarker }) => {
//         useMapEvents({
//             click(e) {
//                 const { lat, lng } = e.latlng;
//                 onUpdateMarker({ lat, lng });

//                 geocodeReverse(lat, lng).then(address => {
//                     setLocationInfo(`Rue : ${address}`);
//                     if (markerRef.current) {
//                         markerRef.current.openPopup();
//                     }
//                 });
//             },
//         });
//         return null;
//     };

//     return (
//         <div className="map-container">
//             <MapContainer
//                 center={center}
//                 zoom={13}
//                 maxBounds={bounds}
//                 maxBoundsViscosity={1.0}
//             >
//                 <TileLayer
//                     url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//                     attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
//                 />
//                 <Polygon
//                     positions={[mapBounds, regionCoordinates]}
//                     pathOptions={{ fillColor: 'black', fillOpacity: 0.7, strokeWeight: 0 }}
//                 />
//                 <Polygon
//                     positions={regionCoordinates}
//                     pathOptions={{ weight: 2, color: '#2f4f4f', fillColor: 'black' }}
//                 />
//                 {currentMarker && (
//                     <Marker
//                         position={[currentMarker.lat, currentMarker.lng]}
//                         icon={customIcon}
//                         ref={markerRef}
//                         draggable={isEditMode}
//                         eventHandlers={{ dragend: handleDragEnd }}
//                     >
//                         <Popup style={{ backgroundColor: '#2f2f2f' }} autoPan={false} tooltipclose={handleCancel}>
//                             {animalImage && (<img src={animalImage} alt="Animal" />)}
//                             <p>{locationInfo}</p>
//                             {isEditMode && (
//                                 <div>
//                                     <button onClick={handleConfirm}>Confirmer</button>
//                                     {/* <button onClick={handleCancel}>Annuler</button> */}
//                                     <button onClick={handleDelete}>Supprimer</button>
//                                 </div>
//                             )}
//                         </Popup>
//                     </Marker>
//                 )}

//                 {isEditMode && <AddMarker onUpdateMarker={setCurrentMarker} />}

//                 <UpdateMapView />
//             </MapContainer>
//         </div>
//     );
// };

// export default SingleMarkerMap;


import React, { useEffect, useState, useRef } from 'react';
import { MapContainer, TileLayer, Polygon, useMap, Marker, Popup, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import markerIcon from '../../assets/logos/location-pin-kappze.svg';

const fetchCommuneData = async (code) => {
    const response = await fetch(`https://geo.api.gouv.fr/communes/${code}?fields=nom,code,codeParent,codesPostaux,siren,centre,contour,bbox,codeEpci,codeDepartement,codeRegion,population&format=json&geometry=centre`);
    const data = await response.json();
    return data;
};

const calculateCenter = (coordinates) => {
    let latSum = 0;
    let lngSum = 0;
    const len = coordinates.length;

    coordinates.forEach(coord => {
        latSum += coord[1];
        lngSum += coord[0];
    });

    return {
        lat: latSum / len,
        lng: lngSum / len
    };
};

const calculateBounds = (coordinates) => {
    let minLat = Infinity, maxLat = -Infinity, minLng = Infinity, maxLng = -Infinity;

    coordinates.forEach(coord => {
        minLat = Math.min(minLat, coord[1]);
        maxLat = Math.max(maxLat, coord[1]);
        minLng = Math.min(minLng, coord[0]);
        maxLng = Math.max(maxLng, coord[0]);
    });

    return [
        [minLat, minLng],
        [maxLat, maxLng]
    ];
};

const SingleMarkerMap = ({ communeCode, marker, animalImage, isEditMode, onUpdateMarker, onDeleteMarker }) => {
    const [center, setCenter] = useState({ lat: 48.8566, lng: 2.3522 });
    const [bounds, setBounds] = useState(null);
    const [regionCoordinates, setRegionCoordinates] = useState([]);
    const [locationInfo, setLocationInfo] = useState('Chargement de l\'adresse ...');
    const [currentMarker, setCurrentMarker] = useState(marker);
    const [initialMarker, setInitialMarker] = useState(marker);
    const [popupOpen, setPopupOpen] = useState(false);
    const markerRef = useRef(null);

    const customIcon = new L.Icon({
        iconUrl: markerIcon,
        iconSize: [32, 32],
        iconAnchor: [16, 32],
        popupAnchor: [0, -32]
    });

    useEffect(() => {
        if (communeCode) {
            fetchCommuneData(communeCode).then(data => {
                const contour = data.contour;

                if (contour.type === "Polygon") {
                    const coordinates = contour.coordinates[0];
                    const newCenter = calculateCenter(coordinates);
                    const newBounds = calculateBounds(coordinates);
                    const formattedCoordinates = coordinates.map(coord => ({
                        lat: coord[1],
                        lng: coord[0]
                    }));

                    setCenter(newCenter);
                    setBounds(newBounds);
                    setRegionCoordinates([formattedCoordinates]); // Un seul polygone
                } else if (contour.type === "MultiPolygon") {
                    const allPolygons = contour.coordinates.map(polygon => {
                        return polygon[0].map(coord => ({
                            lat: coord[1],
                            lng: coord[0]
                        }));
                    });

                    const allCoordinates = contour.coordinates.flat(2);
                    const newCenter = calculateCenter(allCoordinates);
                    const newBounds = calculateBounds(allCoordinates);

                    setCenter(newCenter);
                    setBounds(newBounds);
                    setRegionCoordinates(allPolygons); // MultiPolygons
                }
            });
        }
    }, [communeCode]);

    useEffect(() => {
        if (marker) {
            geocodeReverse(marker.lat, marker.lng).then(address => {
                setLocationInfo(`Rue : ${address}`);
                if (markerRef.current) {
                    markerRef.current.openPopup();
                }
            });
        }
    }, [marker]);

    useEffect(() => {
        if (currentMarker && markerRef.current) {
            markerRef.current.openPopup();
        }
    }, [currentMarker]);

    const mapBounds = [
        { lat: 85, lng: -180 },
        { lat: 85, lng: 180 },
        { lat: -85, lng: 180 },
        { lat: -85, lng: -180 },
        { lat: 85, lng: -180 }
    ];

    const UpdateMapView = () => {
        const map = useMap();
        useEffect(() => {
            if (bounds) {
                map.fitBounds(bounds);
                map.setMaxBounds(bounds);
                map.setMinZoom(map.getZoom());
            } else {
                map.setView(center, 13);
            }
        }, [map, center, bounds]);
        return null;
    };

    const geocodeReverse = async (lat, lng) => {
        if (lat && lng) {
            const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`);
            const data = await response.json();
            return data?.address?.road || 'Unknown road';
        }
        return;
    };

    const handleDragEnd = (event) => {
        const marker = event.target;
        const position = marker.getLatLng();
        setCurrentMarker(position);
        setPopupOpen(true);
        marker.setLatLng(position, { draggable: 'true' }).bindPopup(position).update();
    };

    const handleConfirm = (e) => {
        e.preventDefault();
        onUpdateMarker(currentMarker);
        setInitialMarker(currentMarker);
        setPopupOpen(false);
    };

    const handleCancel = () => {
        setCurrentMarker(initialMarker);
        setPopupOpen(false);
    };

    const handleDelete = () => {
        if (marker) {
            onDeleteMarker(marker.id);
            setPopupOpen(false);
        }
        return;
    };

    const AddMarker = ({ onUpdateMarker }) => {
        useMapEvents({
            click(e) {
                const { lat, lng } = e.latlng;
                onUpdateMarker({ lat, lng });

                geocodeReverse(lat, lng).then(address => {
                    setLocationInfo(`Rue : ${address}`);
                    if (markerRef.current) {
                        markerRef.current.openPopup();
                    }
                });
            },
        });
        return null;
    };

    return (
        <div className="map-container">
            <MapContainer
                center={center}
                zoom={13}
                maxBounds={bounds}
                maxBoundsViscosity={1.0}
            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                {/* Masque pour couvrir la zone extérieure */}
                <Polygon
                    positions={[mapBounds, ...regionCoordinates]}
                    pathOptions={{ fillColor: 'black', fillOpacity: 0.7, strokeWeight: 0 }}
                />

                {/* Polygones des frontières */}
                {regionCoordinates.map((polygon, index) => (
                    <Polygon
                        key={index}
                        positions={polygon}
                        pathOptions={{ weight: 2, color: '#2f4f4f', fillColor: 'transparent' }}
                    />
                ))}

                {currentMarker && (
                    <Marker
                        position={[currentMarker.lat, currentMarker.lng]}
                        icon={customIcon}
                        ref={markerRef}
                        draggable={isEditMode}
                        eventHandlers={{ dragend: handleDragEnd }}
                    >
                        <Popup style={{ backgroundColor: '#2f2f2f' }} autoPan={false} tooltipclose={handleCancel}>
                            {animalImage && (<img src={animalImage} alt="Animal" />)}
                            <p>{locationInfo}</p>
                            {isEditMode && (
                                <div>
                                    <button onClick={handleConfirm}>Confirmer</button>
                                    <button onClick={handleDelete}>Supprimer</button>
                                </div>
                            )}
                        </Popup>
                    </Marker>
                )}

                {isEditMode && <AddMarker onUpdateMarker={setCurrentMarker} />}

                <UpdateMapView />
            </MapContainer>
        </div>
    );
};

export default SingleMarkerMap;



import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getFirestore, collection, where, query, getDocs, addDoc, updateDoc, deleteDoc, getDoc, doc, Timestamp } from 'firebase/firestore';
import { deleteImageFromStorage, uploadImage } from "../animals/animalSlice";

/*
* Fetch subSectors
***    
*/

export const fetchSubSectors = createAsyncThunk('subSectors/fetchSubSectors', async (sectorId) => {
    const db = getFirestore();
    const q = query(collection(db, 'subSectors'), where('citySectorId', '==', sectorId));
    const querySnapshot = await getDocs(q);
    const subSectorsData = [];
    querySnapshot.forEach((doc) => {
        subSectorsData.push({ id: doc.id, ...doc.data() });
    });
    return subSectorsData;
});


/*
* Create subSector
***    
*/

export const addSubSector = createAsyncThunk(
    'subSectors/addSubSector',
    async (subSectorData, { rejectWithValue }) => {
      
        try {
            const db = getFirestore();
            let addedDate = Timestamp.now().toDate();
            let formattedDate = addedDate.toISOString().split('T')[0]; // Format date as "YYYY-MM-DD"
            const newSubSectorData = { ...subSectorData, addedDate: formattedDate }; // Add formatted date to newSectorData
            const subSectorRef = collection(db, 'subSectors');
            const newSubSectorRef = await addDoc(subSectorRef, newSubSectorData);
            const newSubSectorSnapshot = await getDoc(newSubSectorRef);
            return { id: newSubSectorRef.id, ...newSubSectorSnapshot.data() };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);


/*
* Update subSector
***    
*/

export const updateSubSector = createAsyncThunk(
    'citiesSector/updateSubSector',
    async (subSectorData, { rejectWithValue }) => {
        // console.log(subSectorData)
        try {
            const db = getFirestore();
            const subSectorRef = doc(db, 'subSectors', subSectorData.subSectorId);
            
            await updateDoc(subSectorRef, subSectorData);
            const updatedSubSectorSnapshot = await getDoc(subSectorRef);
            const updatedSubSector = { id: updatedSubSectorSnapshot.id, ...updatedSubSectorSnapshot.data() };
            return updatedSubSector;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);


/*
* Delete citySector
***    
*/

export const deleteSubSector = createAsyncThunk(
    'citiesSector/deleteSubSector',
    async (subSectorId, { rejectWithValue }) => {
        try {
            const db = getFirestore();
            const subSectorRef = doc(db, 'subSectors', subSectorId);
            const subSectorSnapshot = await getDoc(subSectorRef);
            const subSectorData = { id: subSectorSnapshot.id, ...subSectorSnapshot.data() };
            await deleteDoc(subSectorRef);
            // Si le secteur a déjà une image, la supprimer de Firebase Storage
            if (subSectorRef && subSectorRef.imageCover && subSectorRef.imageCover.name) {
                deleteImageFromStorage(subSectorRef.imageCover.name);
            }

            return subSectorData;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);


/*
* Modify canal cover Image
***    
*/


export const updateSubSectorCoverImage = createAsyncThunk(
    "animals/updateSubSectorCoverImage",
    async ({ subSectorId, image }, { rejectWithValue, dispatch }) => {
        try {
            const db = getFirestore();
            const subSectorRef = doc(db, "subSectors", subSectorId);

            // Récupérer l'image actuelle de l'animal
            const subSectorDoc = await getDoc(subSectorRef);
            const subSectorData = subSectorDoc.data();

            // Si le canal a déjà une image, la supprimer de Firebase Storage
            if (subSectorData && subSectorData.imageCover && subSectorData.imageCover.name) {
                dispatch(deleteImageFromStorage(subSectorData.imageCover.name));
            }

            // Uploader la nouvelle image
            const newImageUrl = await uploadImage(image);
            // Mettre à jour la référence de l'image dans Firestore
            await updateDoc(subSectorRef, { imageCover: newImageUrl });

            return { subSectorId, imageCoverUrl: newImageUrl };
        } catch (error) {
            // console.log('error ici', error)
            return rejectWithValue(error.message);
        }
    }
);


/*
* * * CitySector slice
***    
*/

const subSectorsSlice = createSlice({
    name: 'subSectors',
    initialState: {
        status: 'idle',
        data: [],
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSubSectors.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchSubSectors.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload;
            })
            .addCase(fetchSubSectors.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(addSubSector.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(addSubSector.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data.push(action.payload);

            })
            .addCase(addSubSector.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(updateSubSector.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateSubSector.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const updatedSubSectorIndex = state.data.findIndex((subSector) => subSector.id === action.payload.id);
                if (updatedSubSectorIndex >= 0) {
                    state.data[updatedSubSectorIndex] = action.payload;
                }
            })
            .addCase(updateSubSector.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
        builder.addCase(updateSubSectorCoverImage.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(updateSubSectorCoverImage.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const updatedSubSectorIndex = state.data.findIndex((subSector) => subSector.id === action.payload.subSectorId);

                if (updatedSubSectorIndex >= 0) {
                    const { subSectorId, imageCoverUrl } = action.payload;
                    const sector = state.data.find((sector) => sector.id === subSectorId);
                    if (!sector.image) {
                        sector.image = {};
                    }
                    sector.image.url = imageCoverUrl;
                    state.coverUrl = sector.image.url.url;
                }
            })
            .addCase(updateSubSectorCoverImage.rejected, (state, action) => {
                state.status = 'failed';
                // console.log('action payload -> ', action.payload)
                state.error = action.payload;
            });

    },
});

export default subSectorsSlice.reducer;


import { RACES } from '../../data/races';

const RaceSelect = ({ selectedRaces, onChange }) => {

    const handleRaceChange = (event) => {
        if (onChange) {
            onChange(event);
        }
    };

    return (
        <div style={{ paddingTop: '1rem', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
            {Array.from(new Set(Object.values(RACES))).map((raceValue, index) => {
                return (
                    <span style={{ width: '30%', paddingRight: '1rem', display: 'flex', alignItems: 'center' }} key={index}>
                        <input
                            type="checkbox"
                            id={`color-${raceValue}`}
                            value={raceValue}
                            checked={selectedRaces && selectedRaces.includes(raceValue)}
                            onChange={handleRaceChange}
                        />
                        <label style={{ color: 'black', marginLeft: '0.5rem' }} htmlFor={`color-${raceValue}`}>
                            {raceValue.charAt(0).toUpperCase() + raceValue.slice(1)}
                        </label>
                    </span>
                );
            })}
        </div>
    );
};


export default RaceSelect;


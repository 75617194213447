import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getFirestore, collection, doc, getDoc, query, where, getDocs, deleteDoc, updateDoc, deleteField } from 'firebase/firestore';


export const fetchCanalUsers = createAsyncThunk(
    'canals/fetchCanalUsers',
    async (canalId) => {
        const db = getFirestore();

        // Get the canal data
        const canalRef = doc(db, 'canals', canalId);
        const canalSnapshot = await getDoc(canalRef);
        const canalData = canalSnapshot.data();

        // Get the users associated with this canal
        const usersQuery = query(collection(db, 'userCanals'), where('canalId', '==', canalId));
        const usersSnapshot = await getDocs(usersQuery);

        const usersData = [];
        for (const userDoc of usersSnapshot.docs) {
            const userCanalData = userDoc.data();
            const userId = userCanalData.userId;

            // Get the user's information from its reference
            const userRef = doc(db, 'users', userId);
            const userSnapshot = await getDoc(userRef);

            if (userSnapshot.exists()) {
                const user = userSnapshot.data();

                let userData = { id: userId, ...userSnapshot.data(), joiningDate: userCanalData.joiningDate };


                // Vérifier le statut de la licence
                if (user.licenceNumber) {
                    const licenceRef = doc(db, 'licences', user.licenceNumber);
                    const licenceSnapshot = await getDoc(licenceRef);
                    if (licenceSnapshot.exists()) {
                        userData.licenceStatus = licenceSnapshot.data().status;
                        // Ajoutez ici d'autres champs de licence si nécessaire
                    } else {
                        userData.licenceStatus = 'unknown'; // Ou une autre valeur par défaut
                    }
                } else {
                    userData.licenceStatus = 'none'; // Aucun numéro de licence
                }
                // Check if the user is an admin
                let isAdmin = false;
                if (Array.isArray(canalData.role)) {
                    for (let role of canalData.role) {
                        if (role.uid === userId && role.isAdmin) {
                            isAdmin = true;
                            break;
                        }
                    }
                }
                userData.isAdmin = isAdmin;

                usersData.push(userData);
            }
        }

        return usersData;
    }
);


export const removeUserFromCanal = createAsyncThunk(
    'canals/removeUserFromCanal',
    async ({ userId, canalId }) => {
        const db = getFirestore();

        // Query to get the document ID of the user-canal link
        const userCanalQuery = query(collection(db, 'userCanals'), where('userId', '==', userId), where('canalId', '==', canalId));
        const userCanalSnapshot = await getDocs(userCanalQuery);

        if (!userCanalSnapshot.empty) {
            // Assuming there is only one document that matches the query
            const docId = userCanalSnapshot.docs[0].id;

            // Delete the document
            await deleteDoc(doc(db, 'userCanals', docId));
        } else {
            throw new Error('La liaison entre l\'utilisateur et l\'canal n\'a pas été trouvée');
        }
        // Supprimer la préférence de notification pour le canal spécifique dans la collection 'users'
        const userRef = doc(db, 'users', userId);
        // Ici, on utilise l'opérateur `FieldValue.delete()` pour supprimer la clé spécifique
        await updateDoc(userRef, { [`notificationsAdminPreferences.${canalId}`]: deleteField() });
        // Get the canal document
        const canalRef = doc(db, 'canals', canalId);
        const canalSnap = await getDoc(canalRef);

        if (canalSnap.exists) {
            // Filter out the user from the roles array
            const canalData = canalSnap.data();
            const filteredRoles = canalData.role.filter(role => role.uid !== userId);

            // Update the canal document with the filtered roles array
            await updateDoc(canalRef, { role: filteredRoles });
        } else {
            throw new Error('L\'canal n\'a pas été trouvée');
        }
    }
);

export const joinAssociation = createAsyncThunk(
    'associations/joinAssociation',
    async ({ userId, isLinkedToAnAssociation, linkedAssociationId }, { rejectWithValue }) => {
        try {
            const db = getFirestore();
            const userRef = doc(db, 'users', userId);
            const userSnapshot = await getDoc(userRef);

            if (!userSnapshot.exists()) {
                throw new Error('Utilisateur introuvable');
            }

            // Récupérer les identifiants existants ou initialiser un tableau vide
            const existingIds = userSnapshot.data().linkedAssociationIds || [];

            // Ajouter le nouvel ID sans dupliquer
            const updatedIds = Array.from(new Set([...existingIds, linkedAssociationId]));

            // Mettre à jour le document de l'utilisateur
            await updateDoc(userRef, {
                isLinkedToAnAssociation: isLinkedToAnAssociation,
                linkedAssociationIds: updatedIds
            });

            // Récupérer et renvoyer les données mises à jour de l'utilisateur
            const updatedUserSnapshot = await getDoc(userRef);
            return { userId, ...updatedUserSnapshot.data() };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);


export const leaveAssociation = createAsyncThunk(
    'associations/leaveAssociation',
    async ({ userId, linkedAssociationId }, { rejectWithValue }) => {
        try {
            const db = getFirestore();
            const userRef = doc(db, 'users', userId);
            const userSnapshot = await getDoc(userRef);

            if (!userSnapshot.exists()) {
                throw new Error('Utilisateur introuvable');
            }

            // Récupérer les identifiants existants
            const existingIds = userSnapshot.data().linkedAssociationIds || [];

            // Supprimer l'ID spécifié
            const updatedIds = existingIds.filter(id => id !== linkedAssociationId);

            // Mettre à jour le document de l'utilisateur
            await updateDoc(userRef, {
                isLinkedToAnAssociation: updatedIds.length > 0,
                linkedAssociationIds: updatedIds
            });

            // Récupérer et renvoyer les données mises à jour de l'utilisateur
            const updatedUserSnapshot = await getDoc(userRef);
            return { userId, ...updatedUserSnapshot.data() };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);





const canalUsersSlice = createSlice({
    name: 'canalUsers',
    initialState: {
        status: 'idle',
        data: [],
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCanalUsers.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCanalUsers.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload;
            })
            .addCase(fetchCanalUsers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(joinAssociation.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(joinAssociation.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = state.data.map(user => {
                    if (user.id === action.payload.userId) {
                        return { ...user, ...action.payload };
                    }
                    return user;
                });
            })
            .addCase(joinAssociation.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(leaveAssociation.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(leaveAssociation.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = state.data.map(user => {
                    if (user.id === action.payload.userId) {
                        return { ...user, ...action.payload };
                    }
                    return user;
                });
            })
            .addCase(leaveAssociation.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
    },
});

export default canalUsersSlice.reducer;

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import LoginForm from '../components/user/LoginForm';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
    const { isAuthenticated } = useSelector((state) => state.auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, navigate]);
    return <LoginForm />;
};

export default LoginPage;
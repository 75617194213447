import React, { useState } from 'react';
import iconupdate from '../../assets/icon-update.svg';
import { useLocation } from 'react-router-dom';
import logo from '../../assets/transparent-without-circle.png';
import { useTranslation } from 'react-i18next';


const EditableField = ({
    label,
    value,
    onSave,
    type,
    options = [],
    optionsKey = "",
    displayValue = null,
    isAdmin = false,
    uid = null,
    currentUserId = null,
    commentAuthorId = null,
    customClass = null,
    isEditMode = null,
}) => {
    const { t } = useTranslation();
    const [isEditing, setIsEditing] = useState(false);
    const [editedValue, setEditedValue] = useState(value);
    const location = useLocation();
    const userPathId = location.pathname.split('/').pop();
    const [passwordShown, setPasswordShown] = useState(false);

    // Password toggle handler
    const togglePassword = () => {
        // When the handler is invoked
        // change inverse the boolean state passwordShown
        setPasswordShown(!passwordShown);
    };

    const [animationClass, setAnimationClass] = useState(null);


    const handleEdit = (e) => {
        setAnimationClass('popup-entering');
        setIsEditing(true);
        setTimeout(() => {
            setAnimationClass(null);
        }, 500);
    };

    const handleCancel = (e) => {


        setAnimationClass('popup-exiting');

        setTimeout(() => {


            setIsEditing(false);


        }, 450);

        setTimeout(() => {


            setAnimationClass(null);

        }, 500);


    };


    const handleSave = () => {
        onSave(editedValue);
        setIsEditing(false);
    };


    const handleChange = (event) => {
        if (type === "checkbox") {
            setEditedValue(event.target.checked);
        } else {
            setEditedValue(event.target.value);
        }
    };

    const formatValue = () => {
        if (type === 'checkbox') {
            return value ? `${t('yes')}` : `${t('no')}`;
        }
        return displayValue !== null ? displayValue : value;
    };

    return (
        <>
            {customClass === 'settings-label' && (<p style={{ color: 'black', textDecoration: 'underline', fontWeight: 'bolder' }}>{label} : </p>)}
            {isEditing && (
                <div className={`popup-container ${animationClass}`}>
                    <div className="popup">
                        <div className="popup-logo">
                            <img src={logo} />
                        </div>
                        <h2 className="editable-fields-label" style={{ color: "#2f4f4f", marginBottom: "1rem", fontWeight: "bold" }}>{label}</h2>
                        {type === "select" ? (
                            <select value={editedValue} onChange={handleChange}>
                                <option defaultValue={""}>Sélectionner</option>
                                {options.map((option) => (
                                    <option key={option[optionsKey]} data-name={option.name} value={option[optionsKey]}>
                                        {option.name}
                                    </option>
                                ))}
                            </select>
                        ) : type === "checkbox" ? (
                            <label className="switch">
                                <input type="checkbox" checked={editedValue} onChange={handleChange} />
                                <span className="slider round"></span>
                            </label>
                        ) : type === "date" ? (
                            <input style={{ color: "black" }} type="date" value={editedValue} data-name={editedValue} onChange={handleChange} />
                        ) : type === "text" ? (
                            <input style={{ color: "black" }} type="text" value={editedValue} data-name={editedValue} onChange={handleChange} />
                        ) : label === "Mot de passe" ? (
                            <>
                                <input type={passwordShown ? "text" : "password"} value={editedValue} data-name={editedValue} onChange={handleChange} />
                                <button onClick={togglePassword}>{passwordShown ? 'Masquer' : 'Montrer'}</button>    </>) :
                            (

                                <textarea style={{ color: "black", width: '100%', maxWidth: '400px', boxSizing: 'border-box' }} value={editedValue} data-name={editedValue} onChange={handleChange} cols="50" rows="4" />

                            )}
                        <div className="popup-buttons">
                            <button className="btn-cancel" onClick={handleCancel} style={{ fontSize: '0.9rem' }}>Annuler</button>
                            <button onClick={handleSave} className='btn-validate'>Valider</button>

                        </div>
                    </div>
                </div>
            )}
            <div className={`editable-field${isEditing ? ' editing' : ''}`}>
                {label === "Nom" ? (<h2 className="editable-fields-value">{formatValue()}</h2>) : customClass && customClass !== 'settings-label' ? (<p className="editable-fields-value animalName" >{formatValue()}</p>) : (<p className="editable-fields-value" >{formatValue()}</p>)}
                <>
                    {label === 'Mot de passe' && (<p style={{ color: '#000' }}>******</p>)}

                    <button className={`button-update ${isEditMode ? 'visible' : 'hidden'}`} onClick={handleEdit}>
                        <img style={{ width: "14px", margin: "2px", color: '#FFF' }} src={iconupdate} alt="Icon Update" className="button-icon" />
                    </button>

                </>

            </div>
        </>
    );
};

export default EditableField;


import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCanalUsers } from '../features/canals/canalUsersSlice';

const UsefulLinks = () => {
    const { uid, isAuthenticated } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(fetchCanalUsers(uid));
        }
    }, [dispatch, uid, isAuthenticated]);


    return (
        <div className='kappze-page user-page' style={{ padding: '1rem' }}>
            <div className="header-useful_links" style={{ padding: '1rem' }}>
                <h2>Liens utiles</h2>
            </div>
            <div className="blocs" style={{ padding: '3rem', display: 'flex', alignItems: 'flex-start' }}>
                <div className="bloc-1">
                    <h3>Identification :</h3>
                    <div className="bloc-1_elt-list">
                        <img src='' /> <p>I-CAD vérification du numéro d'ID de l'animal</p>
                    </div>
                    <div className="bloc-1_elt-list">
                        <img src='' /> <p>EUROPETNET vérification du numéro d'ID de l'animal (europe)</p>
                    </div>

                    <div className="bloc-1_elt-list">
                        <img src='' /> <p>Filalapat</p>
                    </div>

                    <div className="bloc-1_elt-list">
                        <img src='' /> <p>PetAlert</p>
                    </div>

                </div>
                <div className="bloc-2">
                    <h3>Législation</h3>

                    <div className="bloc-1_elt-list">
                        
                    </div>
                </div>
                <div className="bloc-3">
                    <h3>Produits utiles</h3>
                </div>
                <div className="bloc-4">
                    <h3>Contact</h3>
                    - contact@kappze.com
                    - instagram
                    - facebook
                </div>
            </div>

        </div>
    );
}

export default UsefulLinks;
import React from "react";
import { NavLink } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import IconMaleOutline from '../../assets/male-outline-black.svg';
import IconFemaleOutline from '../../assets/female-outline-black.svg';
import IconUnknownOutline from '../../assets/help-outline-orange.svg';
import IconCity from '../../assets/trail-sign-outline.svg'
import CheckmarkIcon from '../../assets/checkmark-outline.svg'
import CloseIcon from '../../assets/close-outline.svg'
import { formatDateToFrench } from "../../utils/formatDate";
import { useTranslation } from 'react-i18next';
import BannerPlaceHolder from '../../assets/placeholder-banner-kappze.png'

const AnimalCard = (props) => {
    const { t } = useTranslation();
    const icon = props.animal.sex === 'Mâle' ? IconMaleOutline : props.animal.sex === 'Femelle' ? IconFemaleOutline : IconUnknownOutline;
    return (<>
        <NavLink className='card card-animal' to={`/animal/${props.animal.id}`}>
            {props.animal.isBelonged === true && (
                <div className="pastille_situation pastille_situation-belonged pastille_situation-card">
                    <span className="pastille_role-editor-text">{t('owner')}</span>
                </div>
            )}

            {props.animal.isCatMissing === true && (
                <div className="pastille_lost pastille_situation-lost pastille_situation-card">
                    <span className="pastille_role-editor-text">Perdu</span>
                </div>
            )}

            {(!props.animal.isBelonged && !props.animal.hasIdNumber) && (
                <div className="pastille_situation pastille_situation-errant pastille_situation-card" >
                    <span className="pastille_role-editor-text">{t('wandering')}</span>
                </div>
            )}

            {(!props.animal.isBelonged && props.animal.hasIdNumber) && (
                <div className="pastille_situation pastille_situation-libre pastille_situation-card">
                    <span className="pastille_role-editor-text">{t('free')}</span>
                </div>
            )}

            <div className="card-animal__image-container">
                {props.animal.image.url ? (
                    <LazyLoadImage
                        effect='blur'
                        src={props.animal.image.url}
                        alt={`Image de ${props.animal.name}`}
                        style={props.animal.death ? { filter: 'grayscale(100%)' } : {}}
                    />
                ) : (
                    <LazyLoadImage
                        effect='blur'
                        src={BannerPlaceHolder}
                        alt={`Image de ${props.animal.name}`}
                        style={{
                            backgroundColor: 'transparent',
                            ...(props.animal.death ? { filter: 'grayscale(100%)' } : {})
                        }}
                    />
                )}
            </div>


            <div className="card-animal__content">
                <span className="card-first_row">
                    <h2 className="card-animal__title">{props.animal.name ? props.animal.name : 'Aucun nom'}</h2>
                    <img src={icon} />
                </span>

                {props.animal.death === true && (
                    <span className="card-second_row" style={{ marginTop: '0px' }}>
                        <p className="card-animal__info" style={{ fontWeight: 'bold' }}><span style={{ color: '#000', marginRight: '13px', marginLeft: '7px' }}>✟</span>{t('death')}</p>
                    </span>
                )}

                {(props.sectorsEnabled === true || props.isAdmin) && (
                    <span className="card-second_row">
                    <p className="card-animal__info"><img src={IconCity} className="button-icon-cards button-icon-cards-additionnal" /> {props.animal.citySectorName ? props.animal.citySectorName : 'Inconnu'}</p>
                </span>
                )}




{/* {props.isAdmin === true ? (

    props.animal.isSterilise === true &&
                    (
                        <span className="card-second_row" style={{ marginTop: '0px' }}>
                            <p className="card-animal__info" style={{ fontWeight: '600' }}><img src={CheckmarkIcon} className="button-icon-cards button-icon-cards-additionnal" />{t('sterilized')}</p>
                        </span>
                    )}
                {props.animal.isSterilise === false && (
                    <span className="card-second_row" style={{ marginTop: '0px' }}>
                        <p className="card-animal__info" style={{ fontWeight: '600' }}><img src={CloseIcon} className="button-icon-cards button-icon-cards-additionnal button-icon-cards-negative" />{t('sterilized')}</p>
                    </span>
                )}
                {(props.animal.isSterilise === null || props.animal.isSterilise === '' )&& (
                    <span className="card-second_row" style={{ marginTop: '0px' }}>
                        <p className="card-animal__info" style={{ fontWeight: '600' }}><img src={IconUnknownOutline} className="button-icon-cards button-icon-cards-additionnal button-icon-cards-unknown" />{t('sterilized')}</p>
                    </span>
                
) : (null)} */}

{props.isAdmin === true ? (
    <>
        {props.animal.isSterilise === true ? (
            <span className="card-second_row" style={{ marginTop: '0px' }}>
                <p className="card-animal__info" style={{ fontWeight: '600' }}>
                    <img src={CheckmarkIcon} className="button-icon-cards button-icon-cards-additionnal" />
                    {t('sterilized')}
                </p>
            </span>
        ) : props.animal.isSterilise === false ? (
            <span className="card-second_row" style={{ marginTop: '0px' }}>
                <p className="card-animal__info" style={{ fontWeight: '600' }}>
                    <img src={CloseIcon} className="button-icon-cards button-icon-cards-additionnal button-icon-cards-negative" />
                    {t('sterilized')}
                </p>
            </span>
        ) : (
            <span className="card-second_row" style={{ marginTop: '0px' }}>
                <p className="card-animal__info" style={{ fontWeight: '600' }}>
                    <img src={IconUnknownOutline} className="button-icon-cards button-icon-cards-additionnal button-icon-cards-unknown" />
                    {t('sterilized')}
                </p>
            </span>
        )}
    </>
) : (null)}






                {/* {(props.animal.isTrapped) && (
                <div className="pastille_situation pastille_situation-trapped pastille_situation-trapped-card">
                    <span className="pastille_role-editor-text">{t('capture')}</span>
                </div>
            )} */}
                {props.animal.published === false && (
                    <span className="card-second_row" style={{ marginTop: '0px' }}>
                        <p className="card-animal__info">
                            <strong>{t('reportingEmail')} :</strong>&nbsp;{props.animal.userSignal}
                        </p>
                    </span>

                )}

                {props.animal.published === false && (
                    <span className="card-second_row" style={{ marginTop: '0px' }}>
                        <p className="card-animal__info"><strong>{t('dateOfReport')} :</strong> &nbsp;{formatDateToFrench(props.animal.addedDate)}</p>
                    </span>
                )}
                {props.animal.published === false && (
                    <span className="card-second_row" style={{ marginTop: '0px' }}>
                        <p className="card-animal__info"><strong>{t('descriptionOfTheReport')} : </strong>&nbsp;{props.animal.commentSignal}</p>
                    </span>
                )}

            </div>
        </NavLink>
    </>
    );
}



export default AnimalCard;

import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useSelector, useDispatch } from 'react-redux';
import IconPhone from '../../assets/call-outline.svg';
import IconMail from '../../assets/mail-outline.svg';
import heartblack from '../../assets/heart-circle.svg';
import IconLocalisation from '../../assets/location-outline.svg';
import IconPaw from '../../assets/paw-outline.svg';
import IconPeople from '../../assets/people-outline.svg';
import { fetchAnimalsByCanal } from "../../features/animals/animalSlice";
import { fetchCanalUsers } from '../../features/canals/canalUsersSlice';
import { useTranslation } from 'react-i18next';
import BannerPlaceHolder from '../../assets/cat-not-found.png'
import { fetchAnimalsByAssociation, fetchAssociationsDetails, fetchSingleAssociationDetails } from '../../features/association/associationSlice';
import { a } from 'react-spring';
import { setIsLinkedToAnAssociation } from '../../features/user/userSlice';
import { fetchUsersByAssociation } from '../../features/user/fetchingUserSlice';

const AssociationCard = ({ association }) => {
    const [localAnimals, setLocalAnimals] = useState([]);
    const [localUserCanal, setLocalUsersCanal] = useState([]);
    const { uid } = useSelector((state) => state.auth);
    const { isAuthenticated  } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const animalsAssociation = useSelector((state) => state.associations?.animals)
    const selfAssociation = useSelector((state) => state.associations?.singleAssociationDetails)
    const [associationDetails, setAssociationDetails] = useState(null);
    const userAssociation = useSelector((state) => Object.entries(state.users?.members))
    const { t } = useTranslation();

    // useEffect(() => {
    //     if (isAuthenticated) {
    //         dispatch(fetchAnimalsByCanal(association?.id)).then((result) => {
    //             setLocalAnimals(result.payload);
    //         });
    //         dispatch(fetchCanalUsers(association?.id)).then((result) => {
    //             setLocalUsersCanal(result.payload)
    //         })
    //     }
    // }, [association, dispatch, isAuthenticated]);

    useEffect(() => {
        if(isAuthenticated) {
            // dispatch(fetchSingleAssociationDetails(association)).then((result) => {
            //     setAssociationDetails(result.payload);
            // });
            dispatch(fetchAnimalsByAssociation({linkedAssociationId: association.siret, isLinkedToAnAssociation: true})).then((result) => {
                setLocalAnimals(result.payload);
            })
            dispatch(fetchUsersByAssociation({linkedAssociationId: association.siret, isLinkedToAnAssociation: true})).then((res) => {
                setLocalUsersCanal(res.payload)
            })
        }
    }, [association, dispatch, isAuthenticated]);

    return (
        <>
            <NavLink className='card-canal' to={`/association/${association?.id}`}>

                <div className="card-canal_img-title">
                    {association?.name ? (<h2>{association?.name}</h2>) : (<h2>{associationDetails?.name}</h2>)}
                    {association?.image?.url ? (
                        <LazyLoadImage effect="none" src={association?.image.url} style={{ objectFit: 'contain' }} alt={`Image de ${association?.name}`} />
                    ) : (

                        <LazyLoadImage effect="none" src={heartblack} style={{ objectFit: 'cover', width:'90px', opacity: '0.8' }} alt={`Image de ${association?.name}`} />


                    )}
                        <div className="pastille_role pastille_role-contributor">
                            <span className="pastille_role-editor-text">{t('contributor')}</span>
                        </div>

                    
                </div>

                <div className="img-informations-general">
                    <span style={{ display: 'flex', alignItems: 'center' }}><img src={IconPaw} className="button-icon-cards" /> <p>{localAnimals?.length}</p></span>
                    <span style={{ display: 'flex', alignItems: 'center' }}><img src={IconPeople} className="button-icon-cards" /> <p>{localUserCanal?.length}</p></span>
                </div>
                <div className="img-informations">


                    <div className="img-informations-icon">

                        <span style={{ display: 'flex', alignItems: 'center' }}><img src={IconLocalisation} className="button-icon-cards button-icon-cards-additionnal" /> <p>{association?.communeLibelle}, {association?.postalCode}</p></span>
                    </div>
                </div>

            </NavLink>
        </>
    );
}

export default AssociationCard;

export const RACES = {
    "abyssin": "Abyssin",
    "american curl": "American Curl",
    "american shorthair": "American Shorthair",
    "angora turc": "Angora Turc",
    "balinais": "Balinais",
    "bengal": "Bengal",
    "bleu russe": "Bleu Russe",
    "bobtail japonais": "Bobtail japonais",
    "bombay": "Bombay",
    "british shorthair": "British Shorthair",
    "burmese": "Burmese",
    "burmilla": "Burmilla",
    "chartreux": "Chartreux",
    "chat de gouttiere": "Chat de gouttière",
    "chinchilla": "Chinchilla",
    "cornish rex": "Cornish Rex",
    "devon rex": "Devon Rex",
    "german rex": "German Rex",
    "highland fold": "Highland Fold",
    "korat": "Korat",
    "laperm": "Laperm",
    "maine coon": "Maine Coon",
    "mandarin": "Mandarin",
    "manx": "Manx",
    "mau egyptien": "Mau Egyptien",
    "munchkin": "Munchkin",
    "nebelung": "Nebelung",
    "norvegien": "Norvégien",
    "persan": "Persan",
    "ragdoll": "Ragdoll",
    "sacre de birmanie": "Sacré de Birmanie",
    "scottish fold": "Scottish Fold",
    "seregenti": "Seregenti",
    "siamois": "Siamois",
    "siberien": "Sibérien",
    "singapura": "Singapura",
    "snowshoe": "Snowshoe",
    "sokoke": "Sokoké",
    "somali": "Somali",
    "sphynx": "Sphynx",
    "tiffany": "Tiffany",
    "tonkinois": "Tonkinois",
    "turc de van": "Turc de Van",
    "york chocolate": "York Chocolate",
    "europeen": "Européen",
    "exotic shorthair": "Exotic Shorthair",
    "havana brown": "Havana Brown",
    "ocicat": "Ocicat",
    "oriental": "Oriental"
};


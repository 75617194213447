import React from 'react';
import IconMaleOutline from '../../assets/male-outline-black.svg';
import IconFemaleOutline from '../../assets/female-outline-black.svg';
import { useTranslation } from 'react-i18next';

const TotalIdentificationGraph = ({ animals, year }) => {
    const { t } = useTranslation();
    const previousYear = (parseInt(year) - 1).toString();


    // console.log('YEAR : ', year)
    const countIdentifications = () => {
        return animals.filter(animal =>
            animal?.dateIdentification?.startsWith(year) &&
            animal?.trappedDate?.startsWith(year) &&
            animal?.addedAsStray === true
        ).length;  
    };

    const countIdentificationsFemale = () => {
        return animals.filter(animal =>
            animal?.dateIdentification?.startsWith(year) &&
            animal?.trappedDate?.startsWith(year) &&
            animal?.addedAsStray === true &&
            animal?.sex === "Femelle"
        ).length;
    };

    const countIdentificationsMale = () => {
        return animals.filter(animal =>
            animal?.dateIdentification?.startsWith(year) &&
            animal?.trappedDate?.startsWith(year) &&
            animal?.addedAsStray === true &&
            animal?.sex === "Mâle"
        ).length;
    };



    const countIdentificationsLastYear = () => {
        return animals.filter(animal =>
            animal?.dateIdentification?.startsWith(previousYear) &&
            animal?.trappedDate?.startsWith(previousYear) &&
            animal?.addedAsStray === true
        ).length;
    };

    const countIdentificationsFemaleLastYear = () => {
        return animals.filter(animal =>
            animal?.dateIdentification?.startsWith(previousYear) &&
            animal?.trappedDate?.startsWith(previousYear) &&
            animal?.addedAsStray === true &&
            animal?.sex === "Femelle"
        ).length;
    };

    const countIdentificationsMaleLastYear = () => {
        return animals.filter(animal =>
            animal?.dateIdentification?.startsWith(previousYear) &&
            animal?.trappedDate?.startsWith(previousYear) &&
            animal?.addedAsStray === true &&
            animal?.sex === "Mâle"
        ).length;
    };




    const calculatePercentageDifference = (current, previous) => {
        if (previous === 0) return 'N/A'; // Pour éviter la division par zéro
        let difference = (((current - previous) / previous) * 100).toFixed(2);
        let sign = difference >= 0 ? '+' : ''; // Ajoute un '+' si la différence est positive ou zéro
        return `${sign}${difference}%`;
    };


    const totalIdentifications = countIdentifications();
    const totalIdentificationsFemale = countIdentificationsFemale();
    const totalIdentificationsMale = countIdentificationsMale();

    const totalIdentificationsLastYear = countIdentificationsLastYear();
    const totalIdentificationsFemaleLastYear = countIdentificationsFemaleLastYear();
    const totalIdentificationsMaleLastYear = countIdentificationsMaleLastYear();

    const percentageTotal = calculatePercentageDifference(totalIdentifications, totalIdentificationsLastYear);
    const percentageFemale = calculatePercentageDifference(totalIdentificationsFemale, totalIdentificationsFemaleLastYear);
    const percentageMale = calculatePercentageDifference(totalIdentificationsMale, totalIdentificationsMaleLastYear);

    return (
        <div className="signalements-graph">
            {/* Bloc Total identifiés */}
            <p style={{ color: 'black', marginLeft: '1rem', fontWeight: 'bolder', textAlign: 'left' }}><img src={IconFemaleOutline} style={{width:'27px'}}/>  <img src={IconMaleOutline} style={{width:'27px'}}/></p>


            <div style={{ display: 'flex', flexDirection: 'row', columnGap: '1rem' }}>
                <div style={{ textAlign: 'center' }}>
                    <div className="signalements-count">
                        {totalIdentifications}
                    </div>
                    <div className="signalements-label">
                        {t('graph.identifications.numberOfIdentificatedCats')} {t('in')} {year}
                    </div>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <div className="signalements-count">
                    {percentageTotal}
                    </div>
                    <div className="signalements-label">
                    {totalIdentificationsLastYear} {t('graph.identifications.identificatedCats')} {t('in')} {previousYear}
                    </div>
                </div>

            </div>


            {/* Bloc Femelles identifiés */}
            <p style={{ color: 'black', marginLeft: '1rem', fontWeight: 'bolder', textAlign: 'left' }}><img src={IconFemaleOutline} style={{width:'27px'}}/></p>

            <div style={{ display: 'flex', flexDirection: 'row', columnGap: '1rem' }}>
                <div style={{ textAlign: 'center' }}>
                    <div className="signalements-count">
                        {totalIdentificationsFemale}
                    </div>
                    <div className="signalements-label">
                        {t('graph.identifications.numberOfIdentificatedFemales')} {t('in')} {year}
                    </div>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <div className="signalements-count">
                    {percentageFemale}
                        
                    </div>
                    <div className="signalements-label">
                    {totalIdentificationsFemaleLastYear} {t('graph.identifications.identificatedFemales')} {t('in')} {previousYear}
                    </div>
                </div>


            </div>


            {/* Bloc Mâles identifiés */}
            <p style={{ color: 'black', marginLeft: '1rem', fontWeight: 'bolder', textAlign: 'left' }}><img src={IconMaleOutline} style={{width:'27px'}}/></p>

            <div style={{ display: 'flex', flexDirection: 'row', columnGap: '1rem' }}>

                <div style={{ textAlign: 'center' }}>
                    <div className="signalements-count">
                        {totalIdentificationsMale}
                    </div>
                    <div className="signalements-label">
                        {t('graph.identifications.numberOfIdentificatedMales')} {t('in')} {year}
                    </div>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <div className="signalements-count">
                        {percentageMale}

                    </div>
                    <div className="signalements-label">
                        {totalIdentificationsMaleLastYear} {t('graph.identifications.identificatedMales')} {t('in')} {previousYear}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TotalIdentificationGraph;

import React, { useState, useEffect } from 'react';
import AssosListByCp from '../../components/general/AssosListByCp';// Assurez-vous que le chemin d'importation est correct
import iconhouseBlack from '../../assets/icon-house-black.svg'; // Assurez-vous d'importer correctement les ressources nécessaires
import heartblack from '../../assets/heart-circle.svg';

const JoinCanalPopup = ({ show, handleClose, handleConfirm, canals, animation, users }) => {
    

    const [nomAsso, setNomAsso] = useState('');
    const [codeCommune, setCodeCommune] = useState('');
    const [postalCode, setPostalCode] = useState('')
    const [nameAsso, setNameAsso] = useState('');
        // Mise à jour de nameAsso lorsque nomAsso change
    useEffect(() => {
        if (!nameAsso || nomAsso === nameAsso) {
            setNameAsso(nomAsso);
        }
    }, [nomAsso, nameAsso]);

    useEffect(() => {
        // Met à jour nameAsso pour refléter le nouveau nomAsso chaque fois que nomAsso change.
        // Vous pouvez également ajouter une logique supplémentaire ici si nécessaire pour gérer les changements de codeCommune.
        setNameAsso(nomAsso);
    }, [nomAsso]); // Dépendance uniquement à nomAsso ici, puisque c'est ce qui déclenche la mise à jour.
    

    const handleCodePostalChange = (newCodePostal) => {
        setPostalCode(newCodePostal);
      };

    // const handleAliasChange = (e) => {
    //     setCanalAlias(e.target.value);
    // };

        // Mise à jour de nameAsso lorsque nomAsso change
        useEffect(() => {
            if (!nameAsso || nomAsso === nameAsso) {
                setNameAsso(nomAsso);
            }
        }, [nomAsso, nameAsso]);

    return show ? (
        <div className={`popup-container ${animation}`}>
        <div className="popup">
            <span className="popup-header" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img src={heartblack} style={{ maxWidth: '50px', marginBottom: 0, paddingBottom: 0, marginTop: '10px' }} />

                <h2 className='editable-fields-label'>Ajouter une association</h2>
            </span>

            <form id="form-create" className="popup-singleinput" >

            <AssosListByCp canals={canals} handleConfirm={handleConfirm} setCodeCommune={setCodeCommune} setNomAsso={setNomAsso} onCodePostalChange={handleCodePostalChange} users={users} />

            </form>
            <div className="popup-btns">
            <button className="btn-cancel" onClick={handleClose}>Fermer</button>
            </div>
        </div>
    </div>
    ) : null;
};

export default JoinCanalPopup;

// import { COLORS } from '../../data/colors';


// const ColorSelect = ({ selectedColors, onChange }) => {

//     const handleColorChange = (event) => {
//         if (onChange) {
//             onChange(event);
//         }
//     };
//     const filteredSelectedColors = selectedColors.filter(color => Object.values(COLORS).includes(color));

//     return (
//         <div style={{ paddingTop: '1rem', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
//             {Array.from(new Set(Object.values(COLORS))).map((colorValue, index) => {
//                 return (
//                     <span style={{ width: '30%', paddingRight: '1rem', display: 'flex', alignItems: 'center' }} key={index}>
//                         <input
//                             type="checkbox"
//                             id={`color-${colorValue}`}
//                             value={colorValue}
//                             checked={filteredSelectedColors.includes(colorValue)}
//                             onChange={handleColorChange}
//                         />
//                         <label style={{ color: 'black', marginLeft: '0.5rem' }} htmlFor={`color-${colorValue}`}>
//                             {colorValue.charAt(0).toUpperCase() + colorValue.slice(1)}
//                         </label>
//                     </span>
//                 );
//             })}
//         </div>
//     );
// };


// export default ColorSelect;

import React from 'react';
import { COLORS } from '../../data/colors';
import { Tooltip } from 'react-tooltip'; // Assurez-vous d'avoir le bon import pour votre bibliothèque de tooltips
import helpOutline from '../../assets/help-outline.svg';

const ColorSelect = ({ selectedColors, onChange }) => {

    const handleColorChange = (event) => {
        if (onChange) {
            onChange(event);
        }
    };

    const filteredSelectedColors = selectedColors.filter(color => Object.keys(COLORS).some(key => COLORS[key].name === color));

    return (
        <div style={{ paddingTop: '1rem', display: 'flex', flexWrap: 'wrap', columnGap:'2rem', rowGap: '10px' }}>
            {Object.entries(COLORS).map(([colorKey, { name, imagePath }], index) => (
                <span style={{ width: '30%', paddingRight: '1rem', display: 'flex', alignItems: 'center' }} key={index}>
                    <input
                        type="checkbox"
                        id={`color-${name}`}
                        value={name}
                        checked={filteredSelectedColors.includes(name)}
                        onChange={handleColorChange}
                        style={{cursor: 'pointer'}}
                        
                    />
                    <label style={{ color: 'black', marginLeft: '0.5rem', cursor: 'pointer' }} htmlFor={`color-${name}`}>
                        {name.charAt(0).toUpperCase() + name.slice(1)}
                        {/* <Tooltip place="top" type="dark" effect="float" id={name}> */}
                        <Tooltip style={{ backgroundColor: "#122", color: "#fff", padding: '1rem', maxWidth: '150px' }} id={name}>
                        <h3 style={{marginBottom: '1rem'}}>{name}</h3>
                            <img src={imagePath} alt={name} style={{ width: 150, height: 150 }} />
                        </Tooltip>
                    </label>
                    <img style={{ width: '15px', height: '15px', borderRadius: '50%', border: '1px solid black', background: '#122', marginBottom: '10px', marginLeft: '10px' }} src={helpOutline} alt="Icon Compass" className="button-icon" data-tooltip-id={name} />
                </span>
            ))}
        </div>
    );
};

export default ColorSelect;

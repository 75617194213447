export const PaymentItem = ({ payment }) => {
    return (
        <div style={{ padding: 10, borderBottomWidth: 1, borderBottomColor: '#e0e0e0', backgroundColor: '#fff', borderRadius: '2px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', columnGap: '1rem', flexWrap: 'wrap', alignItems: 'flex-start', paddingLeft: '1rem' }}>
                <div style={{display: 'flex', flexDirection: 'column', rowGap: '1rem'}}>
                    <h3 style={{ color: '#2f4f4f' }}>Infos du paiement</h3>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <div style={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}><span style={{ fontWeight: 'bolder', color: '#2f2f2f' }}>Date : </span><p style={{ color: '#000', margin:'7px' }}>{new Date(payment.created * 1000).toLocaleDateString()}</p></div>
                        <div style={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}><span style={{ fontWeight: 'bolder', color: '#2f2f2f' }}>Montant : </span><p style={{ color: '#000', margin:'7px' }}>{(payment.amount / 100).toFixed(2)} {payment.currency.toUpperCase()}</p></div>
                        <div style={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}><span style={{ fontWeight: 'bolder', color: '#2f2f2f' }}>Statut : </span><p style={{ color: '#000', margin:'7px'  }}>{payment.status}</p></div>
                        <div style={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}><span style={{ fontWeight: 'bolder', color: '#2f2f2f' }}>Description : </span><p style={{ color: '#000', margin:'7px' }}>{payment.description || "N/A"}</p></div>
                    </div>
                </div>
                
                <div className="facturation-elt">
                    <h3 className="facturation-elt-title">Facturation :</h3>
                    <div className="facturation-elt-content">
                        {/* <div style={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}><span style={{ fontWeight: 'bolder', color: '#2f2f2f' }}>Nom : </span><p style={{ color: '#000', margin:'7px' }}>{payment.shipping?.name ? payment.shipping.name : 'Nom non renseigné'}</p></div>
                        <div style={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}><span style={{ fontWeight: 'bolder', color: '#2f2f2f' }}>Adresse : </span><p style={{ color: '#000', margin:'7px' }}>{payment.shipping?.address?.line1 ? payment.shipping.address.line1 : 'Adresse non renseignée'}</p></div>
                        <div style={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}><span style={{ fontWeight: 'bolder', color: '#2f2f2f' }}>Code postal : </span><p style={{ color: '#000', margin:'7px'}}>{payment.shipping?.address?.postal_code ? payment.shipping.address.postal_code : 'Code postal non renseigné'}</p></div>
                        <div style={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}><span style={{ fontWeight: 'bolder', color: '#2f2f2f' }}>Ville & pays : </span><p style={{ color: '#000', margin:'7px'}}>{payment.shipping?.address?.city ? payment.shipping.address.city : 'Ville non renseignée'}, {payment.shipping?.country ? payment.shipping.country : 'Pays non renseigné'}</p></div>
                        <div style={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}><span style={{ fontWeight: 'bolder', color: '#2f2f2f' }}>Email : </span><p style={{ color: '#000', margin:'7px' }}>{payment.email ? payment.email : 'Non renseigné'}</p></div>
                        <div style={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}><span style={{ fontWeight: 'bolder', color: '#2f2f2f' }}>Téléphone : </span><p style={{ color: '#000', margin:'7px' }}>{payment.phone ? payment.phone : 'Non renseigné'}</p></div> */}
                        <div style={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}><span style={{ fontWeight: 'bolder', color: '#2f2f2f' }}>Facture & reçu : </span><p style={{ color: '#000', margin:'7px' }}><a href={payment.receipt_url} target="_blank"
                            rel="noreferrer" style={{ color: 'black', marginRight: '16px', marginLeft: '16px', textDecoration: 'underline' }}>
                            Télécharger
                        </a></p></div>


                    </div>
                </div>
            </div>


        </div>
    );
};

import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { createUserWithEmailAndPassword, sendEmailVerification, getAuth, signInWithEmailAndPassword, sendPasswordResetEmail, updateEmail as updateEmailAuth, updatePassword as updatePasswordAuth, signOut, deleteUser as deleteAuthUser, onAuthStateChanged } from 'firebase/auth';
import { updateDoc, getFirestore, doc, getDoc, collection, query, where, getDocs, setDoc, deleteDoc, Timestamp } from 'firebase/firestore';
import { firebaseInstance } from '../../index.js';
import { addLicence, fetchLicenceById } from '../licences/licenceSlice.js';
import { persistor } from '../../store/store.js';
import { promptUserForReauthentication } from '../../components/modals/loginModal.jsx';


export const loginUser = createAsyncThunk(
    'auth/loginUser',
    async ({ email, password }, { dispatch, rejectWithValue }) => {
        try {
            const auth = getAuth();
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            if (!user.emailVerified) {
                throw new Error('EMAIL_NOT_VERIFIED');
            }

            const db = getFirestore();
            const userRef = doc(db, 'users', user.uid);
            const docSnapshot = await getDoc(userRef);

            if (docSnapshot.exists()) {
                // Extraire les données actuelles
                const userData = docSnapshot.data();

                // Mise à jour de l'email vérifié dans Firestore si nécessaire
                if (!userData.emailWasVerified) {
                    await updateDoc(userRef, { emailWasVerified: true });
                }

                // Dispatcher les actions Redux pour mettre à jour l'état local
                dispatch(setUserDetails(userData, user.emailVerified));

                return { uid: user.uid, email: user.email };
            } else {
                throw new Error('No user data in Firestore');
            }
        } catch (error) {
            return rejectWithValue({
                code: error.code || 'UNKNOWN_ERROR',
                message: error.message || 'An unknown error occurred.'
            });
        }
    }
);

function setUserDetails(userData, emailVerified) {
    return async (dispatch) => {
        dispatch(setName(userData.name));
        dispatch(setSurname(userData.surname));


        if (userData.licenceNumber) {
            dispatch(setLicence(userData.licenceNumber));

            // Récupérer et définir l'état de la licence
            const licenceData = await dispatch(fetchLicenceById(userData.licenceNumber)).unwrap();
            
            const licenceStatus = licenceData?.status === 'active' ? 'active' : licenceData?.status === 'cancelled' ? 'cancelled' : 'inactive';
            dispatch(setLicenceStatus(licenceStatus));
        } else {
            dispatch(setLicenceStatus(null)); // ou 'inactive'
        }

        if (userData.productType) {
            dispatch(setCurrentProductType(userData.productType));
        }

        dispatch(setIsMairie(userData.isMairie));
        dispatch(setIsBeta(userData.isBeta));
        dispatch(setIsAssociation(userData.isAssociation));
        dispatch(setIsLinkedToAnAssociation(userData.isLinkedToAnAssociation));
        dispatch(setLinkedAssociationsIds(userData.linkedAssociationIds));
        dispatch(setAssociationId(userData.associationId));
        dispatch(setMairieName(userData.mairieName));
        dispatch(setAssociationName(userData.associationName));
        dispatch(setRegistrationDate(userData.registrationDate.toDate()));
        dispatch(setNotificationsEnabled(userData.notificationsEnabled));
        dispatch(setNotificationsAdminPreferences(userData.notificationsAdminPreferences))
        dispatch(setStripeCustomerId(userData.stripeCustomerId));
        dispatch(setAdminRequest(userData.requestAdmin));
        dispatch(setSiretUser(userData.siret));
        dispatch(setSiretAssoUser(userData.siretAsso));
        dispatch(setRnaAsso(userData.rnaAsso));
        dispatch(setNewsletterConsent(userData.newsletterConsent));
        dispatch(setEmailVerified(emailVerified));
        dispatch(setUserType(userData.userType));
        dispatch(setSubscriptionId(userData.subscriptionId));
        dispatch(setLicenceStatus(userData.licenceStatus));
    };
}





export const checkUserAuthStatus = createAsyncThunk(
    "auth/checkUserAuthStatus",
    async (_, { dispatch, rejectWithValue }) => {
        const auth = getAuth();

        return new Promise((resolve, reject) => {
            const unsubscribe = onAuthStateChanged(auth, async (user) => {
                if (user) {
                    const db = getFirestore();
                    const userRef = doc(db, "users", user.uid);
                    const docSnapshot = await getDoc(userRef);

                    if (docSnapshot.exists()) {
                        const userData = docSnapshot.data()
                        const stripeCustomerId = docSnapshot.data().stripeCustomerId;
                        const stripeSubscriptionId = docSnapshot.data().subscriptionId;
                        const licenceStatus = docSnapshot.data().licenceStatus;
                        if (stripeCustomerId) {
                            dispatch(setStripeCustomerId(stripeCustomerId));
                        }
                        if(stripeSubscriptionId){
                            dispatch(setSubscriptionId(stripeSubscriptionId));
                            dispatch(setLicenceStatus(licenceStatus));
                        }

                        const {
                            name,
                            surname,
                            licenceNumber,
                            isMairie,
                            isAssociation,
                            mairieName,
                            associationName,
                            notificationsEnabled, requestAdmin, notificationsAdminPreferences, siret, siretAsso, rnaAsso, animalsSignal, newsletterConsent, emailWasVerified, productType
                            , isLinkedToAnAssociation, associationId, linkedAssociationIds, userType, isBeta } = docSnapshot.data();

                        dispatch(setName(name));
                        dispatch(setSurname(surname));
                        if (licenceNumber) {
                            dispatch(setLicence(licenceNumber));
                        }

                        if (productType) {
                            dispatch(setCurrentProductType(productType));
                        }

                        dispatch(setIsMairie(isMairie));
                        dispatch(setIsBeta(isBeta));
                        dispatch(setIsAssociation(isAssociation));
                        dispatch(setIsLinkedToAnAssociation(isLinkedToAnAssociation));
                        dispatch(setLinkedAssociationsIds(linkedAssociationIds));
                        dispatch(setAssociationId(associationId));
                        dispatch(setMairieName(mairieName));
                        dispatch(setAssociationName(associationName));
                        dispatch(setNotificationsEnabled(notificationsEnabled));
                        dispatch(setProductType(productType));
                        dispatch(setAdminRequest(requestAdmin));
                        dispatch(setNotificationsAdminPreferences(notificationsAdminPreferences))
                        dispatch(setSiretUser(siret));
                        dispatch(setSiretAssoUser(siretAsso));
                        dispatch(setRnaAsso(rnaAsso));
                        dispatch(setEmailVerified(user.emailVerified))
                        dispatch(setUserType(userType))
                        dispatch(setNewsletterConsent(newsletterConsent));
                        dispatch(setSubscriptionId(userData.subscriptionId));


                        // Mise à jour de l'email vérifié dans Firestore si nécessaire
                        if (user.emailVerified && !userData.emailWasVerified) {
                            await updateDoc(userRef, { emailWasVerified: true });
                        }

                        // Utilisation de localStorage au lieu d'AsyncStorage
                        localStorage.setItem("userIsLoggedIn", "true");
                        resolve({ uid: user.uid, email: user.email });
                    } else {
                        reject("User data does not exist in Firestore");
                    }
                } else {
                    // Utilisation de localStorage au lieu d'AsyncStorage
                    localStorage.removeItem("userIsLoggedIn");
                    reject("User is not logged in");
                }

                // Se désabonner de l'écouteur
                unsubscribe();
            }, reject);
        });
    }
);



export const loginWithGoogle = createAsyncThunk(
    'auth/loginWithGoogle',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const provider = new firebaseInstance.auth.GoogleAuthProvider();
            const userCredential = await firebaseInstance.auth().signInWithPopup(provider);
            const user = userCredential.user;

            const db = firebaseInstance.firestore();
            const userRef = db.doc(`users/${user.uid}`);
            const docSnapshot = await userRef.get();

            // Si l'utilisateur n'existe pas dans Firestore, créez-le
            if (!docSnapshot.exists) {
                const userData = {
                    name: user.displayName,
                    surname: '',  // Google ne fournit pas de prénom/nom séparés, donc vous devrez peut-être gérer cela différemment
                    email: user.email,
                    photoURL: user.photoURL
                };

                await userRef.set(userData);
                dispatch(setName(userData.name));
                dispatch(setSurname(userData.surname));
            } else {
                const { name, surname } = docSnapshot.data();
                dispatch(setName(name));
                dispatch(setSurname(surname));
            }

            return { uid: user.uid, email: user.email, name: user.displayName, photoURL: user.photoURL };
        } catch (error) {
            if (error.code === 'auth/account-exists-with-different-credential') {
                // L'utilisateur a déjà un compte avec une méthode d'authentification différente
                const email = error.email;
                const pendingCred = error.credential;

                // Récupérez les méthodes d'authentification associées à cet e-mail
                const methods = await firebaseInstance.auth().fetchSignInMethodsForEmail(email);

                // Si l'utilisateur a un compte avec un mot de passe
                if (methods.includes('password')) {
                    // Demandez à l'utilisateur son mot de passe
                    const password = prompt('Veuillez entrer votre mot de passe:'); // Vous devriez utiliser une méthode plus sécurisée pour obtenir le mot de passe
                    const userCredential = await firebaseInstance.auth().signInWithEmailAndPassword(email, password);

                    // Fusionnez les informations d'authentification
                    await userCredential.user.linkWithCredential(pendingCred);
                    return userCredential.user;
                } else {
                    // Gérez d'autres méthodes d'authentification si nécessaire
                    return rejectWithValue('Veuillez vous connecter avec votre méthode d\'authentification précédente.');
                }
            }

            return rejectWithValue(error.toString());
        }
    }
);

export const registerUser = createAsyncThunk(
    'user/registerUser',
    async ({ email, password, name, surname, userType, siret, rnaAsso, siretAsso, isMairie, mairieName, isAssociation, associationName, postalCode, communeCode, communeLibelle, postalCodeAsso, communeCodeAsso, communeLibelleAsso, newsletterConsent, associationReachable, isLinkedToAnAssociation, linkedAssociationIds, associationId }, { dispatch, rejectWithValue }) => {
        try {
            const auth = getAuth();
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Envoyer un e-mail de vérification
            await sendEmailVerification(user);

            const db = getFirestore();
            const userDoc = doc(db, 'users', user.uid);
            const userData = {
                name,
                surname,
                email,
                userType,
                siret,
                rnaAsso,
                siretAsso,
                postalCode,
                communeCode,
                communeLibelle,
                postalCodeAsso,
                communeCodeAsso,
                communeLibelleAsso,
                isMairie,
                mairieName,
                isAssociation,
                associationName,
                registrationDate: Timestamp.now(),
                notificationsEnabled: false,
                requestAdmin: false,
                emailWasVerified: false,
                newsletterConsent,
                associationReachable,
                isLinkedToAnAssociation,
                linkedAssociationIds,
                associationId
            };
            await setDoc(userDoc, userData);
            dispatch(setRegistrationDate(userData.registrationDate.toDate()));


            if (userData.siret && userData.userType === 'mairie') {
                const siretCommunityDoc = doc(db, 'siretCommunity', userData.siret);
                const siretCommunityData = {
                    creatorId: user.uid,
                    name: userData.mairieName,
                    siret: userData.siret,
                    postalCode: userData.postalCode,
                    communeCode: userData.communeCode,
                    communeLibelle: userData.communeLibelle
                };

                // console.log('siretCommunity Data ---> ', siretCommunityData)
                await setDoc(siretCommunityDoc, siretCommunityData);
            }


            if ((userData.rnaAsso || userData.siretAsso) && userData.isAssociation) {

                
                const assoId = userData.rnaAsso ? userData.rnaAsso : userData.siretAsso;

                // console.log("Association ID :", assoId);

                const organizationDoc = doc(db, 'organizations', assoId);
                const organizationData = {
                    creatorId: user.uid,
                    name: userData.associationName,
                    rna: userData.rnaAsso,
                    siret: userData.siretAsso,
                    postalCode: userData.postalCodeAsso,
                    communeCode: userData.communeCodeAsso,
                    communeLibelle: userData.communeLibelleAsso
                };
                await setDoc(organizationDoc, organizationData);
            }




            return { uid: user.uid, email: user.email };
        } catch (error) {
            return rejectWithValue({
                code: error.code || 'UNKNOWN_ERROR',
                message: error.message || 'An unknown error occurred.'
            });
        }
    }
);



export const updateUserName = createAsyncThunk(
    'user/updateUserName',
    async ({ userId, newName }) => {
        const db = getFirestore();
        const userRef = doc(db, 'users', userId);
        await updateDoc(userRef, { name: newName });
        return { newName };
    }
);

export const updateUserSurname = createAsyncThunk(
    'user/updateUserSurname',
    async ({ userId, newSurname }) => {
        const db = getFirestore();
        const userRef = doc(db, 'users', userId);
        await updateDoc(userRef, { surname: newSurname });
        return { newSurname }
    }
);


async function validateSiret(siret) {
    const db = getFirestore();
    const querySnapshot = await getDocs(query(collection(db, "users"), where("siret", "==", siret)));
    return querySnapshot.empty; // Retourne true si aucun document n'a été trouvé, donc le SIRET est unique
}

async function validateSiretAsso(siret) {
    const db = getFirestore();
    const querySnapshot = await getDocs(query(collection(db, "users"), where("siretAsso", "==", siret)));
    return querySnapshot.empty; // Retourne true si aucun document n'a été trouvé, donc le SIRET est unique
}


export const updateSiret = createAsyncThunk(
    'user/updateSiret',
    async ({ userId, newSiret }, { rejectWithValue }) => {
        if (!await validateSiret(newSiret)) {
            return rejectWithValue('SIRET déjà utilisé');
        }
        const db = getFirestore();
        const userRef = doc(db, 'users', userId);
        await updateDoc(userRef, { siret: newSiret });
        return { newSiret };
    }
);

export const updateSiretAsso = createAsyncThunk(
    'user/updateSiretAsso',
    async ({ userId, newSiretAsso }, { rejectWithValue }) => {
        if (!await validateSiretAsso(newSiretAsso)) {
            return rejectWithValue('SIRET déjà utilisé');
        }
        const db = getFirestore();
        const userRef = doc(db, 'users', userId);
        await updateDoc(userRef, { siretAsso: newSiretAsso });
        return { newSiretAsso };
    }
);


export const resetPassword = createAsyncThunk(
    'auth/resetPassword',
    async (email) => {
        const auth = getAuth();
        await sendPasswordResetEmail(auth, email);
    }
);


export const resetStatus = createAction('auth/resetStatus');


const checkEmailExistsInDatabase = async (email) => {
    const db = getFirestore();
    const usersCollection = collection(db, 'users');
    const emailQuery = query(usersCollection, where('email', '==', email));
    const querySnapshot = await getDocs(emailQuery);
    return querySnapshot.size > 0; // Vérifiez si la taille du querySnapshot est supérieure à zéro pour déterminer si l'e-mail existe
};


const fetchSignalsByUsers = async (userId) => {
    const db = getFirestore();
    const usersCollection = collection(db, 'users');
    const emailQuery = query(usersCollection, where('userCreatorId', '==', userId));
    const querySnapshot = await getDocs(emailQuery);
    return querySnapshot.size > 0; // Vérifiez si la taille du querySnapshot est supérieure à zéro pour déterminer si l'e-mail existe
}

export const checkEmailExists = createAsyncThunk(
    'auth/checkEmailExists',
    async (email) => {
        // Vérifier si l'adresse e-mail existe dans votre base de données ou autre source de données
        const exists = await checkEmailExistsInDatabase(email);

        if (!exists) {
            throw new Error('Adresse e-mail non valide');
        }
    }
);

export const updateUserNotificationPreference = createAsyncThunk(
    "notifications/updateUserNotificationPreference",
    async ({ newValue, userId }) => {
        try {
            const db = getFirestore();
            const userRef = doc(db, "users", userId); // Assurez-vous d'avoir l'userId

            await updateDoc(userRef, { notificationsEnabled: newValue });
        } catch (error) {
            console.error("Error updating user notification preference: ", error);
        }
    }
);

export const updateUserNewsletterPreference = createAsyncThunk(
    "user/updateUserNewsletterPreference",
    async ({ newValue, userId }) => {
        try {
            const db = getFirestore();
            const userRef = doc(db, "users", userId); // Assurez-vous d'avoir l'userId

            await updateDoc(userRef, { newsletterConsent: newValue });
        } catch (error) {
            console.error("Error updating user notification preference: ", error);
        }
    }
);

export const updateAdminNotificationPreference = createAsyncThunk(
    "notifications/updateAdminNotificationPreference",
    async ({ newValue, userId, canalId }, { rejectWithValue }) => {
        try {
            const db = getFirestore();
            const userRef = doc(db, "users", userId);

            await updateDoc(userRef, { [`notificationsAdminPreferences.${canalId}`]: newValue });

            return { userId, canalId, newValue };
        } catch (error) {
            console.error("Error updating user notification preference: ", error);
            return rejectWithValue(error);
        }
    }
);



export const updateEmail = createAsyncThunk(
    'user/updateEmail',
    async (newEmail) => {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
            await updateEmailAuth(user, newEmail);
        }
    }
);


export const updateAdminRequest = createAsyncThunk(
    'user/updateAdminRequest',
    async ({ uid, requestAdmin }) => {
        const db = getFirestore();
        const userRef = doc(db, 'users', uid);
        await updateDoc(userRef, { requestAdmin: requestAdmin });
        return { requestAdmin };
    }
);

export const updateSignalsTotal = createAsyncThunk(
    'user/updateSignalsTotal',
    async ({ uid }) => {
        const db = getFirestore();
        const userRef = doc(db, 'users', uid);

        // Commence par récupérer le document actuel pour l'utilisateur spécifié.
        const docSnap = await getDoc(userRef);

        if (docSnap.exists()) {
            // Récupère le nombre actuel de signalements ou le définit à 0 s'il n'existe pas.
            const currentSignals = docSnap.data().signalements || 0;

            // Incrémente le nombre de signalements.
            const updatedSignals = currentSignals + 1;

            // Met à jour le document avec le nouveau nombre de signalements.
            await updateDoc(userRef, { signalements: updatedSignals });

            // Retourne le nouveau nombre de signalements pour la mise à jour de l'état Redux, si nécessaire.
            return { signal: updatedSignals };
        } else {
            // Gérer le cas où le document n'existe pas, si nécessaire.
            console.log("Document does not exist!");
            // Vous pourriez vouloir retourner une erreur ou une valeur par défaut ici.
            return { signal: 0 };
        }
    }
);


// Définition de l'action asynchrone
export const fetchUserSignalements = createAsyncThunk(
    'animals/fetchUserSignalements',
    async ({ uid }) => {
        const db = getFirestore();
        const animalsRef = collection(db, "animals");
        const querySnapshot = await getDocs(query(animalsRef, where("userCreatorId", "==", uid), where("signal", "==", true)));


        let animals = [];
        querySnapshot.forEach((doc) => {
            animals.push({ id: doc.id, ...doc.data() });
        });

        return animals;
    }
);

export const updateUserEmail = createAsyncThunk(
    'user/updateUserEmail',
    async ({ userId, newEmail }, thunkAPI) => {
        try {
            const auth = getAuth();
            const user = auth.currentUser;
            if (!user) {
                throw new Error('No user logged in');
            }

            // Prompt l'utilisateur pour la réauthentification
            const reauthenticated = await promptUserForReauthentication(user);
            if (!reauthenticated) {
                throw new Error('REAUTHENTICATION_CANCELLED');
            }

            // Une fois réauthentifié, mise à jour de l'email
            await updateEmailAuth(user, newEmail);

            // Mise à jour de l'email dans Firestore
            const db = getFirestore();
            const userRef = doc(db, 'users', userId);
            await updateDoc(userRef, { email: newEmail });

            // Déconnexion de l'utilisateur après la mise à jour
            await signOut(auth);
            return { newEmail };
        } catch (error) {
            return thunkAPI.rejectWithValue({
                code: error.code || 'UNKNOWN_ERROR',
                message: error.message || 'An unknown error occurred.'
            });
        }
    }
);

export const updatePassword = createAsyncThunk(
    'user/updatePassword',
    async ({ newPassword }, { getState, rejectWithValue }) => {
        try {
            const auth = getAuth();
            const user = auth.currentUser;
            if (!user) {
                throw new Error('No user logged in');
            }

            // Réauthentifier l'utilisateur
            const reauthenticated = await promptUserForReauthentication(user);
            if (!reauthenticated) {
                throw new Error('REAUTHENTICATION_CANCELLED');
            }

            // Modifier le mot de passe une fois réauthentifié
            await updatePasswordAuth(user, newPassword);
            return { newPassword }; // Retourner les informations en cas de succès
        } catch (error) {
            return rejectWithValue({
                code: error.code || 'UNKNOWN_ERROR',
                message: error.message || 'An unknown error occurred.'
            });
        }
    }
);

export const logout = () => async (dispatch) => {
    await persistor.purge(); // Purge all stored data
    dispatch({ type: 'RESET_STORE' }); // Reset the state
};


export const deleteUser = createAsyncThunk(
    'user/deleteUser',
    async (_, { getState, rejectWithValue }) => {
        try {
            const state = getState();
            const { uid } = state.auth;
            const auth = getAuth();
            const user = auth.currentUser;

            // Réauthentifier l'utilisateur
            const reauthenticated = await promptUserForReauthentication(user);
            if (!reauthenticated) {
                throw new Error('REAUTHENTICATION_CANCELLED');
            }

            const db = getFirestore();

            // Supprimer les entrées dans userCanals correspondant à l'utilisateur
            const userCanalsRef = collection(db, 'userCanals');
            const qUserCanals = query(userCanalsRef, where('userId', '==', uid));
            const querySnapshotCanals = await getDocs(qUserCanals);
            for (const doc of querySnapshotCanals.docs) {
                await deleteDoc(doc.ref);
            }

            // Supprimer les informations de facturation dans billingInfo
            const billingInfoDocRef = doc(db, 'billingInfo', uid);
            await deleteDoc(billingInfoDocRef);

            // Supprimer l'utilisateur de Firebase Authentication
            await user.delete(); // Cette méthode supprime l'utilisateur authentifié

            // Supprimer l'utilisateur de la collection users
            const userRef = doc(db, 'users', uid);
            await deleteDoc(userRef);

            // Retourner une indication de succès
            return { uid };
        } catch (error) {
            return rejectWithValue({
                code: error.code || 'UNKNOWN_ERROR',
                message: error.message || 'An unknown error occurred.'
            });
        }
    }
);


export const fetchUserData = createAsyncThunk(
    'auth/fetchUserData',
    async (userId, { dispatch }) => {
      const db = getFirestore();
      const userRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userRef);
  
      if (userDoc.exists()) {
        const userData = userDoc.data();
        // console.log('userData -->', userData)
        return userData;
      } else {
        throw new Error('User data not found');
      }
    }
  );
  

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isAuthenticated: false,
        error: null,
        name: null,
        surname: null,
        uid: null,
        email: null,
        userHasLicenceNumber: null,
        registrationDate: null,
        isAdmin: null,
        requestAdmin: null,
        userSignalementsAnimals: [],
        productType: null,
        isLinkedToAnAssociation: false,  // Ajoutez les champs nécessaires ici
        linkedAssociationIds: [],
        notificationsAdminPreferences: {},
        subscriptionId: null, // Ajoutez cette ligne
    },
    reducers: {
        resetError: (state) => {
            state.error = null;
        },
        setSubscriptionId: (state, action) => {
            state.subscriptionId = action.payload;
        },
        setRegistrationDate: (state, action) => {
            state.registrationDate = action.payload;
        },
        setUser: (state, action) => {
            // Mettre à jour les champs nécessaires ici
            state.isLinkedToAnAssociation = action.payload.isLinkedToAnAssociation;
            state.linkedAssociationIds = action.payload.linkedAssociationIds;
            state.subscriptionId = action.payload;
            // Ajoutez d'autres champs si nécessaire
        },
        setStripeCustomerId: (state, action) => {
            state.stripeCustomerId = action.payload;
        },
        setAdminRequest: (state, action) => {
            state.requestAdmin = action.payload;
        },
        setSiretUser: (state, action) => {
            state.siret = action.payload;
        },
        setSiretAssoUser: (state, action) => {
            state.siretAsso = action.payload;
        },
        setRnaAsso: (state, action) => {
            state.rnaAsso = action.payload;
        },
        setNewsletterConsent: (state, action) => {
            state.newsletterConsent = action.payload;
        },
        setEmailVerified: (state, action) => {
            state.emailWasVerified = action.payload;
        },
        setUserType: (state, action) => {
            state.userType = action.payload;
        },
        setNotificationsAdminPreferences: (state, action) => {
            state.notificationsAdminPreferences = action.payload;
        },

        loginSuccess: (state, action) => {

            state.isAuthenticated = true;
            state.error = null;
            state.uid = action.payload.uid;
            state.email = action.payload.email;
            state.requestAdmin = action.payload.requestAdmin

        },
        loginFailure: (state, action) => {
            state.isAuthenticated = false;
            state.error = action.payload;
        },
        setName: (state, action) => {
            state.name = action.payload;
        },
        setSurname: (state, action) => {
            state.surname = action.payload;
        },
        setLicence: (state, action) => {
            state.licenceNumber = action.payload;

        },
        setCurrentProductType: (state, action) => {
            state.productType = action.payload;

        },
        setLicenceStatus: (state, action) => {
            state.licenceStatus = action.payload;

        },
        setIsMairie: (state, action) => {
            state.isMairie = action.payload;
        },
        setIsBeta: (state, action) => {
            state.isBeta = action.payload;
        },
        setIsAssociation: (state, action) => {
            state.isAssociation = action.payload;
        },
        setIsLinkedToAnAssociation: (state, action) => {
            state.isLinkedToAnAssociation = action.payload;
        },
        setLinkedAssociationsIds: (state, action) => {
            state.linkedAssociationIds = action.payload;
        },
        setAssociationId: (state, action) => {
            state.associationId = action.payload;
        },
        setMairieName: (state, action) => {
            state.mairieName = action.payload;
        },
        setAssociationName: (state, action) => {
            state.associationName = action.payload;
        },
        setNotificationsEnabled: (state, action) => {
            state.notificationsEnabled = action.payload;
        },
        setProductType: (state, action) => {
            state.productType = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loginUser.fulfilled, (state, action) => {

            state.uid = action.payload.uid;
            state.email = action.payload.email;
            state.isAuthenticated = true;
            state.error = null;
            state.userIsMairie = action.payload.isMairie;
            state.userIsAssociation = action.payload.isAssociation;
            state.userassociationName = action.payload.associationName;
            state.userMairieName = action.payload.mairieName;
            state.userHasLicenceNumber = action.payload.licenceNumber;
            state.userHasCurrentProductType = action.payload.productType;
            state.status = 'succeeded';
            // state.requestAdmin = action.payload.requestAdmin;
        });
        builder.addCase(loginUser.pending, (state) => {
            state.status = 'loading';
            state.error = null;
        });
        builder.addCase(loginUser.rejected, (state, action) => {
            state.status = 'failed';
            state.isAuthenticated = false;
            if (action.payload) {
                const errorMessage = action.payload.toString() || '';
                if (typeof errorMessage === 'string') {
                    const errorCode = errorMessage.split('Error (')[1]?.split(').')[0];
                    state.error = errorCode;
                } else {
                    state.error = "Unexpected error format.";
                }
            } else {
                state.error = action.error.message;
            }

        });
        builder.addCase(fetchUserData.fulfilled, (state, action) => {
            state.licenceStatus = action.payload.licenceStatus; // Mettre à jour le statut de la licence
            state.subscriptionId = action.payload.subscriptionId; // Mettez également à jour le subscriptionId si nécessaire
          });


        builder.addCase(resetPassword.fulfilled, (state) => {
            state.status = 'fulfilled';
            state.error = null;
        });
        builder.addCase(resetPassword.pending, (state) => {
            state.status = 'loading';
            state.error = null;
        });
        builder.addCase(resetPassword.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.code;
        });
        builder.addCase(checkEmailExists.rejected, (state, action) => {
            state.status = 'failed';
            if (action.payload) {
                const errorCode = action.payload.code;
                state.error = errorCode;
            }

        });
        builder.addCase(resetStatus, (state) => {
            state.status = null;
            state.error = null;
        });
        builder.addCase(updateEmail.fulfilled, (state, action) => {
            state.email = action.payload;
        });
        builder.addCase(updatePassword.fulfilled, (state, action) => {
            state.password = action.payload;
        });
        builder.addCase(registerUser.fulfilled, (state, action) => {
            state.uid = action.payload.uid;
            state.email = action.payload.email;
            state.error = null;
        });
        builder.addCase(registerUser.rejected, (state, action) => {
            state.status = 'failed';
            state.isAuthenticated = false;
            // console.log('action payload ->', action.payload)
            if (action.payload) {
                const errorMessage = action.payload.toString() || '';
                if (typeof errorMessage === 'string') {
                    const errorCode = errorMessage.split('Error (')[1]?.split(').')[0];
                    state.error = errorCode;
                } else {
                    state.error = "Unexpected error format.";
                }
            } else {
                state.error = action.error.message;
            }

        });
        builder.addCase(updateUserName.fulfilled, (state, action) => {
            // Mettre à jour l'état avec le nouveau nom
            state.name = action.payload.newName;
        });
        builder.addCase(updateUserName.rejected, (state, action) => {
            // Mettre à jour l'état avec le nouveau nom
            state.status = 'failed';
            state.error = action.error ? action.error.message : "Une erreur s'est produite lors de la mise à jour du nom.";

        });
        builder.addCase(updateUserSurname.fulfilled, (state, action) => {
            // Mettre à jour l'état avec le nouveau prénom
            state.surname = action.payload.newSurname;
        });
        builder.addCase(deleteUser.fulfilled, (state, action) => {
            // Effectuez les actions nécessaires après la suppression de l'utilisateur, si nécessaire
        });

        builder.addCase(deleteUser.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;

            // Gérez les erreurs lors de la suppression de l'utilisateur, si nécessaire
        });
        builder.addCase(loginWithGoogle.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(loginWithGoogle.fulfilled, (state, action) => {
            state.uid = action.payload.uid;
            state.email = action.payload.email;
            state.isAuthenticated = true;
            state.error = null;
        })
        builder.addCase(loginWithGoogle.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
        });
        builder.addCase(updateAdminRequest.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(updateAdminRequest.fulfilled, (state, action) => {
            state.requestAdmin = action.payload.requestAdmin;
            state.error = null;
        })
        builder.addCase(updateAdminRequest.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
        });
        builder.addCase(updateSignalsTotal.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(updateSignalsTotal.fulfilled, (state, action) => {
            state.signalsTotal = action.payload.requestAdmin;
            state.error = null;
        })
        builder.addCase(updateSignalsTotal.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
        });
        builder
            .addCase(fetchUserSignalements.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchUserSignalements.fulfilled, (state, action) => {
                state.isLoading = false;
                state.userSignalementsAnimals = action.payload;
            })
            .addCase(fetchUserSignalements.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message;
            });
        builder.addCase(addLicence.fulfilled, (state, action) => {
            state.userHasLicenceNumber = true;
            setLicence(action.licenceId)
            state.licenceNumber = action.payload.licenceNumber;
            setCurrentProductType(action.productType);
            state.productType = action.payload.productType;
        });

        builder.addCase(updateAdminNotificationPreference.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
        });
        builder.addCase(updateAdminNotificationPreference.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(updateAdminNotificationPreference.fulfilled, (state, action) => {
            const { userId, canalId, newValue } = action.payload;
            if (!state.notificationsAdminPreferences) {
                state.notificationsAdminPreferences = {};
            }
            if (!state.notificationsAdminPreferences[userId]) {
                state.notificationsAdminPreferences[userId] = {};
            }
            state.notificationsAdminPreferences[userId][canalId] = newValue;
            state.error = null;
            state.loading = false;
        })
    },
});

export const { resetError, setUser, loginSuccess, loginFailure, setName, setSurname, setLicence, setCurrentProductType, setLicenceStatus, setIsMairie, setIsAssociation, setIsLinkedToAnAssociation, setLinkedAssociationsIds, setAssociationId, setAssociationName, setMairieName, setRegistrationDate, setStripeCustomerId, setNotificationsEnabled, setProductType, setAdminRequest, setNotificationsAdminPreferences, setSiretUser, setSiretAssoUser, setRnaAsso, setEmailVerified, setNewsletterConsent, setUserType, setSubscriptionId, setIsBeta } = authSlice.actions;

export default authSlice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getFirestore, collection, where, query, getDocs, addDoc, updateDoc, deleteDoc, getDoc, doc, Timestamp } from 'firebase/firestore';
import { deleteImageFromStorage, uploadImage } from "../animals/animalSlice";

/*
* Fetch cities
***    
*/

export const fetchCitiesSector = createAsyncThunk('citiesSector/fetchCitiesSector', async (canalId) => {
    const db = getFirestore();
    const q = query(collection(db, 'citiesSector'), where('canalId', '==', canalId));
    const querySnapshot = await getDocs(q);
    const citiesData = [];
    querySnapshot.forEach((doc) => {
        citiesData.push({ id: doc.id, ...doc.data() });
    });
    return citiesData;
});

/*
* Create citySector
***    
*/

export const addCitySector = createAsyncThunk(
    'citiesSector/addCitySector',
    async (canalData, { rejectWithValue }) => {

        try {
            const db = getFirestore();
            let addedDate = Timestamp.now().toDate();
            let formattedDate = addedDate.toISOString().split('T')[0]; // Format date as "YYYY-MM-DD"
            const newSectorData = { ...canalData, addedDate: formattedDate }; // Add formatted date to newSectorData
            const canalRef = collection(db, 'citiesSector');
            const newCanalRef = await addDoc(canalRef, newSectorData);
            const newcitiesnapshot = await getDoc(newCanalRef);
            const newCanal = { id: newCanalRef.id, ...newcitiesnapshot.data() };
            return newCanal;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

/*
* Update citySector
***    
*/

export const updateCitySector = createAsyncThunk(
    'citiesSector/updateCitySector',
    async (citySectorData, { rejectWithValue }) => {
        try {
            const db = getFirestore();
            const citySectorRef = doc(db, 'citiesSector', citySectorData.id);
            await updateDoc(citySectorRef, citySectorData);
            const updatedCitySectorSnapshot = await getDoc(citySectorRef);
            const updatedCitySector = { id: updatedCitySectorSnapshot.id, ...updatedCitySectorSnapshot.data() };
            return updatedCitySector;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);


/*
* Delete citySector
***    
*/

// export const deleteCitySector = createAsyncThunk(
//     'citiesSector/deleteSector',
//     async (citySectorId, { rejectWithValue }) => {
//         try {
//             const db = getFirestore();
//             const citySectorRef = doc(db, 'citiesSector', citySectorId);
//             const citySectorSnapshot = await getDoc(citySectorRef);
//             const citySectorData = { id: citySectorSnapshot.id, ...citySectorSnapshot.data() };
//             await deleteDoc(citySectorRef);
//             // Si le secteur a déjà une image, la supprimer de Firebase Storage
//             if (citySectorRef && citySectorRef.imageCover && citySectorRef.imageCover.name) {
//                 deleteImageFromStorage(citySectorRef.imageCover.name);
//             }

//             return citySectorData;
//         } catch (error) {
//             return rejectWithValue(error.message);
//         }
//     }
// );

export const deleteCitySector = createAsyncThunk(
    'citiesSector/deleteSector',
    async (citySectorId, { rejectWithValue }) => {
        try {
            const db = getFirestore();

            // Étape 1: Supprimer les subSectors associés et leurs images
            const subSectorsQuery = query(collection(db, 'subSectors'), where('citySectorId', '==', citySectorId));
            const subSectorsSnapshot = await getDocs(subSectorsQuery);
            const subSectorDeletionPromises = subSectorsSnapshot.docs.map(async doc => {
                const subSectorData = doc.data();
                // Supprimer l'image du sous-secteur de Firebase Storage si elle existe
                if (subSectorData.imageCover && subSectorData.imageCover.name) {
                    await deleteImageFromStorage(subSectorData.imageCover.name);
                }
                // Supprimer le document du sous-secteur
                return deleteDoc(doc.ref);
            });
            await Promise.all(subSectorDeletionPromises);

            // Étape 2: Supprimer le citySector et son image si elle existe
            const citySectorRef = doc(db, 'citiesSector', citySectorId);
            const citySectorSnapshot = await getDoc(citySectorRef);
            const citySectorData = { id: citySectorSnapshot.id, ...citySectorSnapshot.data() };
            if (citySectorData.imageCover && citySectorData.imageCover.name) {
                await deleteImageFromStorage(citySectorData.imageCover.name);
            }
            await deleteDoc(citySectorRef);

            return citySectorData;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

/*
* Modify canal cover Image
***    
*/


export const updateSectorCoverImage = createAsyncThunk(
    "animals/updateSectorCoverImage",
    async ({ citySectorId, image }, { rejectWithValue, dispatch }) => {
        try {
            const db = getFirestore();
            const sectorRef = doc(db, "citiesSector", citySectorId);

            // Récupérer l'image actuelle de l'animal
            const sectorDoc = await getDoc(sectorRef);
            const sectorData = sectorDoc.data();

            // Si le canal a déjà une image, la supprimer de Firebase Storage
            if (sectorData && sectorData.imageCover && sectorData.imageCover.name) {
                dispatch(deleteImageFromStorage(sectorData.imageCover.name));
            }

            // Uploader la nouvelle image
            const newImageUrl = await uploadImage(image);
            // Mettre à jour la référence de l'image dans Firestore
            await updateDoc(sectorRef, { imageCover: newImageUrl });

            return { citySectorId, imageCoverUrl: newImageUrl };
        } catch (error) {
            // console.log('error ici', error)
            return rejectWithValue(error.message);
        }
    }
);


/*
* * * CitySector slice
***    
*/

const citiesSlice = createSlice({
    name: 'citiesSector',
    initialState: {
        status: 'idle',
        data: [],
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCitiesSector.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCitiesSector.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload;
            })
            .addCase(fetchCitiesSector.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(addCitySector.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(addCitySector.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data.push(action.payload);

            })
            .addCase(addCitySector.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(updateCitySector.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateCitySector.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const updatedCitySectorIndex = state.data.findIndex((citySector) => citySector.id === action.payload.id);
                if (updatedCitySectorIndex >= 0) {
                    state.data[updatedCitySectorIndex] = action.payload;
                }
            })
            .addCase(updateCitySector.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
        builder.addCase(updateSectorCoverImage.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(updateSectorCoverImage.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const updatedCitySectorIndex = state.data.findIndex((citySector) => citySector.id === action.payload.citySectorId);

                if (updatedCitySectorIndex >= 0) {
                    const { citySectorId, imageCoverUrl } = action.payload;
                    const sector = state.data.find((sector) => sector.id === citySectorId);
                    if (!sector.image) {
                        sector.image = {};
                    }
                    sector.image.url = imageCoverUrl;
                    state.coverUrl = sector.image.url.url;
                }
            })
            .addCase(updateSectorCoverImage.rejected, (state, action) => {
                state.status = 'failed';
                // console.log('action payload -> ', action.payload)
                state.error = action.payload;
            });

    },
});

export default citiesSlice.reducer;





import React from 'react';
import IconMaleOutline from '../../assets/male-outline-black.svg';
import IconFemaleOutline from '../../assets/female-outline-black.svg';
import { useTranslation } from 'react-i18next';

const TotalSterilizationGraph = ({ animals, year }) => {
    const { t } = useTranslation();
    const previousYear = (parseInt(year) - 1).toString();

    const countSterilizations = () => {
        return animals.filter(animal =>
            animal?.sterilizationDate?.startsWith(year) &&
            animal?.trappedDate?.startsWith(year) &&
            animal?.isSterilise === true
        ).length;
    };

    const countSterilizationsFemale = () => {
        return animals.filter(animal =>
            animal?.sterilizationDate?.startsWith(year) &&
            animal?.trappedDate?.startsWith(year) &&
            animal?.isSterilise === true &&
            animal?.sex === 'Femelle'
        ).length;
    };

    const countSterilizationsMale = () => {
        return animals.filter(animal =>
            animal?.sterilizationDate?.startsWith(year) &&
            animal?.trappedDate?.startsWith(year) &&
            animal?.isSterilise === true &&
            animal?.sex === 'Mâle'
        ).length;
    };


    const countSterilizationsLastYear = () => {
        return animals.filter(animal =>
            animal?.sterilizationDate?.startsWith(previousYear) &&
            animal?.trappedDate?.startsWith(previousYear) &&
            animal?.isSterilise === true
        ).length;
    };

    const countSterilizationsFemaleLastYear = () => {
        return animals.filter(animal =>
            animal?.sterilizationDate?.startsWith(previousYear) &&
            animal?.trappedDate?.startsWith(previousYear) &&
            animal?.isSterilise === true &&
            animal?.sex === 'Femelle'
        ).length;
    };

    const countSterilizationsMaleLastYear = () => {
        return animals.filter(animal =>
            animal?.sterilizationDate?.startsWith(previousYear) &&
            animal?.trappedDate?.startsWith(previousYear) &&
            animal?.isSterilise === true &&
            animal?.sex === 'Mâle'
        ).length;
    };




    const calculatePercentageDifference = (current, previous) => {
        if (previous === 0) return 'N/A'; // Pour éviter la division par zéro
        let difference = (((current - previous) / previous) * 100).toFixed(2);
        let sign = difference >= 0 ? '+' : ''; // Ajoute un '+' si la différence est positive ou zéro
        return `${sign}${difference}%`;
    };


    const totalSterilisations = countSterilizations();
    const totalSterilisationsFemale = countSterilizationsFemale();
    const totalSterilisationsMale = countSterilizationsMale();

    const totalSterilisationsLastYear = countSterilizationsLastYear();
    const totalSterilisationsFemaleLastYear = countSterilizationsFemaleLastYear();
    const totalSterilisationsMaleLastYear = countSterilizationsMaleLastYear();

    const percentageTotal = calculatePercentageDifference(totalSterilisations, totalSterilisationsLastYear);
    const percentageFemale = calculatePercentageDifference(totalSterilisationsFemale, totalSterilisationsFemaleLastYear);
    const percentageMale = calculatePercentageDifference(totalSterilisationsMale, totalSterilisationsMaleLastYear);

    return (
        <div className="signalements-graph">
            {/* Bloc Total Stérilisations */}
            <p style={{ color: 'black', marginLeft: '1rem', fontWeight: 'bolder', textAlign: 'left' }}><img src={IconFemaleOutline} style={{width:'27px'}}/>  <img src={IconMaleOutline} style={{width:'27px'}}/></p>

            <div style={{ display: 'flex', flexDirection: 'row', columnGap: '1rem' }}>

                <div style={{ textAlign: 'center' }}>
                    <div className="signalements-count">
                        {totalSterilisations}
                    </div>
                    <div className="signalements-label">
                        {t('numberOfSterilizations')} {t('in')} {year}
                    </div>
                </div>

                <div style={{ textAlign: 'center' }}>
                    <div className="signalements-count">
                        {percentageTotal}

                    </div>
                    <div className="signalements-label">
                        ({totalSterilisationsLastYear} {t('sterilizations')} {t('in')} {previousYear})
                    </div>
                </div>
            </div>



            {/* Bloc Femelles Stérilisées */}
            <p style={{ color: 'black', marginLeft: '1rem', fontWeight: 'bolder', textAlign: 'left' }}><img src={IconFemaleOutline} style={{width:'27px'}}/></p>
            <div style={{ display: 'flex', flexDirection: 'row', columnGap: '1rem' }}>

                <div style={{ textAlign: 'center' }}>
                    <div className="signalements-count">
                        {totalSterilisationsFemale}
                    </div>
                    <div className="signalements-label">
                    {t('graph.sterilizations.numberOfFemalesSterilized')} {t('in')} {year}
                    </div>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <div className="signalements-count">

                        {percentageFemale}
                    </div>
                    <div className="signalements-label">
                        {totalSterilisationsFemaleLastYear} {t('graph.sterilizations.sterilizedFemales')} {t('in')} {previousYear}
                    </div>
                </div>
            </div>


            {/* Bloc Mâles Stérilisés */}
            <p style={{ color: 'black', marginLeft: '1rem', fontWeight: 'bolder', textAlign: 'left' }}><img src={IconMaleOutline} style={{width:'27px'}}/></p>

            <div style={{ display: 'flex', flexDirection: 'row', columnGap: '1rem' }}>
                <div style={{ textAlign: 'center' }}>
                    <div className="signalements-count">
                        {totalSterilisationsMale}
                    </div>
                    <div className="signalements-label">
                    {t('graph.sterilizations.numberOfMalesSterilized')} en {year}
                    </div>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <div className="signalements-count">
                        {percentageMale}
                    </div>
                    <div className="signalements-label">
                        {totalSterilisationsMaleLastYear} {t('graph.sterilizations.sterilizedMales')} {t('in')} {previousYear}
                    </div>
                </div>
            </div>

        </div>
    );
};

export default TotalSterilizationGraph;

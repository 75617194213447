import React, { useEffect, useState, useRef } from 'react';
import { MapContainer, TileLayer, Polygon, Marker, Tooltip, useMap, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import markerIcon from '../../assets/logos/location-pin-kappze.svg';
import placeholder from '../../assets/logocat2.svg'
import { NavLink } from 'react-router-dom';

const fetchCommuneData = async (code) => {
    const response = await fetch(`https://geo.api.gouv.fr/communes/${code}?fields=nom,code,codeParent,codesPostaux,siren,centre,contour,bbox,codeEpci,codeDepartement,codeRegion,population&format=json&geometry=centre`);
    const data = await response.json();
    return data;
};

const geocodeReverse = async (lat, lng) => {
    const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`);
    const data = await response.json();
    return data?.address?.road || 'Route inconnue';
};

const calculateCenter = (coordinates) => {
    let latSum = 0;
    let lngSum = 0;
    const len = coordinates.length;

    coordinates.forEach(coord => {
        latSum += coord[1];
        lngSum += coord[0];
    });

    return {
        lat: latSum / len,
        lng: lngSum / len
    };
};

const calculateBounds = (coordinates) => {
    let minLat = Infinity, maxLat = -Infinity, minLng = Infinity, maxLng = -Infinity;

    coordinates.forEach(coord => {
        minLat = Math.min(minLat, coord[1]);
        maxLat = Math.max(maxLat, coord[1]);
        minLng = Math.min(minLng, coord[0]);
        maxLng = Math.max(maxLng, coord[0]);
    });

    return [
        [minLat, minLng],
        [maxLat, maxLng]
    ];
};

const MultipleMarkersMap = ({ communeCode, animals }) => {
    const [center, setCenter] = useState({ lat: 48.8566, lng: 2.3522 });
    const [bounds, setBounds] = useState(null);
    const [regionCoordinates, setRegionCoordinates] = useState([]);
    const [locationInfos, setLocationInfos] = useState({});

    

    const customIcon = new L.Icon({
        iconUrl: markerIcon,
        iconSize: [32, 32], // Taille de l'icône
        iconAnchor: [16, 32], // Point de l'icône qui correspond à la position du marqueur
        popupAnchor: [0, -32] // Point où la popup apparaît par rapport à l'icône
    });

    useEffect(() => {
        const fetchLocationInfos = async () => {
            const infos = {};
            for (const animal of animals) {
                if (animal.location && animal.location.lat && animal.location.lng) {
                    const address = await geocodeReverse(animal.location.lat, animal.location.lng);
                    infos[animal.id] = address;
                }
            }
            setLocationInfos(infos);
        };

        fetchLocationInfos();
    }, [animals]);

    // useEffect(() => {
    //     if (marker) {
    //         geocodeReverse(marker.lat, marker.lng).then(address => {
    //             setLocationInfo(`Rue : ${address}`);
    //             if (markerRef.current) {
    //                 markerRef.current.openPopup();
    //             }
    //         });
    //     }
    // }, [marker]);


    const mapBounds = [
        { lat: 85, lng: -180 },
        { lat: 85, lng: 180 },
        { lat: -85, lng: 180 },
        { lat: -85, lng: -180 },
        { lat: 85, lng: -180 } // Ferme le polygone
    ];

    const UpdateMapView = () => {
        const map = useMap();
        useEffect(() => {
            if (bounds) {
                map.fitBounds(bounds);
                map.setMaxBounds(bounds);
                map.setMinZoom(map.getZoom());
            } else {
                map.setView(center, 13);
            }
        }, [map, center, bounds]);
        return null;
    };

    useEffect(() => {
        if (communeCode) {
            fetchCommuneData(communeCode).then(data => {
                const contour = data.contour;
    
                if (contour.type === "Polygon") {
                    // Si c'est un seul polygone, on formate les coordonnées pour Leaflet
                    const coordinates = contour.coordinates[0];
                    const formattedCoordinates = coordinates.map(coord => ({
                        lat: coord[1],
                        lng: coord[0]
                    }));
                    const newCenter = calculateCenter(coordinates);
                    const newBounds = calculateBounds(coordinates);
    
                    setCenter(newCenter);
                    setBounds(newBounds);
                    setRegionCoordinates([formattedCoordinates]); // Nous utilisons un tableau pour être cohérent
                } else if (contour.type === "MultiPolygon") {
                    // Si c'est un MultiPolygon, on formate chaque polygone individuellement
                    const allPolygons = contour.coordinates.map(polygon => {
                        return polygon[0].map(coord => ({
                            lat: coord[1],
                            lng: coord[0]
                        }));
                    });
    
                    // Calculer un centre et des limites pour l'ensemble des polygones
                    const allCoordinates = contour.coordinates.flat(2);
                    const newCenter = calculateCenter(allCoordinates);
                    const newBounds = calculateBounds(allCoordinates);
    
                    setCenter(newCenter);
                    setBounds(newBounds);
                    setRegionCoordinates(allPolygons); // On stocke chaque polygone séparément
                }
            });
        }
    }, [communeCode]);




    // return (
    //     <div className="map-container">
    //         <MapContainer
    //             center={center}
    //             zoom={13}
    //             maxBounds={bounds}
    //             maxBoundsViscosity={1.0}
    //         >
    //             <TileLayer
    //                 url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    //                 attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    //             />
    //             <Polygon
    //                 positions={[mapBounds, regionCoordinates]}
    //                 pathOptions={{ fillColor: 'black', fillOpacity: 0.7, strokeWeight: 0 }}
    //             />
    //             <Polygon
    //                 positions={regionCoordinates}
    //                 pathOptions={{ weight: 2, color: '#2f4f4f', fillColor: 'black' }}
    //             />
    //             {animals.map(animal => (
    //                 animal?.location && animal?.location.lat && animal?.location.lng && (
    //                     <Marker
    //                         key={animal?.id}
    //                         position={[animal?.location?.lat, animal?.location?.lng]}
    //                         icon={customIcon}
    //                     >
    //                         <Popup style={{ backgroundColor: '#2f2f2f' }} autoPan={false}>
    //                             {animal?.image?.url ? (<img src={animal?.image?.url} alt="Animal" />) : ((<img src={placeholder} alt="Animal" />))}
    //                             <p>{animal?.name}</p>
    //                             <NavLink style={{fontWeight: 600, color: '#fff'}}to={`/animal/${animal?.id}`}>
    //                             Voir le profil
    //                             </NavLink>
    //                             <p>{locationInfos[animal?.id] || 'Chargement de l\'adresse ...'}</p>
    //                         </Popup>
    //                     </Marker>
    //                 )
    //             ))}
    //             <UpdateMapView />
    //         </MapContainer>
    //     </div>
    // );
    const createMask = (polygons) => {
        return [mapBounds, ...polygons]; // Ajoute le grand polygone et laisse des "trous" pour les autres polygones
    };
    return (
        <div className="map-container">
            <MapContainer
                center={center}
                zoom={13}
                maxBounds={bounds}
                maxBoundsViscosity={1.0}
            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                
                {/* Ajoute un polygone noir couvrant toute la carte, avec des trous pour les polygones */}
                <Polygon
                    positions={createMask(regionCoordinates)}
                    pathOptions={{ fillColor: 'black', fillOpacity: 0.7, stroke: false }}
                />
    
                {/* Affiche les frontières des polygones */}
                {regionCoordinates.map((polygon, index) => (
                    <Polygon
                        key={index}
                        positions={polygon}
                        pathOptions={{ weight: 2, color: '#2f4f4f', fillColor: 'transparent' }}
                    />
                ))}
    
                {animals.map(animal => (
                    animal?.location && animal?.location.lat && animal?.location.lng && (
                        <Marker
                            key={animal?.id}
                            position={[animal?.location?.lat, animal?.location?.lng]}
                            icon={customIcon}
                        >
                            <Popup style={{ backgroundColor: '#2f2f2f' }} autoPan={false}>
                                {animal?.image?.url ? (<img src={animal?.image?.url} alt="Animal" />) : (<img src={placeholder} alt="Animal" />)}
                                <p>{animal?.name}</p>
                                <NavLink style={{ fontWeight: 600, color: '#fff' }} to={`/animal/${animal?.id}`}>
                                    Voir le profil
                                </NavLink>
                                <p>{locationInfos[animal?.id] || 'Chargement de l\'adresse ...'}</p>
                            </Popup>
                        </Marker>
                    )
                ))}
                <UpdateMapView />
            </MapContainer>
        </div>
    );
};

export default MultipleMarkersMap;

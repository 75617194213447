import React, { useState } from 'react';

const ContactModal = ({ isOpen, onClose, sendEmail }) => {
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState('');
  

  if (!isOpen) return null;

  return (
    <div className="modal-background-signal">
      <div className="modal-container-signal">
        
        <h2 style={{color: '#2f4f4f'}}>Contacter le propriétaire</h2>

        <p style={{color: '#000'}}>Un email lui sera envoyé contenant votre message et votre email enregistré sur la plateforme.</p>
        <textarea
          placeholder="Ajoutez votre message"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        <button onClick={() => {
          sendEmail(description);
          onClose();
        }}>
          Envoyer
        </button>
        <button className="button-supp" onClick={onClose}>Fermer</button>
        </div>
      </div>
    </div>
  );
};

export default ContactModal;

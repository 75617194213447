import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ error, errorInfo });
        if (this.props.isProduction) {
            this.reportError(error, errorInfo);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            // Réinitialiser l'état lorsqu'il y a un changement de route
            this.setState({ hasError: false, error: null, errorInfo: null });
        }
    }

    reportError = (error, errorInfo) => {
        const payload = {
            error: error.toString(),
            errorInfo: errorInfo ? errorInfo.componentStack : 'No error info',
            url: window.location.href,
            userAgent: navigator.userAgent,
        };

        axios.post('https://us-central1-kappze.cloudfunctions.net/reportError', payload)
            .then(response => {
                // console.log('Response from server:', response.data);
            })
            .catch(error => {
                console.error('Error sending request:', error);
            });
    };

    render() {
        if (this.state.hasError) {
            return (
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginTop: "2rem",
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    color: '#fff',
                    padding: '2rem',
                    borderRadius: '8px'
                }}>
                    <h1>Une erreur est survenue. Le support Kappze a été notifié.</h1>
                    <Link to="/" style={{ color: '#ffcc00', textDecoration: 'underline', marginTop: '1rem' }}>
                        Retourner à la page d'accueil
                    </Link>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;

// import React, { useState } from 'react';
// import Swal from 'sweetalert2';
// import { sendQuoteRequest } from '../../features/notifications/sendQuoteRequest';
// import catIcon from '../../assets/icon-paw-black.svg';

// const AnimalSliderForm = () => {
//     const basePrice = 604.98; // Prix initial pour 500 animaux
//     const incrementPercentage = 0.21; // +21% pour chaque tranche de 500 animaux
//     const maxAnimals = 12000;

//     const [animalCount, setAnimalCount] = useState(1000); // Commence à 1000 animaux
//     const [price, setPrice] = useState(Math.round(basePrice)); // Arrondi initial du prix de base
//     const [formData, setFormData] = useState({
//         name: '',
//         email: '',
//         phone: '',
//         company: ''
//     });
//     const [isSubmitting, setIsSubmitting] = useState(false); // Gérer l'état du bouton pour éviter les envois multiples
//     const [quoteSubmitted, setQuoteSubmitted] = useState(false); // État pour gérer l'affichage du message de confirmation

//     // const calculatePrice = (count) => {
//     //     const baseAnimals = 1000;
//     //     if (count <= baseAnimals) {
//     //         return Math.round(basePrice);
//     //     }

//     //     const extraAnimals = count - baseAnimals;
//     //     const additionalPaliers = Math.floor(extraAnimals / 500); // Nombre de paliers au-dessus de 1000 animaux
//     //     let newPrice = basePrice;

//     //     for (let i = 0; i < additionalPaliers; i++) {
//     //         newPrice += newPrice * incrementPercentage;
//     //     }

//     //     return Math.round(newPrice); // Arrondi final du prix calculé
//     // };

//     const calculatePrice = (count) => {
//         const baseAnimals = 1000;
//         let reducedPercentage = 0.1; // Réduction du pourcentage après 8000 animaux
//         let newPrice = basePrice;
    
//         if (count <= baseAnimals) {
//             return Math.round(basePrice);
//         }
    
//         const extraAnimals = count - baseAnimals;
//         const additionalPaliers = Math.floor(extraAnimals / 500); // Nombre de paliers au-dessus de 1000 animaux
    
//         for (let i = 0; i < additionalPaliers; i++) {
//             if (count > 8000) {
//                 newPrice += newPrice * reducedPercentage; // Pourcentage réduit
//             } else {
//                 newPrice += newPrice * incrementPercentage; // Pourcentage normal
//             }
//         }
    
//         return Math.round(newPrice); // Arrondi final du prix calculé
//     };

    
//     const handleAnimalCountChange = (e) => {
//         const count = parseInt(e.target.value);
//         setAnimalCount(count);

//         const newPrice = calculatePrice(count);
//         setPrice(newPrice);
//     };

//     const handleInputChange = (e) => {
//         const { name, value } = e.target;

//         // console.log(e.target)
//         setFormData(prevFormData => ({
//             ...prevFormData,
//             [name]: value
//         }));
//     };

//     const handleRequestQuote = () => {
//         const { name, email, phone, company, street, postalCode, city, country } = formData;

//         // console.log(formData)

//         // Validation des champs
//         if (!name || !email || !phone || !company || !street || !postalCode || !city || !country) {
//             Swal.fire({
//                 icon: 'error',
//                 title: 'Erreur',
//                 text: 'Veuillez remplir tous les champs du formulaire avant de soumettre.',
//                 confirmButtonColor: '#2F4F4F',
//             });
//         } else {
//             setIsSubmitting(true); // Désactiver le bouton
//             Swal.fire({
//                 icon: 'success',
//                 title: 'Devis en cours',
//                 text: 'Votre demande de devis a bien été reçue.',
//                 confirmButtonColor: '#2F4F4F',
//             }).then(() => {
//                 sendQuoteRequest(animalCount, price, formData);
//                 setIsSubmitting(false); // Réactiver le bouton après l'envoi
//                 setQuoteSubmitted(true); // Afficher le message de confirmation
//             });
//         }
//     };

//     // Si le devis a été envoyé, afficher le message de confirmation
//     if (quoteSubmitted) {
//         return (
//             <div style={{ textAlign: 'center', marginTop: '2rem' }}>
//                 <h2 style={{ color: '#2f4f4f' }}>Merci de votre contact !</h2>
//                 <p style={{ color: '#2f4f4f' }}>Nous reviendrons vers vous sous 24h.</p>
//             </div>
//         );
//     }

//     return (
//         <div>
//             <h2 style={{ color: '#2f4f4f', marginTop: '1.7rem', marginBottom: '1rem', textAlign: 'center' }}>Choisissez le nombre d'animaux</h2>

//             <input
//                 type="range"
//                 min="1000"
//                 max={maxAnimals}
//                 value={animalCount}
//                 onChange={handleAnimalCountChange}
//                 step="500"
//                 id="slider"
//             />
//             <div className="range-labels">
//                 <span>1000</span>
//                 <span>{maxAnimals}</span>
//             </div>

//             <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//                 <img src={catIcon} style={{ maxWidth: '50px' }} alt="Cat Icon" />
//                 <span style={{ color: '#2f4f4f', fontWeight: 'bolder' }}>Jusqu'à {animalCount}</span>
//             </div>
//             <p style={{ color: '#2f4f4f' }}>Prix estimé par an : <span style={{ textDecoration: 'underline' }}>{price} €</span></p>

//             <h3 style={{ color: '#2f4f4f', fontSize: '14px' }}>Informations pour le devis</h3>
//             <input
//                 type="text"
//                 name="name"
//                 placeholder="Nom & Prenom"
//                 value={formData.name}
//                 onChange={handleInputChange}
//                 required={true}
//             />
//             <input
//                 type="email"
//                 name="email"
//                 placeholder="Email"
//                 required={true}
//                 value={formData.email}
//                 onChange={handleInputChange}
//             />
//             <input
//                 type="text"
//                 name="phone"
//                 placeholder="Téléphone"
//                 value={formData.phone}
//                 onChange={handleInputChange}
//                 required={true}
//             />
//             <input
//                 type="text"
//                 name="company"
//                 placeholder="Entreprise/Organisation"
//                 value={formData.company}
//                 onChange={handleInputChange}
//                 required={true}
//             />

//             <input
//                 type="text"
//                 name="street"
//                 placeholder="Adresse"
//                 value={formData.street}
//                 onChange={handleInputChange}
//                 required={true}
//             />

//             <input
//                 type="text"
//                 name="postalCode"
//                 placeholder="Code Postal"
//                 value={formData.postalCode}
//                 onChange={handleInputChange}
//                 required={true}
//             />

//             <input
//                 type="text"
//                 name="city"
//                 placeholder="Ville"
//                 value={formData.city}
//                 onChange={handleInputChange}
//                 required={true}
//             />

//             <input
//                 type="text"
//                 name="country"
//                 placeholder="Pays"
//                 value={formData.country}
//                 onChange={handleInputChange}
//                 required={true}
//             />

//             <button
//                 style={{ margin: 0 }}
//                 onClick={handleRequestQuote}
//                 disabled={isSubmitting} // Désactiver le bouton pendant l'envoi
//             >
//                 {isSubmitting ? 'Envoi en cours...' : 'Demander un devis'}
//             </button>
//         </div>
//     );
// };

// export default AnimalSliderForm;
import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { sendQuoteRequest } from '../../features/notifications/sendQuoteRequest';
import catIcon from '../../assets/icon-paw-black.svg';

const AnimalSliderForm = () => {
    const basePrice = 604.98; // Prix initial pour 500 animaux
    const incrementPercentage = 0.21; // +21% pour chaque tranche de 500 animaux jusqu'à 8000
    const reducedPercentage = 0.04; // +4% pour chaque tranche au-delà de 8000 animaux
    const minimalPercentage = 0.02;
    const maxAnimals = 20000;

    const [animalCount, setAnimalCount] = useState(1000); // Commence à 1000 animaux
    const [price, setPrice] = useState(Math.round(basePrice)); // Arrondi initial du prix de base
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        company: '',
        street: '',
        postalCode: '',
        city: '',
        country: '',
        comment: ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false); // Gérer l'état du bouton pour éviter les envois multiples
    const [quoteSubmitted, setQuoteSubmitted] = useState(false); // État pour gérer l'affichage du message de confirmation

    const calculatePrice = (count) => {
        const baseAnimals = 1000;
        let newPrice = basePrice;

        if (count <= baseAnimals) {
            return Math.round(basePrice);
        }

        const extraAnimals = count - baseAnimals;
        const additionalPaliers = Math.floor(extraAnimals / 500); // Nombre de paliers au-dessus de 1000 animaux

        for (let i = 0; i < additionalPaliers; i++) {
            const currentAnimalCount = baseAnimals + (i + 1) * 500;
            
            if (currentAnimalCount <= 8000) {
                // Appliquer 21% jusqu'à 8000 animaux
                newPrice += newPrice * incrementPercentage;
            } else if (currentAnimalCount <= 11000) {
                // Appliquer 15% entre 8000 et 11000 animaux
                newPrice += newPrice * reducedPercentage;
            } else {
                // Appliquer 2% au-delà de 11000 animaux
                newPrice += newPrice * minimalPercentage;
            }
        }

        return Math.round(newPrice); // Arrondi final du prix calculé
    };

    const handleAnimalCountChange = (e) => {
        const count = parseInt(e.target.value);
        setAnimalCount(count);

        const newPrice = calculatePrice(count);
        setPrice(newPrice);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleRequestQuote = () => {
        const { name, email, phone, company, street, postalCode, city, country, comment } = formData;

        // Validation des champs
        if (!name || !email || !phone || !company || !street || !postalCode || !city || !country) {
            Swal.fire({
                icon: 'error',
                title: 'Erreur',
                text: 'Veuillez remplir tous les champs du formulaire avant de soumettre.',
                confirmButtonColor: '#2F4F4F',
            });
        } else {
            setIsSubmitting(true); // Désactiver le bouton
            Swal.fire({
                icon: 'success',
                title: 'Devis en cours',
                text: 'Votre demande de devis a bien été reçue.',
                confirmButtonColor: '#2F4F4F',
            }).then(() => {
                sendQuoteRequest(animalCount, price, formData);
                setIsSubmitting(false); // Réactiver le bouton après l'envoi
                setQuoteSubmitted(true); // Afficher le message de confirmation
            });
        }
    };

    // Si le devis a été envoyé, afficher le message de confirmation
    if (quoteSubmitted) {
        return (
            <div style={{ textAlign: 'center', marginTop: '2rem' }}>
                <h2 style={{ color: '#2f4f4f' }}>Merci de votre contact !</h2>
                <p style={{ color: '#2f4f4f' }}>Nous reviendrons vers vous sous 24h.</p>
            </div>
        );
    }

    return (
        <div>
            <h2 style={{ color: '#2f4f4f', marginTop: '1.7rem', marginBottom: '1rem', textAlign: 'center' }}>Choisissez le nombre d'animaux</h2>

            <input
                type="range"
                min="1000"
                max={maxAnimals}
                value={animalCount}
                onChange={handleAnimalCountChange}
                step="500"
                id="slider"
            />
            <div className="range-labels">
                <span>1000</span>
                <span>{maxAnimals}</span>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img src={catIcon} style={{ maxWidth: '50px' }} alt="Cat Icon" />
                <span style={{ color: '#2f4f4f', fontWeight: 'bolder' }}>Jusqu'à {animalCount}</span>
            </div>
            <p style={{ color: '#2f4f4f', textAlign: 'center' }}>Prix estimé par an : <span style={{ textDecoration: 'underline' }}>{price} €</span></p>

            <p style={{ color: '#2f4f4f', fontSize: '16px', marginBottom: '1rem', textAlign: 'left' }}>- Vos informations</p>

            <label for="name" style={{color: '#000'}}>Prénom & nom </label>

            <input
                type="text"
                name="name"
                placeholder="Prénom & Nom"
                value={formData.name}
                onChange={handleInputChange}
                required={true}
            />
            <label for="email" style={{color: '#000'}}>Email </label>

            <input
                type="email"
                name="email"
                placeholder="Email"
                required={true}
                value={formData.email}
                onChange={handleInputChange}
            />

<label for="phone" style={{color: '#000'}}>Téléphone </label>
            <input
                type="text"
                name="phone"
                placeholder="Téléphone"
                value={formData.phone}
                onChange={handleInputChange}
                required={true}
            />

            <label for="company" style={{color: '#000'}}>Entreprise / Organisation </label>
            <input
                type="text"
                name="company"
                placeholder="Entreprise/Organisation"
                value={formData.company}
                onChange={handleInputChange}
                required={true}
            />

<label for="street" style={{color: '#000'}}>Adresse </label>
            <input
                type="text"
                name="street"
                placeholder="Adresse"
                value={formData.street}
                onChange={handleInputChange}
                required={true}
            />

<label for="postalCode" style={{color: '#000'}}>Code postal </label>
            <input
                type="text"
                name="postalCode"
                placeholder="Code Postal"
                value={formData.postalCode}
                onChange={handleInputChange}
                required={true}
            />

<label for="city" style={{color: '#000'}}>Ville </label>
            <input
                type="text"
                name="city"
                placeholder="Ville"
                value={formData.city}
                onChange={handleInputChange}
                required={true}
            />

<label for="country" style={{color: '#000'}}>Pays </label>
            <input
                type="text"
                name="country"
                placeholder="Pays"
                value={formData.country}
                onChange={handleInputChange}
                required={true}
            />

<label for="comment" style={{color: '#000'}}>Commentaire </label>
<input
                type="text"
                name="comment"
                placeholder="Ajouter un commentaire"
                value={formData.comment}
                onChange={handleInputChange}
                required={true}
            />

            <button
                style={{ margin: 0 }}
                onClick={handleRequestQuote}
                disabled={isSubmitting} // Désactiver le bouton pendant l'envoi
            >
                {isSubmitting ? 'Envoi en cours...' : 'Demander un devis'}
            </button>
        </div>
    );
};

export default AnimalSliderForm;

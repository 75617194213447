import React from 'react';

function TableAnimals() {
    const data = [
        ['', 'Créer / signaler un animal', 'Modifier un animal', 'Supprimer un animal', 'Ajouter un document', 'Supprimer un document', 'Ajouter un commentaire', 'Supprimer un commentaire'],
        ['Créateur', 'X', 'X', 'X', 'X', 'X', 'X', 'X'],
        ['Administrateur', 'X', 'X', 'X', 'X', 'X', 'X', 'X'],
        ['Association', 'X', 'X', 'X', 'X', 'X', 'X', 'X'],
        ['Contributeur', 'X', 'X', 'X', 'X', 'X', 'X', 'X'],
        ['Invité', 'X', '', '', '', '', 'X', 'X'],

    ];

    return (
        <table border="1">
            <tbody>
                {data.map((row, rowIndex) => (
                    <tr key={rowIndex} className='tr-table'>
                        {row.map((cell, cellIndex) => (
                            <td key={cellIndex} className='td-table'>
                                {cell}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default TableAnimals;

import React from 'react';
import { LoadScript } from '@react-google-maps/api';
import GoogleMapComponent from './standardMap';

const apiKey = "AIzaSyDIOJzdyCTv51JQCP_E6Lo3tGxR2yXVijM"; // Remplacez par votre clé API

const Map = () => {
  return (
    <LoadScript googleMapsApiKey={apiKey}>
      <GoogleMapComponent/>
    </LoadScript>
  );
};

export default Map;

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import RegistrationForm from '../components/user/RegistrationForm';
import { useNavigate } from 'react-router-dom';

const RegistrationPage = () => {
    const { isAuthenticated } = useSelector((state) => state.auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, navigate]);

    return <RegistrationForm />;
};

export default RegistrationPage;
import React, { useCallback, useState } from 'react';
import { uploadSingleFile, convertAndUploadPDF, processPDFWithAdobe, cleanUpFiles } from '../../features/animals/animalSlice';
import { useDropzone } from 'react-dropzone';

import { ReactComponent as Icad } from '../../assets/logos/logo_icad.svg'; // Chemin vers votre SVG


const ImageAnalyzer = ({ onAnalysisComplete, blackFont }) => {


    const [file, setFile] = useState(null);
    const [analysisResult, setAnalysisResult] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(0); // Pour la barre de progression
    const [statusMessage, setStatusMessage] = useState(''); // Message d'état
    const [errorMessage, setErrorMessage] = useState('');

    const onDrop = useCallback(async (acceptedFiles) => {
        // Traitez ici le fichier déposé
        const selectedFile = acceptedFiles[0];
        setFile(selectedFile);
        await handleImageChange(selectedFile);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'image/*': ['.jpeg', '.jpg', '.png'],
            "application/pdf" : [ '.pdf']
           },
        disabled: isLoading
    });

    const handleImageChange = async (e) => {
        const file = e;
        if (file) {
            setIsLoading(true);
            setProgress(10); // Début du processus
            setStatusMessage('Téléchargement du fichier...');

            try {
                let fileData;
                let imageUrl;
                let pdfFileName = '';
                let imageFileName = '';

                if (file.type === 'application/pdf') {
                    setProgress(20);
                    setStatusMessage('Conversion du fichier PDF...');
                    fileData = await convertAndUploadPDF(file);
                    pdfFileName = fileData.name;
                    const processedData = await processPDFWithAdobe(fileData.url);
                    imageUrl = processedData.imageUrl;
                    const decodedImageUrl = decodeURIComponent(imageUrl);
                    imageFileName = decodedImageUrl.split('/').pop().split('?')[0];
                    setProgress(40);
                    setStatusMessage('Fichier PDF converti.');

                } else {
                    fileData = await uploadSingleFile(file, 'uploadedFile');
                    imageUrl = fileData.url;
                    const decodedImageUrl = decodeURIComponent(imageUrl);
                    imageFileName = decodedImageUrl.split('/').pop().split('?')[0];
                    setProgress(40);
                    setStatusMessage('Image téléchargée.');
                }

                setProgress(60);
                setStatusMessage('Analyse de l\'image en cours...');
                await analyzeText(imageUrl);

                setProgress(80);
                setStatusMessage('Nettoyage des fichiers...');
                if (file.type === 'application/pdf') {
                    await cleanUpFiles(imageFileName, pdfFileName, false);
                } else {
                    await cleanUpFiles(imageFileName, '', true);
                }

                setProgress(100);
                setStatusMessage('Analyse terminée.');
            } catch (error) {
                console.error('Erreur:', error);
                setStatusMessage('Erreur lors du traitement.');
                setErrorMessage('Une erreur est survenue pendant l\'analyse. Veuillez réessayer.');
    
            } finally {
                setIsLoading(false);
            }
        }
    };

    const analyzeText = async (imageUrl) => {
        try {
            const response = await fetch('https://us-central1-kappze.cloudfunctions.net/stripeapi/analyse-texte', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ imageUrl }),
            });
            const extractedData = await response.json();

            setAnalysisResult(extractedData);
            onAnalysisComplete(extractedData);
        } catch (error) {
            console.error('Erreur lors de l\'analyse du texte:', error);
            setErrorMessage('Erreur lors de l\'analyse du texte. Veuillez réessayer.');
        }
    };


    return (
        <div className="image-analyzer-container">
            <div {...getRootProps()} className='dropzone'>
                <input {...getInputProps()} disabled={isLoading} accept="image/png, image/jpeg, application/pdf"/>
                {isDragActive ? 
                    (<p>Déposez le fichier ici...</p>) : 
                    (<p>{isLoading ? '' : 'Glisser / déposer un PNG, JPEG ou PDF'}</p>)
                }

                <Icad className="grayscale-svg" />
            </div>
            <div className="status-container">
                    <div className={`status-elt ${isLoading ? 'visible' : 'hidden'}`}>
                    {blackFont === true ?  (
                        <p style={{color: "#000"}}>{statusMessage}</p>
                    ) : (<p>{statusMessage}</p>)}
                        
                        <progress value={progress} max="100"></progress>
                    </div>
                {errorMessage && (
                    <div className="error-message">
                        <p style={{color: 'black'}}>{errorMessage}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ImageAnalyzer;



import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { fetchCitiesSector } from '../../features/citiesSector/citySectorSlice';
import { fetchAnimalsByCanal } from '../../features/animals/animalSlice';
import { fetchCanalUsers } from '../../features/canals/canalUsersSlice';
import { updateAdminRequest, updateAdminNotificationPreference } from '../../features/user/userSlice';
import { fetchUserById, fetchUsersByAssociation } from '../../features/user/fetchingUserSlice';
import informationcircleoutline from '../../assets/information-circle-outline.svg';
import informationcircleoutlinewhite from '../../assets/information-circle-outline-white.svg';
import { Tooltip } from 'react-tooltip';
import helpOutline from '../../assets/help-outline.svg';
import BannerPlaceHolder from '../../assets/placeholder-banner-kappze.png'
import { formatDateToFrench } from '../../utils/formatDate';
import { createAndSendNotification } from '../../features/notifications/notificationSlice'
import CatNotFound from '../../assets/cat-not-found-white.png';
import AddMyCat from '../../assets/trace.svg';
import ImageIcon from '../../assets/image-outline.svg'
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import AddCityModal from '../../components/citiesSector/AddCityModal';
import AnimalFilters from '../../components/animals/AnimalFilters';
import iconpaw from '../../assets/icon-paw.svg';
import iconpawblack from '../../assets/icon-paw-black.svg'
import iconsignal from '../../assets/megaphone-outline.svg';
import iconsectorwhite from '../../assets/trail-sign-outline.svg';
import iconsector from '../../assets/trail-sign-outline-black.svg';
import iconadd from '../../assets/icon-add.svg';
import logoChatPerdu from '../../assets/logos/chatperdualonewithoutbackground.svg';
import iconeChatPerdu from '../../assets/logos/logo_chatperdu.png';
import iconsettings from '../../assets/icon-settings.svg';
import statsIcon from '../../assets/bar-chart-outline.svg'
import BackButton from '../../components/general/BackButton';
import LoadingPage from '../../components/general/LoadingPage';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ExportButton from '../../components/general/ExportButton'
import copy from '../../assets/copypaste.svg';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { fetchCanalsByIds, fetchLicenceStatusByCanalId } from '../../features/canals/canalSlice';
import { fetchAnimalsByAssociation, fetchCanalsAssociation, fetchInformationsFromAssociation } from '../../features/association/associationSlice'; // Assurez-vous que ce chemin est correct



Modal.setAppElement('#root')

const AssociationDetail = () => {
    const { t } = useTranslation();
    const { uid, requestAdmin, name, surname, email, notificationsEnabledAdmin, isAuthenticated, linkedAssociationId } = useSelector((state) => state.auth);
    const { id: associationId, id } = useParams();
    const { data: canals } = useSelector((state) => state.canals);
    const userCanalIds = canals.filter(canal => canal.isAdmin).map(canal => canal.id);
    const creatorDetails = useSelector(state => state.users?.userDetails);
    const membersAssociation = useSelector(state => state.users?.members || {});
    const associationCanals = useSelector(state => state.canals?.canalsAvailableForAssociations);
    // const userIsFromAssociation = membersAssociation.some(member => member.id === uid);
    const userIsFromAssociation = uid in membersAssociation;
    const canalsJoined = useSelector(state => state.associations.canals)
    const userStatus = useSelector(state => state.users.status);
    const { data: citiesSector, status, error } = useSelector((state) => state.citiesSector);
    const animals = useSelector(state => state.associations.animals); // Assurez-vous que le chemin d'accès dans le state est correct
    const animalsStatus = useSelector(state => state.associations.animals);
    const publishedAnimals = animals.filter((animal) => animal.published === true);
    const unpublishedAnimals = animals.filter((animal) => animal.published === false);
    const restrictedAnimals = publishedAnimals.filter(animal => userCanalIds.includes(animal.canalId));

    const { data: users } = useSelector((state) => state.canalUsers);
    const femaleAnimals = animals.filter((animal) => animal.sex === 'Femelle');
    const femaleAnimalsSterilized = femaleAnimals.filter((animal) => animal.isSterilise === true);
    const canalsStatus = useSelector((state) => state.canals.status);
    const [userIsAdmin, setUserRole] = useState(true);

    const numSterilizedCats = animals.filter(animal => animal.isSterilise).length;
    const numNotIdentifiedCats = animals.filter(animal => !animal.hasIdNumber).length;
    const [activeTab, setActiveTab] = useState('animaux');
    const sterilizationPercentage = Math.round((numSterilizedCats / animals.length) * 100);
    const sterilizationFemalePercentage = Math.round((femaleAnimalsSterilized.length / femaleAnimals.length) * 100);
    const amountOfAnimals = restrictedAnimals.length;
    const archiveType = 'association';
    // const backgroundUrl = canal?.imageCover?.url
    const dispatch = useDispatch();


    useEffect(() => {
        if (associationId) {
            dispatch(fetchAnimalsByAssociation({ linkedAssociationId: associationId, isLinkedToAnAssociation: true }));
            dispatch(fetchInformationsFromAssociation({ linkedAssociationId: associationId }))

        }
    }, [dispatch, associationId]);



    const associationDetails = useSelector(state => {
        return state.associations.associations.find(asso => 
            asso.rna === associationId || asso.siretAsso === associationId
        );
    });
    


    // useEffect(() => {
    //     if (associationDetails?.creatorId) {
    //         dispatch(fetchUserById(associationDetails.creatorId));
    //         dispatch(fetchCanalsAssociation(associationDetails.creatorId))
    //     }
    // }, [dispatch, associationDetails?.creatorId, uid]);

    useEffect(() => {
        if (associationDetails?.creatorId) {
            dispatch(fetchUserById(associationDetails.creatorId));
            dispatch(fetchCanalsAssociation(associationDetails.creatorId));
        }
    }, [dispatch, associationDetails?.creatorId, uid]);
    

    // useEffect(() => {
    //     if (associationDetails?.rna) {
    //         dispatch(fetchUsersByAssociation({ linkedAssociationId: associationDetails.rna, isLinkedToAnAssociation: true }));
    //     }
    // }, [dispatch, associationDetails?.rna, uid]);

    useEffect(() => {
        const linkedAssociationId = associationDetails?.rna || associationDetails?.siretAsso;
        
        if (linkedAssociationId) {
            dispatch(fetchUsersByAssociation({ linkedAssociationId, isLinkedToAnAssociation: true }));
        }
    }, [dispatch, associationDetails?.rna, associationDetails?.siret, uid]);
    


    useEffect(() => {
        // Extraire les canalId uniques des animaux
        const canalIds = [...new Set(restrictedAnimals.map(animal => animal.canalId).filter(id => id))];

        // Dispatcher l'action pour récupérer les canaux
        dispatch(fetchCanalsByIds(canalIds));
    }, [canals]);

    


    if (animalsStatus === 'loading' || canalsStatus === 'loading') {
        return <LoadingPage isLoading={true} />;
    }




    return (
        <div className=' canal-page filtered-page'>
            <LoadingPage isLoading={false} />
            <BackButton needsBackground={true} />

            <div className="canal-page_main page-main">
                <div className="canal-page_content">
                    <div className="canal-page_content-title" style={{ backgroundImage: `url(${BannerPlaceHolder})` }}>
                        <div className="canal-page_content-title-img">
                            <LazyLoadImage effect="none" src={CatNotFound} style={{ objectFit: 'contain' }} alt={`Image de ${associationDetails?.name}`} />

                            <h1 className='page-title'>{associationDetails?.name}</h1>
                        </div>
                        <Tooltip id="my-tooltip-share-canal" style={{ backgroundColor: "#122", color: "#fff", padding: '1rem', zIndex: '2', maxWidth: '248px' }}>
                            <div style={{ backgroundColor: '#122' }}>
                                <h3>{t('alias.shareChannel.title')}</h3>
                                <p>{t('alias.shareChannel.text1')}</p>
                                <ul>
                                    <li>{t('alias.shareChannel.list1')}</li>
                                    <li>{t('alias.shareChannel.list2')}</li>
                                </ul>
                            </div>
                        </Tooltip>
                    </div>
                </div>
                <div className="section-informations">

                    <div className="tabs">

                        <label
                            htmlFor="tab-animaux"
                            onClick={() => setActiveTab('animaux')}
                            className={activeTab === 'animaux' ? 'active' : ''}
                        >
                            <span className="tab-text-label">{t('animals')}</span>
                            <img className="tab-icon-label" src={iconpaw} style={{ width: '20px', marginLeft: '10px' }} />
                            <img className="tab-icon-label active" src={iconpawblack} style={{ width: '20px', marginLeft: '10px' }} />
                        </label>
                        {(userIsAdmin) && (
                            <label
                                htmlFor="tab-secteurs"
                                onClick={() => setActiveTab('secteurs')}
                                className={activeTab === 'secteurs' ? 'active' : ''}
                            >
                                <span className="tab-text-label">Villes</span>
                                <img className="tab-icon-label" src={iconsectorwhite} style={{ width: '20px', marginLeft: '10px' }} />
                                <img className="tab-icon-label active" src={iconsector} style={{ width: '20px', marginLeft: '10px' }} />
                            </label>
                        )}

                        <label
                            htmlFor="tab-informations"
                            onClick={() => setActiveTab('informations')}
                            className={activeTab === 'informations' ? 'active' : ''}
                        >
                            <span className="tab-text-label">{t('generalInformations')}</span>
                            <img className="tab-icon-label" src={informationcircleoutlinewhite} style={{ width: '20px', marginLeft: '10px' }} />
                            <img className="tab-icon-label active" src={informationcircleoutline} style={{ width: '20px', marginLeft: '10px' }} />
                        </label>


                        {/* Contenu des onglets */}

                        {(userIsFromAssociation) ? (
                            <div className={`tabs-content ${activeTab === 'animaux' ? 'active' : 'not'}`}>
                            <div className='content-animaux' style={{ padding: '1rem' }}>
                                {activeTab === 'animaux' && (
                                    <div id="content-animaux">
                                        {animals.length > 0 ? (
                                            <>
                                                <div className="stat-animal">
                                                    <div className="data-animal">
                                                        <p>{animals.length}</p>
                                                    </div>
                                                    <div className="label-animal">
                                                        <p>Chat(s)</p>
                                                        <p className='label-animal-more'>{t('ofWhich')} {femaleAnimals.length} {t('femalesListed')}</p>
                                                    </div>
                                                </div>

                                                {numNotIdentifiedCats > 0 ? (
                                                    <div className="stat-animal">
                                                        <div className="data-animal">
                                                            <p>{numNotIdentifiedCats}</p>
                                                        </div>
                                                        <div className="label-animal">
                                                            <p>{t('unidentifiedCats')}</p>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="stat-animal">
                                                        <div className="data-animal" style={{ backgroundColor: '#2f4f4f' }}>
                                                            <p style={{ color: '#fff' }}>OK</p>
                                                        </div>
                                                        <div className="label-animal">
                                                            <p>{t('everyCatsAreIdentified')}</p>
                                                        </div>
                                                    </div>
                                                )}

                                                {sterilizationFemalePercentage > 0 && (
                                                    sterilizationFemalePercentage > 75 ? (
                                                        <div className="stat-animal">
                                                            <div className="data-animal" style={{ backgroundColor: '#2f4f4f' }}>
                                                                <p style={{ color: '#fff' }}>{sterilizationFemalePercentage}%</p>
                                                            </div>
                                                            <div className="label-animal">
                                                                <p>{t('rateOfSterilizedFemales')}</p>
                                                                <p className='label-animal-more'>{t('ieATotalOf')} {femaleAnimalsSterilized.length} {t('sterilizedFemales')} / {femaleAnimals.length} {t('listed')}</p>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="stat-animal">
                                                            <div className="data-animal">
                                                                <p>{sterilizationFemalePercentage}%</p>
                                                            </div>
                                                            <div className="label-animal">
                                                                <p>{t('rateOfSterilizedFemales')}</p>
                                                                <p className='label-animal-more'>{t('ieATotalOf')} {femaleAnimalsSterilized.length} {t('sterilizedFemales')} / {femaleAnimals.length} {t('listed')}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                )}

                                                {sterilizationPercentage > 75 ? (
                                                    <div className="stat-animal">
                                                        <div className="data-animal" style={{ backgroundColor: '#2f4f4f' }}>
                                                            <p style={{ color: '#fff' }}>{sterilizationPercentage}%</p>
                                                        </div>
                                                        <div className="label-animal">
                                                            <p>{t('overallSterilizationRate')}</p>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="stat-animal">
                                                        <div className="data-animal" style={{ borderColor: '#872929' }}>
                                                            <p>{sterilizationPercentage}%</p>
                                                        </div>
                                                        <div className="label-animal">
                                                            <p>{t('overallSterilizationRate')}</p>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <p style={{ color: '#000' }}>{t('youDontHaveAnyAnimalsYet')}</p>
                                        )}
                                    </div>
                                )}

                            </div></div>
                        ) : (
                            <div className={`tabs-content ${activeTab === 'animaux' ? 'active' : 'not'}`}>
                            <div className='content-animaux' style={{ padding: '1rem' }}>
                                {activeTab === 'animaux' && (
                                    <div id="content-animaux">
                                        {restrictedAnimals.length > 0 ? (
                                            <>
                                                <div className="stat-animal">
                                                    <div className="data-animal">
                                                        <p>{restrictedAnimals.length}</p>
                                                    </div>
                                                    <div className="label-animal">
                                                        <p>Chat(s)</p>
                                                        <p className='label-animal-more'>{t('ofWhich')} {femaleAnimals.length} {t('femalesListed')}</p>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <p style={{ color: '#000' }}>{t('youDontHaveAnyAnimalsYet')}</p>
                                        )}
                                    </div>
                                )}

                            </div></div>
                        )}







                        <div className={`tabs-content ${activeTab === 'secteurs' ? 'active' : 'not'}`}>
                            <div className='content-animaux' style={{ padding: '1rem' }}>
                                {activeTab === 'secteurs' && (
                                    <div>
                                        <p style={{ color: 'black' }}>Villes où l'association intervient</p>
                                        <div id="content-secteurs-names">

                                            {status === 'loading' ? (
                                                <p>Loading...</p>
                                            ) : status === 'failed' ? (
                                                <p>Error: {error}</p>
                                            ) : (

                                                canalsJoined.length > 0 ? (
                                                    canalsJoined.map((city, index) => (
                                                        <div key={index} className="stat-animal stat-animal-sector-name">
                                                            <div className="data-animal-sector">
                                                                <p key={index} style={{ fontWeight: 'bold', color: '#000', textDecoration: 'upperline' }}>

                                                                    <span>{city.name}, {city.postalCode}</span>
                                                                </p>
                                                            </div>
                                                        </div>

                                                    ))
                                                ) : (<p style={{ color: '#000', textAlign: 'center' }}> {t('youDontHaveSectorsYet')}</p>)

                                            )}
                                        </div>
                                    </div>
                                )}

                            </div></div>
                        <div className={`tabs-content ${activeTab === 'informations' ? 'active' : 'not'}`}>
                            <div className='content-animaux' style={{ padding: '1rem' }}>
                                {activeTab === 'informations' && (
                                    <div id="content-informations">
                                        <div className="content-informations-canal">
                                            <h3>{t('generalInformations')}</h3>
                                            <p><span style={{ fontWeight: "500" }}> {associationDetails?.name}</span></p>
                                            <p><span style={{ fontWeight: "500" }}>{creatorDetails?.name}</span></p>
                                            <p><span style={{ fontWeight: "500" }}>{creatorDetails?.email}</span></p>
                                            <p><span style={{ fontWeight: "500" }}>{associationDetails?.communeLibelle}</span></p>
                                            <p><span style={{ fontWeight: "500" }}>{associationDetails?.postalCode}</span></p>
                                        </div>

                                        <div className="content-informations-members">
    <h3 style={{ textTransform: 'capitalize' }}>{t('members')}</h3>
    {Object.values(membersAssociation).map((user, index) => (
        <div key={index}>
            {user ? (
                <p style={{ color: 'black' }}>
                    <span style={{ fontWeight: 'bold' }}>
                        {user.surname} {user.name}
                    </span>, {user.email}, {user.phoneNumber}
                    {user.id === creatorDetails.id && <span style={{ color: 'red', marginLeft: '10px' }}>Créateur</span>}
                </p>
            ) : (
                <p style={{ color: 'black' }}>
                    <span style={{ fontWeight: "bolder", color: 'darkslategray' }}>
                        {t('notAnyMembers')}
                    </span>
                </p>
            )}
        </div>
    ))}
</div>
                                    </div>
                                )}

                            </div></div>
                    </div>
                </div>
            </div>
            {
                restrictedAnimals.length ? (
                    ((userIsAdmin && !userIsFromAssociation)) ? (<div className='section-animalFilters'>
                        <AnimalFilters animals={restrictedAnimals} archiveType={archiveType} isAdmin={userIsAdmin} canals={canalsJoined} />
                    </div>) : (userIsAdmin && userIsFromAssociation) ? (<div className='section-animalFilters'> <AnimalFilters animals={publishedAnimals} archiveType={archiveType} isAdmin={userIsAdmin} canals={canalsJoined} /> </div>) : (<div className='no-animals' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}><p> La consultation des animaux n'est pas disponible sur ce canal.</p>
                        <img src={CatNotFound} style={{ maxWidth: '150px' }} /></div>)




                ) : (<div className='no-animals' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}><p> Il n'y a pas d'animaux à afficher.</p>
                    <img src={CatNotFound} style={{ maxWidth: '150px' }} /></div>)
            }
        </div>
    );
};

export default AssociationDetail;
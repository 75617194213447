import { useSelector } from 'react-redux'
import { Outlet, Navigate } from 'react-router-dom'

const ProtectedRoute = () => {

    const { isAuthenticated } = useSelector((state) => state.auth);
    
    if (!isAuthenticated) {
        return (
            <>
                <Navigate to={{ pathname: '/login' }} />
            </>
        )
    } 
    return <Outlet />
}
export default ProtectedRoute
export const getErrorMsg = (errorCode) => {
    switch (errorCode) {
        case 'auth/user-not-found':
            return 'L\'utilisateur n\'existe pas';
        case 'auth/invalid-email':
            return 'Email invalide';
        case 'auth/email-already-in-use':
            return 'Email déjà utilisé par un utilisateur';
        case 'auth/wrong-password':
            return 'Mot de passe incorrect'
        case 'auth/weak-password':
            return 'Mot de passe trop faible';
        case 'auth/too-many-requests':
            return 'Trop de tentative d\'accès. Veuillez patienter avant de vous reconnecter.';
        case 'EMAIL_NOT_VERIFIED':
            return 'Veuillez valider votre email via le lien qui vous a été envoyé.';
        default:
            return 'Une erreur s\'est produite lors de la connexion. Veuillez réessayer.';
    }
};




export const validateString = (str) => {
    const errors = {};

    const nameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ-]+$/; // Les caractères alphabétiques uniquement (y compris les accents et les tirets)
    if (!nameRegex.test(str)) {
        errors.str = "Veuillez rentrer une donnée valide.";
    }

    return errors;
}

export const validateStringCommunity = (str) => {
    const errors = {};

    // Les caractères alphabétiques uniquement (y compris les accents, les espaces et les tirets)
    const nameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ\- ]+$/;
    if (!nameRegex.test(str)) {
        errors.str = "Veuillez rentrer une donnée valide.";
    }

    return errors;
}


export const validatePassword = (password) => {
    const errors = {};
    const passwordRegex = /^.{8,20}$/;
    if (!passwordRegex.test(password)) {
        errors.password = "Le mot de passe doit contenir entre 8 et 20 caractères.";
    }

    return errors;
}

export const validatePhoneNumber = (phoneNumber) => {
    const errors = {};
    const phoneNumberRegex = /^0[1-9]\d{8}$/; // Format "06 90 97 90 90"
    if (!phoneNumberRegex.test(phoneNumber)) {
        errors.phoneNumber = "Le numéro de téléphone est invalide. Format désiré : 0634321254";
    }

    return errors;
}


export const validateSiret = (siret) => {
    const errors = {};
    const sirenRegex = /^[0-9]{14}$/;
    if (!sirenRegex.test(siret)) {
        errors.siret = "Le SIRET est invalide. Format désiré : 00000000000000.";
    }

    return errors;
}

export const validateRNA = (rna) => {
    const errors = {};
    const rnaRegex = /^[A-Z]\d{8,10}$/; // Une lettre majuscule suivie de 8 à 10 chiffres

    if (!rnaRegex.test(rna)) {
        errors.rna = "Le numéro RNA est invalide. Format désiré : une lettre majuscule suivie de 8 à 10 chiffres.";
    }

    return errors;
}


export const validatePostalCode = (siren) => {
    const errors = {};
    const sirenRegex = /^[0-9]{5}$/; // Format "06 90 97 90 90"
    if (!sirenRegex.test(siren)) {
        errors.siren = "Le Code Postal est invalide. Format désiré : 00000.";
    }

    return errors;
}

export const validateEmail = (email, err) => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Vérification du format d'email
    const freeEmailRegex = /@free\.fr$/; // Vérification des adresses se terminant par free.fr

    if (!emailRegex.test(email)) {
        errors.email = "L'email est invalide. Format désiré : johndoe@domain.com";
    } else if (freeEmailRegex.test(email)) {
        errors.email = "Les adresses e-mail se terminant par free.fr ne sont pas acceptées.";
    }

    err = errors;
    return err;
}




export const validateIdCanal = (str) => {
    const errors = {};
    const stringRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ-]+$/; // Les caractères alphabétiques uniquement (y compris les accents et les tirets)
    if (!stringRegex.test(str)) {
        errors.str = "Veuillez rentrer une donnée valide.";
    }

    return errors;
}

export const validateImage = (image) => {
    const errors = {};
    const allowedExtensions = ['png', 'jpg', 'jpeg'];
    const fileExtension = image ? image.name.split('.').pop().toLowerCase() : '';
    if (!allowedExtensions.includes(fileExtension)) {
        errors.imageStr = "Impossible de charger la photo. Formats autorisés : png/jpg/jpeg.";
    }

    return errors;
}

// export const validate
import { COLORS } from '../../data/colors';
import { React, useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getFilteredAnimals } from '../../components/animals/getFilteredAnimal';
import arrowRight from '../../assets/arrow-forward.svg';
import ResetButton from '../general/ResetButton';
import { HAIRS } from '../../data/hair';
import { RACES } from '../../data/races';
import { SITUATION } from '../../data/situation';
import { useTranslation } from 'react-i18next';
import QuickSearchList from './QuickSearchList';


const QuickSearch = ({ animals, archiveType }) => {
    const { t } = useTranslation();
    const { data: citiesSector } = useSelector((state) => state.citiesSector);
    const [selectedCitySectorId, setSelectedCitySectorId] = useState('');
    const [selectedCitySector, setSelectedCitySector] = useState('');
    const [selectedColor, setSelectedColor] = useState('');
    const [selectedSituation, setSelectedSituation] = useState('');
    const [selectedHair, setSelectedHair] = useState('');
    const [selectedRace, setSelectedRace] = useState('');
    const [filterByDisease, setFilterByDisease] = useState(null);
    const [filterBySterilization, setFilterBySterilization] = useState(null);
    const [filterByIdentification, setFilterByIdentification] = useState(null);
    const [filterByDeath, setFilterByDeath] = useState(null);
    const [filterByOwner, setFilterByOwner] = useState(null);
    const [filterBySex, setFilterBySex] = useState(null);
    const [filterByName, setFilterByName] = useState('');
    const [filterById, setFilterById] = useState('');
    const [filterByMom, setFilterByMom] = useState(null);
    const [sortByDate, setSortByDate] = useState('desc'); // 'asc' pour ordre croissant, 'desc' pour ordre décroissant
    const [customDefaultValue, setDefaultValue] = useState("");
    const [showFilterPopup, setShowFilterPopup] = useState(false);
    const [animationClass, setAnimationClass] = useState('');
    const modalRef = useRef(null);



    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef && !modalRef.current.contains(event.target)) {
                handleCloseFilterPopup();
            }
        };

        const handleEscKey = (event) => {
            if (event.key === 'Escape') {
                handleCloseFilterPopup();
            }
        };

        if (showFilterPopup) {
            document.addEventListener('mousedown', handleClickOutside);
            document.addEventListener('keydown', handleEscKey);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleEscKey);
        };
    }, [showFilterPopup]);

    const handleShowFilterPopup = () => {
        setAnimationClass('popup-entering');
        setShowFilterPopup(true)

    }

    const handleCloseFilterPopup = () => {

        setAnimationClass('popup-exiting');

        setTimeout(() => {


            setShowFilterPopup(false);


        }, 450);

        setTimeout(() => {


            setAnimationClass(null);

        }, 500);
    };



    const resetFilters = () => {
        setSelectedCitySectorId(null);
        setSelectedCitySector(null);
        setSelectedColor(null);
        setSelectedHair(null);
        setSelectedRace(null);
        setFilterByDisease(null);
        setFilterBySterilization(null);
        setFilterByIdentification(null);
        setFilterByDeath(null);
        setFilterByOwner(null);
        setFilterBySex(null);
        setFilterByName(null);
        setFilterById(null);
        setFilterByMom(null);
        setSelectedSituation(null);
        setSortByDate('desc'); //
        setDefaultValue('');
    }

    const filteredAnimals = getFilteredAnimals(
        animals,
        selectedCitySector,
        selectedColor,
        selectedHair,
        selectedRace,
        filterByDisease,
        filterBySterilization,
        filterByIdentification,
        filterByOwner,
        filterBySex,
        filterByMom,
        filterByName,
        filterById,
        filterByDeath,
        selectedSituation
    );

    const sortAnimalsByDate = () => {
        const sortedAnimals = [...filteredAnimals];
        sortedAnimals.sort((a, b) => {
            const dateA = new Date(a.addedDate);
            const dateB = new Date(b.addedDate);

            if (sortByDate === 'asc') {
                return dateA - dateB; // Tri par date ascendant
            } else {
                return dateB - dateA; // Tri par date descendant
            }
        });
        return sortedAnimals;
    };

    const toggleSortByDate = () => {
        if (sortByDate === 'asc') {
            setSortByDate('desc');
        } else {
            setSortByDate('asc');
        }
    };




    const handleCitySectorChange = async (event) => {
        const selectedCitySectorId = event.target.value;
        setSelectedCitySectorId(selectedCitySectorId);
        setSelectedCitySector(event.target.options[event.target.selectedIndex].text);

    };


    const handleColorChange = (event) => {
        setSelectedColor(event.target.value);
    };

    const handleSituationChange = (event) => {
        setSelectedSituation(event.target.value);
    };

    const handleHairChange = (event) => {
        setSelectedHair(event.target.value);
    };

    const handleRaceChange = (event) => {
        setSelectedRace(event.target.value);
    };

    const handleDiseaseChange = (event) => {
        const value = event.target.value === "true" ? true : event.target.value === "false" ? false : null;
        setFilterByDisease(value);
    };

    const handleDeathChange = (event) => {
        const value = event.target.value === "true" ? true : event.target.value === "false" ? false : null;
        setFilterByDeath(value);
    };

    const handleSexChange = (event) => {
        setFilterBySex(event.target.value)
    };

    const handleMomChange = (event) => {
        const value = event.target.value === "true" ? true : event.target.value === "false" ? false : null;
        setFilterByMom(value);
    }

    const handleSterilizationChange = (event) => {
        const value = event.target.value === "true" ? true : event.target.value === "false" ? false : null;
        setFilterBySterilization(value);
    };

    const handleIdentificationChange = (event) => {
        const value = event.target.value === "true" ? true : event.target.value === "false" ? false : null;
        setFilterByIdentification(value)
    };

    const handleOwnerChange = (event) => {
        const value = event.target.value === "true" ? true : event.target.value === "false" ? false : null;
        setFilterByOwner(value);
    };



    const handleNameChange = (event) => {
        setFilterByName(event.target.value);
    };

    const handleIdChange = (event) => {
        setFilterById(event.target.value);
    };

    const sortedAnimals = sortAnimalsByDate();

    return (
        <div className='animals-parent-filters quicksearch-parent-filters'>

            <div className="filter-buttons-container" style={{ margin: 10, marginLeft: 0, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', rowGap: 10, columnGap: 10 }}>

                <button className="buttonsFilter" onClick={handleShowFilterPopup}>{t('filter')}</button>
                <button className="buttonsFilter" onClick={toggleSortByDate}>
                    {sortByDate === 'asc' ? `${t('currently')} : ${t('mostOlder')}` : `${t('currently')} : ${t('mostRecent')}`}
                    <img style={{ width: '10px', color: 'white', marginLeft: '20px' }} src={arrowRight} />
                </button>


                {filterBySex !== null && <ResetButton
                    text={`Sexe : ${filterBySex}`}
                    onClick={() => setFilterBySex(null)}
                />}

                {selectedCitySector && selectedCitySector !== 'Tous' &&

                    <ResetButton
                        text={`Secteur : ${selectedCitySector}`}
                        onClick={() => {
                            setSelectedCitySectorId(null);
                            setSelectedCitySector(null);
                        }}
                    />}

                {selectedColor && selectedColor !== 'Tous' && <ResetButton
                    text={`Couleur : ${selectedColor}`}
                    onClick={() => setSelectedColor(null)}
                />}

                {selectedSituation && selectedSituation !== 'Tous' && <ResetButton
                    text={`${selectedSituation}`}
                    onClick={() => setSelectedSituation(null)}
                />}

                {selectedHair && selectedHair !== 'Tous' && <ResetButton
                    text={`${selectedHair}`}
                    onClick={() => setSelectedHair(null)}
                />}

                {selectedRace && selectedRace !== 'Toutes' && <ResetButton
                    text={`${selectedRace}`}
                    onClick={() => setSelectedRace(null)}
                />}

                {filterByIdentification !== null && <ResetButton
                    text={`${filterByIdentification ? 'Identifié' : 'Non-identifié'}`}
                    onClick={() => setFilterByIdentification(null)}
                />}

                {filterByDisease !== null && <ResetButton
                    text={`${filterByDisease ? 'Malade' : 'Non-malade'}`}
                    onClick={() => setFilterByDisease(null)}
                />}

                {filterBySterilization && filterBySterilization !== 'Tous' && <ResetButton
                    text={`${filterBySterilization ? 'Stérilisé' : 'Non stérilisé'}`}
                    onClick={() => setFilterBySterilization(null)}
                />}

                {filterByDeath !== null && <ResetButton
                    text={`${filterByDeath ? 'Décédé' : 'Non décédé'}`}
                    onClick={() => setFilterByDeath(null)}
                />}

                {filterByName && <ResetButton
                    text={`${filterByName}`}
                    onClick={() => setFilterByName(null)}
                />}

                {filterById && <ResetButton
                    text={`${filterById}`}
                    onClick={() => setFilterById(null)}
                />}

                {filterByMom !== null && <ResetButton
                    text={`${filterByMom ? 'Est une mère' : 'N\'est pas une mère'}`}
                    onClick={() => setFilterByMom(null)}
                />}

                {filterByOwner !== null && <ResetButton
                    text={`${filterByOwner ? 'A un propriétaire' : 'N\'a pas de propriétaire'}`}
                    onClick={() => setFilterByOwner(null)}
                />}
            </div>


            {showFilterPopup && (
                <div className={`popup-container ${animationClass}`}>
                    <div className="popup" ref={modalRef}>

                        <div className="popup-logo">
                            <div className="buttonCloseContainer">
                                <button className="buttonCloseFilter buttonsFilter" onClick={handleCloseFilterPopup}>x</button>
                            </div>
                        </div>

                        <h2 className='editable-fields-label'>{t('filters.title')}</h2>

                        <div className="inputs-filter-modal">
                            {archiveType === 'canal' ? (
                                <div className='input-filter-modal'>

                                    <label className="labelFilters" htmlFor="citySector">{t('filters.sector')}</label>
                                    <select
                                        name="citySector"
                                        id="citySector"
                                        value={selectedCitySectorId === null ? customDefaultValue : selectedCitySectorId}
                                        onChange={handleCitySectorChange}
                                        className="inputFilter"
                                    >
                                        <option defaultValue={customDefaultValue}>Tous</option>
                                        {citiesSector.map((citySector) => (
                                            <option value={citySector.id} key={citySector.id} >
                                                {citySector.name}
                                            </option>
                                        ))}
                                    </select>



                                </div>
                            ) : (<></>)}


                            <div className='input-filter-modal'>
                                <label className="labelFilters" htmlFor="sexe">{t('filters.sex')}</label>
                                <select
                                    name="sexe"
                                    id="sexe"
                                    value={filterBySex === null ? customDefaultValue : filterBySex}
                                    onChange={handleSexChange}
                                    className="inputFilter"
                                >
                                    <option defaultValue={customDefaultValue}>Tous</option>
                                    <option value="Mâle">Mâle</option>
                                    <option value="Femelle">Femelle</option>
                                    <option value="Inconnu">Inconnu</option>
                                </select>
                            </div>

                            <div className='input-filter-modal'>
                                <label className="labelFilters" htmlFor="color">{t('filters.color')}</label>
                                <select className="inputFilter" name="color" id="color" value={selectedColor === null ? customDefaultValue : selectedColor} onChange={handleColorChange}>
                                    <option defaultValue={customDefaultValue}>Toutes</option>
                                    {Array.from(new Set(Object.values(COLORS))).map((colorValue, index) => {
                                        return (
                                            <option key={index} value={colorValue}>
                                                {colorValue}
                                            </option>
                                        )
                                    })}
                                </select>
                            </div>

                            <div className='input-filter-modal'>
                                <label className="labelFilters" htmlFor="hair">{t('filters.hair')}</label>
                                <select className="inputFilter" name="hair" id="hair" value={selectedHair === null ? customDefaultValue : selectedHair} onChange={handleHairChange}>
                                    <option defaultValue={customDefaultValue}>Tous</option>
                                    {Array.from(new Set(Object.values(HAIRS))).map((hairValue, index) => {
                                        return (
                                            <option key={index} value={hairValue}>
                                                {hairValue}
                                            </option>
                                        )
                                    })}
                                </select>
                            </div>

                            <div className='input-filter-modal'>
                                <label className="labelFilters" htmlFor="race">{t('filters.breed')}</label>
                                <select className="inputFilter" name="race" id="race" value={selectedRace === null ? customDefaultValue : selectedRace} onChange={handleRaceChange}>
                                    <option defaultValue={customDefaultValue}>Toutes</option>
                                    {Array.from(new Set(Object.values(RACES))).map((raceValue, index) => {
                                        return (
                                            <option key={index} value={raceValue}>
                                                {raceValue}
                                            </option>
                                        )

                                    })}
                                </select>
                            </div>

                            <div className='input-filter-modal'>
                                <label className="labelFilters" htmlFor="race">{t('filters.situation')}</label>
                                <select className="inputFilter" name="race" id="race" value={selectedSituation === null ? customDefaultValue : selectedSituation} onChange={handleSituationChange}>
                                    <option defaultValue={customDefaultValue}>Toutes</option>
                                    {SITUATION.map((situation) => (
                                        <option key={situation} value={situation.label}>
                                            {situation.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className='input-filter-modal'>
                                <label className="labelFilters" htmlFor="disease">{t('filters.withDiseases')}</label>
                                <select
                                    name="disease"
                                    id="disease"
                                    value={filterByDisease === null ? customDefaultValue : filterByDisease}
                                    onChange={handleDiseaseChange}
                                    className="inputFilter"
                                >
                                    <option defaultValue={customDefaultValue}>-</option>
                                    <option value="true">Oui</option>
                                    <option value="false">Non</option>
                                </select>
                            </div>

                            <div className='input-filter-modal'>
                                <label className="labelFilters" htmlFor="death">{t('filters.deceased')}</label>
                                <select
                                    name="death"
                                    id="death"
                                    value={filterByDeath === null ? customDefaultValue : filterByDeath}
                                    onChange={handleDeathChange}
                                    className="inputFilter"
                                >
                                    <option defaultValue={customDefaultValue}>-</option>
                                    <option value="true">Oui</option>
                                    <option value="false">Non</option>
                                </select>
                            </div>

                            <div className='input-filter-modal'>
                                <label className="labelFilters" htmlFor="sterilization">{t('filters.sterilized')}</label>
                                <select
                                    name="sterilization"
                                    id="sterilization"
                                    value={filterBySterilization === null ? customDefaultValue : filterBySterilization}

                                    onChange={handleSterilizationChange}
                                    className="inputFilter"
                                >
                                    <option defaultValue={customDefaultValue}>-</option>
                                    <option value="true">Oui</option>
                                    <option value="false">Non</option>
                                </select>
                            </div>

                            <div className='input-filter-modal'>
                                <label className="labelFilters" htmlFor="identification">{t('filters.identified')}</label>
                                <select
                                    name="identification"
                                    id="identification"
                                    value={filterByIdentification === null ? customDefaultValue : filterByIdentification}
                                    onChange={handleIdentificationChange}
                                    className="inputFilter"
                                >
                                    <option defaultValue={customDefaultValue}>-</option>
                                    <option value="true">Oui</option>
                                    <option value="false">Non</option>
                                </select>
                            </div>

                            <div className='input-filter-modal'>
                                <label className="labelFilters" htmlFor="owner">{t('filters.belongsToAnOwner')}</label>
                                <select
                                    name="owner"
                                    id="owner"
                                    value={filterByOwner === null ? customDefaultValue : filterByOwner}
                                    onChange={handleOwnerChange}
                                    className="inputFilter"
                                >
                                    <option defaultValue={customDefaultValue}>-</option>
                                    <option value="true">Oui</option>
                                    <option value="false">Non</option>
                                </select>
                            </div>

                            <div className='input-filter-modal'>
                                <label className="labelFilters" htmlFor="name">{t('filters.name')}</label>
                                <input className="inputFilter" placeholder="Nom du chat" type="text" id="name" value={filterByName === null ? customDefaultValue : filterByName} onChange={handleNameChange} />
                            </div>

                            <div className='input-filter-modal'>
                                <label className="labelFilters" htmlFor="idcard">{t('filters.identificationNumber')}</label>
                                <input className="inputFilter" placeholder="N° identification du chat" type="text" id="idcard" value={filterById === null ? customDefaultValue : filterById} onChange={handleIdChange} />
                            </div>

                            <div className='input-filter-modal'>
                                <label className="labelFilters" htmlFor="mom">{t('filters.isAMother')}</label>
                                <select
                                    name="catmom"
                                    id="catmom"
                                    value={filterByMom === null ? customDefaultValue : filterByMom}
                                    onChange={handleMomChange}
                                    className="inputFilter"
                                >
                                    <option defaultValue={customDefaultValue}>-</option>
                                    <option value="true">Oui</option>
                                    <option value="false">Non</option>
                                </select>
                            </div>
                        </div>

                        <div className="popup-btns">
                            <button className="btn-cancel" onClick={resetFilters}>Reset</button>
                            <button className="btn-validate" onClick={handleCloseFilterPopup}>{t('validate')}</button>
                        </div>
                    </div>
                </div>
            )}

            <QuickSearchList animals={sortedAnimals} />
        </div>
    );

};

export default QuickSearch;


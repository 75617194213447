import React from 'react';
import { useTranslation } from 'react-i18next';

const SignalementsGraph = ({ animals, year }) => {
    const { t } = useTranslation();
    const previousYear = (parseInt(year) - 1).toString();
    let stringYear = year.toString();
    let stringPreviousYear = previousYear.toString();
    const countSignalements = () => {
        

        return animals.filter(animal =>
            animal?.addedDate?.startsWith(stringYear) &&
            animal?.signal === true &&
            animal?.published === true &&
            animal?.signalByOwner === false
        ).length;

        
    };

    const countSignalementsLastYear = () => {

        return animals.filter(animal =>
            animal?.addedDate?.startsWith(stringPreviousYear) &&
            animal?.signal === true &&
            animal?.published === true &&
            animal?.signalByOwner === false
        ).length;
    };

    const calculatePercentageDifference = (current, previous) => {
        if (previous === 0) return 'N/A'; // Pour éviter la division par zéro
        let difference = (((current - previous) / previous) * 100).toFixed(2);
        let sign = difference >= 0 ? '+' : ''; // Ajoute un '+' si la différence est positive ou zéro
        return `${sign}${difference}%`;
    };
    const totalSignalements = countSignalements();

    const totalSignalementsLastYear = countSignalementsLastYear();

    const percentageTotal = calculatePercentageDifference(totalSignalements, totalSignalementsLastYear);

    return (
        <div className="signalements-graph">

            <div style={{ display: 'flex', flexDirection: 'row', columnGap: '1rem' }}>

                <div style={{ textAlign: 'center' }}>
                    <div className="signalements-count">
                        {totalSignalements}
                    </div>
                    <div className="signalements-label">
                    {t('graph.reports.reports')} {t('in')} {year}
                    </div>
                </div>

                <div style={{ textAlign: 'center' }}>
                    <div className="signalements-count">
                        {percentageTotal}
                    </div>
                    <div className="signalements-label">
                    {totalSignalementsLastYear} {t('graph.reports.reports')} {t('in')} {previousYear}
                    </div>
                </div>
            </div>

        </div>
    );
};

export default SignalementsGraph;

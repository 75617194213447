import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import d3LocaleFr from '../../data/fr-FR.json';
import d3LocaleEn from '../../data/en-EN.json';
import { useTranslation } from 'react-i18next';

const CurvesIdentificationGraph = ({ animals, year }) => {
    const d3Container = useRef(null);
    const { t } = useTranslation();
    const currentLanguage = t('currentLanguage') ; // ou t('currentLanguage') si vous stockez la langue actuelle dans vos ressources i18n

    const d3Locale = currentLanguage === 'fr' ? d3.timeFormatLocale(d3LocaleFr) : d3.timeFormatLocale(d3LocaleEn);
    const formatMonth = d3Locale.format("%B");

    useEffect(() => {
        d3.select(d3Container.current).selectAll("*").remove();
        if (animals && d3Container.current) {
            const strayCounts = Array.from({ length: 12 }, () => 0);
            const identificationCounts = Array.from({ length: 12 }, () => 0);

            // Ajouter un traitement similaire pour l'année précédente
            const previousYear = (parseInt(year) - 1).toString();
            const strayCountsPreviousYear = Array.from({ length: 12 }, () => 0);
            const sterilizationCountsPreviousYear = Array.from({ length: 12 }, () => 0);

            animals.forEach(animal => {
                // Vérifier si l'animal est errant et si l'année correspond
                if (animal?.addedAsStray && animal?.published && animal?.addedDate?.startsWith(year)) {
                    const month = parseInt(animal?.addedDate?.split('-')[1], 10) - 1;
                    strayCounts[month]++;
                }
                // Vérifier si l'animal a été stérilisé et si l'année correspond
                if (animal?.dateIdentification && animal?.published && animal?.addedAsStray && animal?.dateIdentification?.startsWith(year)) {
                    const month = parseInt(animal?.dateIdentification?.split('-')[1], 10) - 1;
                    identificationCounts[month]++;
                }
            });

            animals.forEach(animal => {
                // Traiter les données pour l'année précédente
                if (animal?.addedAsStray && animal.published && animal?.addedDate?.startsWith(previousYear)) {
                    const month = parseInt(animal?.addedDate?.split('-')[1], 10) - 1;


                    strayCountsPreviousYear[month]++;
                }
                if (animal?.dateIdentification && animal?.addedAsStray && animal?.published && animal?.dateIdentification?.startsWith(previousYear)) {
                    const month = parseInt(animal?.dateIdentification?.split('-')[1], 10) - 1;
                    sterilizationCountsPreviousYear[month]++;
                }
            });

            // Configuration de base pour le graphique D3
            const margin = { top: 20, right: 30, bottom: 140, left: 90 };
            const width = 700 - margin.left - margin.right;
            const height = 470 - margin.top - margin.bottom;

            // Créer un SVG
            const svg = d3.select(d3Container.current)
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .append('g')
                .attr('transform', `translate(${margin.left},${margin.top})`);

            // Créer l'échelle X
            const x = d3.scaleTime()
                .domain([new Date(year, 0, 1), new Date(year, 11, 31)])
                .range([0, width]);
                

            svg.append('g')
                .attr('transform', `translate(0,${height})`)
                .call(d3.axisBottom(x).tickFormat(formatMonth))
                .style("color", "black");

            // Créer l'échelle Y
            const maxY = Math.max(d3.max(strayCounts), d3.max(identificationCounts));
            const y = d3.scaleLinear()
                .domain([0, maxY])
                .range([height, 0])

            // Ajouter l'axe Y
            const yAxis = svg.append("g")
                .call(d3.axisLeft(y))
                .style("stroke", "black");

            // Changer la couleur des lignes (ticks) de l'axe Y
            yAxis.selectAll(".tick line")
                .style("stroke", "black");

            // Changer la couleur du chemin de l'axe Y
            yAxis.select(".domain")
                .style("stroke", "black")

            // Changer la couleur du texte de l'axe Y
            yAxis.selectAll(".tick text")
                .style("fill", "black")
                .style('font-family', 'Wix Madefor Display')


            // Fonction de tracé des lignes
            const line = d3.line()
                .x((_, i) => x(new Date(year, i)))
                .y(d => y(d))
                .curve(d3.curveMonotoneX);


            const addPoints = (data, color) => {
                svg.selectAll(`circle.${color}`)
                    .data(data)
                    .enter()
                    .append('circle')
                    .attr('class', color)
                    .attr('fill', color)
                    .attr('cx', (_, i) => x(new Date(year, i)))
                    .attr('cy', d => y(d))
                    .attr('r', 3);
            };

            // Ajouter des points sur les courbes
            addPoints(strayCounts, 'white');
            addPoints(identificationCounts, 'white');

            // Dessiner la courbe des chats errants
            svg.append('path')
                .data([strayCounts])
                .attr('fill', 'none')
                .attr('stroke', 'green')
                .attr('stroke-width', 3)
                .attr('d', line);

            // Dessiner la courbe des stérilisations
            svg.append('path')
                .data([identificationCounts])
                .attr('fill', 'none')
                .attr('stroke', '#d25f43')
                .attr('stroke-width', 3)
                .attr('d', line);

            // Dessiner les courbes pour l'année précédente (avec un style différent)
            svg.append('path')
                .data([strayCountsPreviousYear])
                .attr('fill', 'none')
                .attr('stroke', 'green') // Couleur différente pour l'année précédente
                .attr('stroke-width', 2)
                .attr('opacity', 0.2)
                .attr('d', line);

            svg.append('path')
                .data([sterilizationCountsPreviousYear])
                .attr('fill', 'none')
                .attr('stroke', '#d25f43') // Couleur différente pour l'année précédente
                .attr('stroke-width', 2)
                .attr('opacity', 0.2)
                .attr('d', line);

            svg.append('text')
                .attr('transform', `translate(${width / 2}, ${height + margin.top + 40})`)
                .style('text-anchor', 'middle')
                .text(`${t('graph.identifications.monthsOfTheYear')}`)
                .style('fill', 'black') // Couleur du texte en blanc
                .style('font-family', 'Wix Madefor Display')

            // Ajouter la légende de l'axe Y
            svg.append('text')
                .attr('transform', 'rotate(-90)')
                .attr('y', 0 - margin.left + 20)
                .attr('x', 0 - (height / 2))
                .attr('dy', '1em')
                .style('text-anchor', 'middle')
                .text(`${t('graph.identifications.numberOfAnimals')}`)
                .style('fill', 'black') // Couleur du texte en blanc
                .style('font-family', 'Wix Madefor Display')

            // Ajouter la légende des couleurs
            const legend = svg.selectAll('.legend')
                .data([{ color: 'green', text: `${t('graph.identifications.addingWanderingCat')}` }, { color: '#d25f43', text: `${t('graph.identifications.numberOfIdentifications')}` }])
                .enter().append('g')
                .attr('class', 'legend')
                .attr('transform', (d, i) => `translate(0, ${i * 20})`)
                .style('font-family', 'Wix Madefor Display')


            const barLegend = svg.append("g")
                .attr("class", "bar-legend")
                .attr("transform", `translate(${width - 150}, 20)`)// Ajuster la position si nécessaire

            barLegend.append("text")
                .attr("x", -490)
                .attr("y", 390)
                .attr("dy", ".35em")
                .text(`${year} ${t('graph.sterilizations.inComparisonWith')} ${previousYear}.`)
                .style("font-size", "12px")
                .style("fill", "black")
                .style('font-family', 'Wix Madefor Display')

            legend.append('rect')
                .attr('x', width - 18)
                .attr("y", 380)
                .attr('width', 18)
                .attr('height', 18)
                .style('fill', d => d.color);

            legend.append('text')
                .attr('x', width - 24)
                .attr("y", 390)
                .attr('dy', '.35em')
                .style("font-size", "12px")
                .style('text-anchor', 'end')
                .text(d => d.text)
                .style('fill', 'black') // Couleur du texte en blanc
        }
    }, [animals, year, currentLanguage]);

    return (
        <svg ref={d3Container}></svg>
    );
};

export default CurvesIdentificationGraph;

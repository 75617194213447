import React, { useState, useEffect } from 'react';
import logocat from '../../assets/kappze_logo_circle_noir_roigne.png';
import logopremium from '../../assets/logos/kappze_logo_circle_noir_gold.png';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../features/user/userSlice';
import logOutIcon from '../../assets/log-out-outline.svg';
import { useTranslation } from 'react-i18next';
import LanguageSelector from './SwitchLanguage';
import heartwhite from '../../assets/heart-circle-sharp.svg';
import iconhouse from '../../assets/icon-house.svg'
import userprofile from '../../assets/user-solid.svg';

import { initReactI18next } from 'react-i18next';

const Header = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isAuthenticated, uid, licenceNumber, licenceStatus, isAssociation, productType, associationId, isLinkedToAnAssociation } = useSelector((state) => state.auth);
    const location = useLocation();
    const [isMenuOpen, setIsMenuOpen] = useState(null);
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 900);

    const isPasswordPage = location.pathname === '/password';
    const isAnimalPage = location.pathname.startsWith('/animal/');
    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };


    const handleLogout = () => {
        dispatch(logout());
        navigate('/'); // Rediriger vers la page d'accueil

    };

    useEffect(() => {
        const handleDocumentClick = (e) => {
            if (isMenuOpen && !e.target.closest('.header-resp-nav') && !e.target.closest('.burger')) {
                setIsMenuOpen(false);
            }
        };
        document.addEventListener('mousedown', handleDocumentClick);

        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, [isMenuOpen]);

    const handleLinkClick = () => {
        setIsMenuOpen(false);
    };


    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth > 767);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (isPasswordPage || (!isAuthenticated && !isAnimalPage)) {
        return null;
    } else if (isAnimalPage && !isAuthenticated) {
        return (
            <header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)', padding: '16px', backgroundColor: '#122' }}>
                <div className='logo-title'>
                    <NavLink to="/" style={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
                        <img src={logocat} alt="Logo" style={{ height: '50px' }} />
                        <h2 style={{ color: '#ddd' }}>Kappze</h2></NavLink>
                </div>
                <div className="header-main-div" style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    {isDesktop ? (
                        // Menu régulier pour les écrans de plus de 767px
                        <>
                            {!isPasswordPage && (
                                <>
                                    <nav style={{ display: 'flex', alignItems: 'center', marginRight: '16px', width: '100%', justifyContent: 'flex-end' }}>
                                        <NavLink to={`/login/`} style={{ color: '#ddd', marginRight: '26px' }} className="header-elt">
                                            {t('logIn')}
                                        </NavLink>
                                    </nav>
                                </>
                            )}
                        </>
                    ) : (
                        // Menu burger pour les écrans jusqu'à 767px
                        <div className="header-resp">
                            <div className={`burger header-resp-burger ${isMenuOpen ? 'open' : ''}`} onClick={handleMenuToggle}>
                                <div className="burger-line"></div>
                                <div className="burger-line"></div>
                                <div className="burger-line"></div>
                            </div>

                            <nav className={`header-resp-nav ${isMenuOpen ? 'open' : ''}`} >
                                {!isPasswordPage && (
                                    <span style={{ width: '100%', background: '#122', display: 'flex', alignItems: 'flex-start', rowGap: '2rem', borderRadius: '2px' }}>
                                        <NavLink onClick={handleLinkClick} to={`/login/`} style={{ color: '#ddd', fontWeight: 'bolder', margin: '16px' }}>
                                            {t('logIn')}
                                        </NavLink>
                                    </span>
                                )}
                            </nav>
                        </div>
                    )}
                </div>
            </header>
        );
    }



    return (
        <header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', rowGap: '1rem', columnGap: "1rem", boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)', padding: '16px', backgroundColor: '#122' }}>
            <div className='logo-title'>
                {(licenceStatus !== 'inactive' && productType !== 1) ? (
                    <NavLink to="/" style={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
                        <img src={logopremium} alt="Logo" style={{ height: '50px' }} />
                        <h2 style={{ color: '#ddd' }}>Kappze+</h2>
                    </NavLink>

                ) : (<NavLink to="/" style={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
                    <img src={logocat} alt="Logo" style={{ height: '50px' }} />
                    <h2 style={{ color: '#ddd' }}>Kappze</h2>
                </NavLink>)}

            </div>


            <div className="header-main-div" style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                {isDesktop ? (
                    // Menu régulier pour les écrans de plus de 767px
                    <>
                        {!isPasswordPage && (
                            <>
                                <nav style={{ display: 'flex', alignItems: 'center', textAlign:'center', fontSize: "14px", marginRight: '16px', width: '100%', justifyContent: 'flex-end' }}>



                                    <a href="https://www.cnpa-asso.fr/annuaire/" target="_blank"
                                        rel="noreferrer" style={{ color: '#ddd', marginRight: '16px', fontSize: "14px" }} className="header-elt">
                                        Trouver une association
                                    </a>
                                    <span style={{ color: '#fff', marginRight: '1rem' }}>•</span>
                                  <a href="https://www.i-cad.fr/verifier-animal-chien-chat-enregistrement-fichiers-officiels" target="_blank"
                                        rel="noreferrer" style={{ color: '#ddd', marginRight: '16px', fontSize: '14px' }} className="header-elt">
                                        Vérifier une identité (I-CAD)
                                    </a>

                                    <span style={{ color: '#fff', marginRight: '1rem' }}>•</span>

                                    {isAssociation && (
                                        <>
                                            <NavLink to={`/association/${associationId}`} style={{ color: '#ddd', marginRight: '16px', display: 'flex', alignItems: 'center', fontSize: "14px" }} className="header-elt">
                                                <img style={{ width: '20px', marginLeft: '5px' }} src={heartwhite} alt="Icon Add" className="button-icon" /> {t('myAssociation')}
                                            </NavLink>

                                            <span style={{ color: '#fff', marginRight: '1rem' }}>•</span>

                                        </>
                                    )}


                                    {(!isAssociation && isLinkedToAnAssociation) && (
                                        <>
                                            <NavLink to={`/associations/`} style={{ color: '#ddd', marginRight: '16px', display: 'flex', alignItems: 'center', fontSize: "14px" }} className="header-elt">
                                                <img style={{ width: '20px', marginLeft: '5px' }} src={heartwhite} alt="Icon Add" className="button-icon" />
                                                {t('myAssociations')}
                                            </NavLink>


                                            <span style={{ color: '#fff', marginRight: '1rem' }}>•</span>
                                        </>

                                    )}


                                    <NavLink to={`/user-profile`} style={{ color: '#ddd', marginRight: '16px', display: 'flex', alignItems: 'center', fontSize: "14px" }} className="header-elt">
                                        <img style={{ width: '20px', marginLeft: '5px' }} src={iconhouse} alt="Icon Add" className="button-icon" />  {t('myChannels')}
                                    </NavLink>
                                    <span style={{ color: '#fff', marginRight: '1rem' }}>•</span>
                                    <NavLink to={`/settings/${uid}`} style={{ color: '#ddd', marginRight: '26px', display: 'flex', alignItems: 'center', fontSize: "14px" }} className="header-elt">
                                        <img style={{ width: '14px', marginLeft: '5px', marginRight: '10px' }} src={userprofile} alt="Icon Add" className="button-icon" />
                                        {t('myProfile')}
                                    </NavLink>
                                    <img src={logOutIcon} onClick={handleLogout} className="btnLogout" style={{ maxWidth: '30px' }} />

                                </nav>
                                {/* <LanguageSelector /> */}
                            </>
                        )}
                    </>
                ) : (
                    // Menu burger pour les écrans jusqu'à 767px
                    <div className="header-resp">
                        <div className={`burger header-resp-burger ${isMenuOpen ? 'open' : ''}`} onClick={handleMenuToggle}>                            <div className="burger-line"></div>
                            <div className="burger-line"></div>
                            <div className="burger-line"></div>
                        </div>

                        <nav className={`header-resp-nav ${isMenuOpen ? 'open' : ''}`}>
                            {!isPasswordPage && (
                                <span style={{ width: '100%', background: '#122', display: 'flex', alignItems: 'flex-start', rowGap: '5px', borderRadius: '2px', zIndex: "5" }}>
                                    <NavLink onClick={handleLinkClick} to={`/user-profile`} style={{ color: '#ddd', marginRight: '16px', marginLeft: '16px', marginTop:'16px', display: 'flex', alignItems: 'center', flexDirection: 'row-reverse' }}>
                                        <img style={{ width: '20px', marginLeft: '5px' }} src={iconhouse} alt="Icon Add" className="button-icon" />
                                        {t('myChannels')}
                                    </NavLink>
                                    <span>__</span>
                                    <NavLink onClick={handleLinkClick} to={`/settings/${uid}`} style={{ color: '#ddd', marginRight: '16px', marginLeft: '16px', display: 'flex', alignItems: 'center', flexDirection: 'row-reverse' }} >
                                        <img style={{ width: '14px', marginLeft: '5px' }} src={userprofile} alt="Icon Add" className="button-icon" />
                                        {t('myProfile')}
                                    </NavLink>


                                    {isAssociation && (
                                        <>
                                            <span>__</span>
                                            <NavLink to={`/association/${associationId}`} style={{ color: '#ddd', marginRight: '16px', marginLeft: '16px', display: 'flex', alignItems: 'center', flexDirection: 'row-reverse' }} className="header-elt">
                                                <img style={{ width: '20px', marginLeft: '5px' }} src={heartwhite} alt="Icon Add" className="button-icon" />
                                                {t('myAssociation')}
                                            </NavLink>

                                        </>
                                    )}


                                    {(!isAssociation && isLinkedToAnAssociation) && (
                                        <>
                                            <span>__</span>
                                            <NavLink to={`/associations/${uid}`} style={{ color: '#ddd', marginRight: '16px', marginLeft: '16px', display: 'flex', alignItems: 'center', flexDirection: 'row-reverse' }} className="header-elt">
                                                <img style={{ width: '20px', marginLeft: '5px' }} src={heartwhite} alt="Icon Add" className="button-icon" />
                                                {t('myAssociations')}
                                            </NavLink>
                                        </>

                                    )}


                                    <span>__</span>
                                    <a onClick={handleLinkClick} href="https://www.i-cad.fr/verifier-animal-chien-chat-enregistrement-fichiers-officiels" target="_blank"
                                        rel="noreferrer" style={{ color: '#ddd', marginRight: '16px', marginLeft: '16px', fontSize: '14px' }}>
                                        Vérifier une identité (I-CAD)
                                    </a>
                                    <span>__</span>
                                    <a onClick={handleLinkClick} href="https://www.cnpa-asso.fr/annuaire/" target="_blank"
                                        rel="noreferrer" style={{ color: '#ddd', marginRight: '16px', marginLeft: '16px', fontSize: '14px' }}>
                                        Trouver une association
                                    </a>
                                    <span>__</span>
                                    <a onClick={() => dispatch(logout())} style={{ marginBottom: '16px', color: '#ddd', background: '#122', marginLeft: '16px', display: 'flex', alignItems: 'center' }}>{t('logOut')} <img src={logOutIcon} className="btnLogout" style={{ maxWidth: '30px' }} /></a>

                                </span>
                            )}
                        </nav>
                    </div>
                )}
            </div>
        </header>
    );
};

export default Header;

import React, { useEffect, useState } from "react";
import { fetchPaymentsForCustomer } from "../features/payments/billingSlice";
import { PaymentsList } from '../components/payments/paymentsList';
import LoadingPage from "../components/general/LoadingPage";

export const PaymentsScreen = ( userCustomerId ) => {
    const [payments, setPayments] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        try {
          setLoading(true);
          const customerId = userCustomerId; // Remplacez par l'ID du client
          const fetchedPayments = await fetchPaymentsForCustomer(customerId);
          setPayments(fetchedPayments);
        } catch (error) {
          console.error("Erreur lors du chargement des données:", error);
        } finally {
          setLoading(false);
        }
      };
      
      useEffect(() => {
        fetchData();
      }, []);

      if (loading) {
        return <LoadingPage isLoading={loading}/>;
      }

    return (
        <div className=" page-main">
        <h2 style={{color: '#fff', padding: '1.5rem', paddingLeft: '0rem'}}>Vos derniers paiements : </h2>
        {payments.length === 0 ?  (<p>Vous n'avez pas de paiements effectués.</p>) : (<PaymentsList payments={payments} />)}
            
        </div>
    );
};

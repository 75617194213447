import React from 'react';
import { useTranslation } from 'react-i18next';

const TotalTrappedGraph = ({ animals, year }) => {
    const previousYear = (parseInt(year) - 1).toString();
    const { t } = useTranslation();

    const countTrapped = () => {
        return animals.filter(animal => animal?.trappedDate?.startsWith(year)).length;
    };

    const totalTrapped = countTrapped();

    const countTrappedLastYear = () => {
        return animals.filter(animal => animal?.trappedDate?.startsWith(previousYear)).length;
    };

    const totalTrappedLastYear = countTrappedLastYear();

    const calculatePercentageDifference = (current, previous) => {
        if (previous === 0) return 'N/A'; // Pour éviter la division par zéro
        let difference = (((current - previous) / previous) * 100).toFixed(2);
        let sign = difference >= 0 ? '+' : ''; // Ajoute un '+' si la différence est positive ou zéro
        return `${sign}${difference}%`;
    };
    const percentageDifference = calculatePercentageDifference(totalTrapped, totalTrappedLastYear);

    return (
        <div className="signalements-graph" id='svg-2'>
            <div style={{ display: 'flex', flexDirection: 'row', columnGap: '1rem' }}>
                <div style={{ textAlign: 'center' }}>
                    <div className="signalements-count">
                        {totalTrapped}
                    </div>
                    <div className="signalements-label">
                        {t('graph.captures.numberOfCapturesIn')} {year}
                    </div>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <div className="signalements-count">
                        {percentageDifference}
                    </div>
                    <div className="signalements-label">
                        {totalTrappedLastYear} {t('captures')} {t('in')} {previousYear}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TotalTrappedGraph;

// import React, { useState } from 'react';
// import { useDispatch } from 'react-redux';
// import { fetchPostalCodeData } from '../../features/siret/siretSlice.js';

// const CitySelectByCp = ({ setCodeCommune, setNomCommune, onCodePostalChange }) => {
//   const [codePostal, setCodePostal] = useState('');
//   const [villes, setVilles] = useState([]);
//   const [selectDisabled, setSelectDisabled] = useState(true);
//   const dispatch = useDispatch();

//   const handleChangeCodePostal = (e) => {
//     const newCodePostal = e.target.value;
//     setCodePostal(newCodePostal);
//     onCodePostalChange(newCodePostal);
//     setSelectDisabled(true); // Désactive le select pendant la recherche

//     if (newCodePostal.length === 5) {
//       dispatch(fetchPostalCodeData(newCodePostal))
//         .then(response => {
//           setVilles(response.payload); // Supposant que la réponse est directement le tableau des villes
//           setSelectDisabled(false); // Réactive le select si des villes sont trouvées
//         })
//         .catch(error => {
//           console.error("Erreur lors de la récupération des villes:", error);
//           setVilles([]); // Assurez-vous de gérer l'état des villes en cas d'erreur
//         });
//     } else {
//       setVilles([]); // Assure une réinitialisation lorsque le code postal est invalide
//     }
//   };

//   const handleSelectVille = (e) => {
//     const selectedVille = villes.find(ville => ville.nomCommune === e.target.value);
//     setCodeCommune(selectedVille.codeCommune);
//     setNomCommune(selectedVille.nomCommune);
//   };

//   return (
//     <div className="citySelectContainer">
//       <input
//         type="text"
//         value={codePostal}
//         onChange={handleChangeCodePostal}
//         placeholder="Entrez votre code postal"
//         className='citySelectPostalCode'
//       />
//       <select
//         className='citySelectCity'
//         onChange={handleSelectVille}
//         defaultValue=""
//         disabled={selectDisabled || villes.length === 0 || !codePostal}
//       >
//         <option value="" disabled>Choisissez votre ville</option>
//         {villes.length > 0 ? (
//           villes.map((ville, index) => (
//             <option key={index} value={ville.nomCommune}>{ville.nomCommune}</option>
//           ))
//         ) : (
//           <option value="" disabled>Aucun résultat</option>
//         )}
//       </select>
//     </div>
//   );
// };

// export default CitySelectByCp;

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchPostalCodeData } from '../../features/siret/siretSlice.js';

const CitySelectByCp = ({ setCodeCommune, setNomCommune, onCodePostalChange }) => {
  const [codePostal, setCodePostal] = useState('');
  const [villes, setVilles] = useState([]);
  const [selectDisabled, setSelectDisabled] = useState(true);
  const [loading, setLoading] = useState(false); // Ajoute un nouvel état pour le chargement
  const [requestInProcess, setRequestInProcess] = useState(null);


  
  const dispatch = useDispatch();

  const handleChangeCodePostal = (e) => {
    const newCodePostal = e.target.value;
    setCodePostal(newCodePostal);
    onCodePostalChange(newCodePostal);
    setSelectDisabled(true); // Désactive le select pendant la recherche

    if (newCodePostal.length === 5) {
      setRequestInProcess(true); // Démarre le chargement
      dispatch(fetchPostalCodeData(newCodePostal))
        .then(response => {
          
          setVilles(response.payload); // Supposant que la réponse est directement le tableau des villes
          setSelectDisabled(false); // Réactive le select si des villes sont trouvées
          setRequestInProcess(false); // Arrête le chargement
        })
        .catch(error => {
          console.error("Erreur lors de la récupération des villes:", error);
          setVilles([]); // Assurez-vous de gérer l'état des villes en cas d'erreur
          setRequestInProcess(false); // Arrête le chargement en cas d'erreur
        });
    } else {
      setVilles([]); // Assure une réinitialisation lorsque le code postal est invalide
      setRequestInProcess(false); // Arrête le chargement si le code postal est invalide
    }
  };

  const handleSelectVille = (e) => {
    const selectedVille = villes.find(ville => ville.nomCommune === e.target.value);
    setCodeCommune(selectedVille.codeCommune);
    setNomCommune(selectedVille.nomCommune);
  };

  return (
    <div className="citySelectContainer">
    
      <input
        type="text"
        value={codePostal}
        onChange={handleChangeCodePostal}
        placeholder="Entrez votre code postal"
        className='citySelectPostalCode'
      /> {requestInProcess && <div className="loaderPayment"></div>}
      {!requestInProcess && (<select
        className='citySelectCity'
        onChange={handleSelectVille}
        defaultValue=""
        disabled={selectDisabled || villes?.length === 0 || !codePostal}
      >
        <option value="" disabled>Choisissez votre ville</option>
        {villes?.length > 0 ? (
          villes?.map((ville, index) => (
            <option key={index} value={ville.nomCommune}>{ville.nomCommune}</option>
          ))
        ) : (
          <option value="" disabled>Aucun résultat</option>
        )}
      </select>)}
    </div>
  );
};

export default CitySelectByCp;

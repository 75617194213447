
import React from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

const MotherSelect = ({ idPrefix, animals, selectedAnimalId, currentAnimal, onAnimalChange, needsLabel, isEditMode, currentEditedAnimal }) => {

  const { t } = useTranslation();
  const handleAnimalChange = (selectedOption) => {
    onAnimalChange(selectedOption.value, selectedOption.label);
  };

  const animalOptions = [
    { value: 'Non défini', label: 'Non défini' },
    ...animals
      .filter(animal => animal.id !== currentEditedAnimal)
      .map(animal => ({ value: animal.id, label: animal.name }))
  ];

  return (
    <div style={{ maxWidth: '250px', height: '52px'}}>
      {needsLabel && (<label htmlFor={`${idPrefix}-animal`} style={{marginBottom: '1rem'}}>Sélectionner une mère</label>)}

        <Select
          id={`${idPrefix}-animal`}
          options={animalOptions}
          value={animalOptions.find(option => option.value === selectedAnimalId)}
          onChange={handleAnimalChange}
          placeholder="Choisir un animal"
          styles={customStyles}
          className={`select-update ${isEditMode ? 'select-visible' : 'select-hidden'}`}
        />
     
        <p className={`select-update ${isEditMode ? 'select-hidden mother-select-p-hidden' : 'select-visible mother-select-p-visible'}`}  style={{color: '#000'}}>{selectedAnimalId === currentEditedAnimal ? t('undefined') : (currentAnimal)}</p>
     
    </div>
  );
};

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'black',
    backgroundColor: state.isSelected ? '#2F2F2F' : 'white',
    '&:hover': {
      backgroundColor: state.isSelected ? '#2F2F2F' : 'lightgray',
    },

  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'black',
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: '2px',
  }),
};

export default MotherSelect;

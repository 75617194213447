import React from 'react';
import Select from 'react-select';
import { Tooltip } from 'react-tooltip';
import helpOutline from '../../assets/help-outline.svg';


const CityAndSectorSelect = ({ idPrefix, cities, selectedCityId, onCityChange, selectType }) => {
  const handleCityChange = (selectedOption) => {
    onCityChange(selectedOption.value, selectedOption.label);
  };

  // const cityOptions = cities.map(city => ({ value: city.id, label: city.name }));

  const cityOptions = [
    { value: 'Non défini', label: '(Par défaut) Inconnu' },
    ...cities
      .map(city => ({ value: city.id, label: city.name }))
  ];

  return (
    <span style={{ display: 'flex', flexDirection: 'row', columnGap: '1rem', alignItems: 'center', flexWrap: 'wrap', rowGap: '1rem' }}>
      <label htmlFor={`${idPrefix}-city`}>{selectType ? 'Sélectionner le sous-secteur (si connu)' : 'Sélectionner le secteur (si connu)'}</label>
      <Select
        id={`${idPrefix}-city`}
        options={cityOptions}
        value={cityOptions.find(option => option.value === selectedCityId)}
        onChange={handleCityChange}
        placeholder={selectType ? "Sous-secteur..." : "Choisir un secteur"}
        styles={customStyles}
      />
      {/* <img style={{ width: '14px', height: '14px', borderRadius: '50%', border: '1px solid black', background: '#122', marginBottom: '10px' }} src={helpOutline} alt="Icon Compass" className="button-icon" data-tooltip-id="my-tooltip-select-sector" /> */}

      {!selectType ? (
        <>
          <img style={{ width: '14px', height: '14px', borderRadius: '50%', border: '1px solid black', background: '#122', marginBottom: '10px' }} src={helpOutline} alt="Icon Compass" className="button-icon" data-tooltip-id="my-tooltip-select-sector" />
          <Tooltip id="my-tooltip-select-sector" style={{ backgroundColor: "#122", color: "#fff", padding: '1rem', maxWidth: '248px' }}>
            <div style={{ backgroundColor: '#122' }}>
              <h3>Secteur</h3>
              <p>Cette donnée peut être ajoutée ou modifiée ultérieurement.</p>
              <ul>
                <li>Le chat apparaîtra dans le canal global, mais aussi dans son secteur défini.</li>
                <li>Vous pouvez créer autant de secteurs que vous le désirez.</li>
              </ul>
            </div>
          </Tooltip>
        </>
      ) : (
        <>
          <img style={{ width: '14px', height: '14px', borderRadius: '50%', border: '1px solid black', background: '#122', marginBottom: '10px' }} src={helpOutline} alt="Icon Compass" className="button-icon" data-tooltip-id="my-tooltip-select-subsector" />
          <Tooltip id="my-tooltip-select-subsector" style={{ backgroundColor: "#122", color: "#fff", padding: '1rem' , maxWidth: '248px'}}>
            <div style={{ backgroundColor: '#122' }}>
              <h3>Sous-secteur</h3>
              <p>Cette donnée peut être ajoutée ou modifiée ultérieurement.</p>
              <ul>
                <li>Le chat apparaîtra dans le canal global, mais aussi dans son secteur défini, et son sous-secteur.</li>
                <li>Vous pouvez créer autant de secteurs et sous-secteurs que vous le désirez.</li>
              </ul>
            </div>
          </Tooltip>
        </>
)}

    </span>
  );
};


const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'black',
    backgroundColor: state.isSelected ? '#2F2F2F' : 'white',
    '&:hover': {
      backgroundColor: state.isSelected ? '#2F2F2F' : 'lightgray',
    },

  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'black',
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: '2px',
  }),
};

export default CityAndSectorSelect;

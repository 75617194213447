import axios from 'axios';

export const sendQuoteRequest = (animalCount, price, formData) => {
    // Préparation du payload avec les données du formulaire et les informations du devis
    const payload = {
        animalCount,
        price,
        name: formData?.name,
        email: formData?.email,
        phone: formData?.phone || 'Non fourni',
        company: formData?.company || 'Non fourni',
        street: formData?.street || 'Non fourni',
        country : formData?.country || 'Non fourni',
        postalCode: formData?.postalCode || 'Non fourni',
        city: formData?.city || 'Non fourni',
        comment: formData?.comment || 'Non fourni'
        
    };

    // Envoi de la requête POST avec Axios
    axios.post('https://us-central1-kappze.cloudfunctions.net/sendQuoteRequest', payload)
        .then(response => {
            if (response.status === 200) {
                // alert('Votre demande de devis a bien été envoyée.');
            }
        })
        .catch(error => {
            console.error('Erreur lors de l\'envoi de la demande de devis:', error);
            alert('Une erreur est survenue lors de l\'envoi de votre demande de devis.');
        });
};

import React from 'react';

function TableConsult() {
    const data = [
        ['', 'Consulter les paramètres du canal', 'Consulter le profil d\'un animal', 'Consulter les commentaires', 'Consulter les documents'],
        ['Créateur', 'X', 'X', 'X', 'X'],
        ['Administrateur', 'X', 'X', 'X', 'X'],
        ['Association', 'X', 'X', 'X', 'X'],
        ['Contributeur', 'X', 'X', 'X', 'X'],
        ['Invité', '', 'X', 'X', ''],

    ];

    return (
        <table border="1">
            <tbody>
                {data.map((row, rowIndex) => (
                    <tr key={rowIndex} className='tr-table'>
                        {row.map((cell, cellIndex) => (
                            <td key={cellIndex} className='td-table'>
                                {cell}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

export default TableConsult;

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getAuth, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';

const MySwal = withReactContent(Swal);

export const promptUserForReauthentication = async (user) => {
    const { value: password, isDismissed } = await MySwal.fire({
        title: 'Confirmer votre identité',
        input: 'password',
        inputLabel: 'Mot de passe',
        inputPlaceholder: 'Entrez votre mot de passe',
        inputAttributes: {
            'aria-label': 'Entrez votre mot de passe',
            autocomplete: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Valider',
        cancelButtonText: 'Annuler',
        confirmButtonColor: '#2F4F4F',
        reverseButtons: true,
        focusConfirm: false
    });

    if (isDismissed) {
        // L'utilisateur a annulé
        return false;
    }

    if (password) {
        const auth = getAuth();
        const credential = EmailAuthProvider.credential(user.email, password);
        try {
            await reauthenticateWithCredential(user, credential);
            return true;
        } catch (error) {
            MySwal.fire({
                icon: 'error',
                title: 'Réauthentification échouée',
                text: "Impossible de vous réauthentifier via ce mot de passe. Veuillez réessayer."
            });
            throw error;
        }
    } else {
        throw new Error('PASSWORD_NOT_PROVIDED');
    }
};

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const NewMembersGraph = ({ users, year }) => {
    const { t } = useTranslation();
    const [totalUsers, setTotalUsers] = useState();


    year = parseInt(year)
    const countUsers = () => {
        return users.filter(user => {
            const joiningDate = new Date(user?.joiningDate?.seconds * 1000);
            return joiningDate.getFullYear() === year; // Convertir l'année en chaîne
        }).length;


    };
    
    const newTotalUsers = countUsers();

    useEffect(() => {
        setTotalUsers(newTotalUsers);
    }, [year, users]); // Dépendances de l'effet
    

    return (
        <div className="signalements-graph">
            <div className="signalements-count">
                {newTotalUsers}
            </div>
            <div className="signalements-label">
                {t('graph.members.numberOfNewMembers')} {t('in')} {year}
            </div>
        </div>
    );
};

export default NewMembersGraph;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword, resetStatus, checkEmailExists } from '../../features/user/userSlice.js';
import { NavLink } from 'react-router-dom';
import { getErrorMsg } from '../../utils/errorMessages.js';
import logocat from '../../assets/transparent-without-circle.png';

const ForgotPasswordForm = () => {
    const [email, setEmail] = useState('');
    const [isEmailSent, setIsEmailSent] = useState(false);
    const dispatch = useDispatch();
    const { status, error: errorCode } = useSelector((state) => state.auth);
    const [errorMessage, setError] = useState('');

    useEffect(() => {
        if (errorCode) {
            setError(getErrorMsg(errorCode));
        } else {
            setError(null);
        }
        return () => {
            setError(null); // réinitialisez l'erreur lorsque le composant se démonte
        };

    }, [errorCode, setError]);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        dispatch(checkEmailExists(email))
            .then(() => {
                dispatch(resetPassword(email));
                setIsEmailSent(true);
            })
            .catch((errorCode) => {
                console.log(errorCode);
            });
    };

    const handleResetStatus = () => {
        dispatch(resetStatus());
        setIsEmailSent(false);
        setEmail('');
    };

    return (
        <div className='auth-page'>

            <div className="auth-section1">
                <div className="background-overlay"></div>
                <h1>Kappze</h1>
                <h2>Le 1er outil de suivi des chats errants</h2>
            </div>
            <div className="auth-section2">

                <h1 className='auth-section2-title'>Kappze</h1>
                <div>
                    <img src={logocat} alt="Logo" style={{ height: '200px', backgroundColor: 'none' }} />
                </div>
                {!isEmailSent ? (
                    <>
                        <h2 style={{ color: 'white', paddingBottom: '2rem' }}>Mot de passe oublié ?</h2>
                        <form onSubmit={handleSubmit} className='authForm  password-input'>
                            <div className='auth-input'>
                                <label htmlFor="email">Email</label>
                                <input type="email" id="email" value={email} onChange={handleEmailChange} />
                            </div>
                            <button type="submit" style={{ fontWeight: 'bold' }}>Envoyer l'email de réinitialisation</button>
                        </form>


                    </>
                ) : (
                    <>
                        {status === 'fulfilled' ? (
                            <>
                                <h1>Email envoyé</h1>
                                <p style={{ padding: "2rem", backgroundColor: 'white', color: 'black' }}>Un email de réinitialisation a été envoyé à l'adresse suivante : <span style={{ fontWeight: 'bold' }}>{email}</span></p>
                            </>
                        ) : status === 'loading' ? (
                            <><h1>Envoi en cours .. </h1></>
                        )

                            : (
                                <>
                                    <h1>Erreur lors de l'envoi de l'email</h1>
                                    <p>{errorMessage}</p>
                                    <button onClick={handleResetStatus}>Réessayer</button>
                                </>
                            )}
                    </>
                )}


                <div className="authForm-footer">
                    <p style={{ color: 'white' }}>
                        Retour à la page de <NavLink to="/login" style={{ color: 'white', fontWeight: 'bold' }}>connexion</NavLink>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ForgotPasswordForm;

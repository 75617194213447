import { unparse } from 'papaparse';
const { saveAs } = require('file-saver');

export const convertToCSV = (data) => {
    const csvData = data.map(animal => ({
        // Remplacez par vos champs spécifiques
        AppId: animal.id,
        Nom: animal.name,
        Couleurs: animal.colors,
        Secteur: animal.citySectorName,
        Stérilisé: animal.isSterilise ? 'oui' : 'non',
        'Est Identifié': animal.hasIdNumber ? 'oui' : 'non',
        'Numero d\' identification' : animal.idNumber,
        'Date d\'identification': animal.dateIdentifiaction,
        'A un propriétaire': animal.isBelonged ? 'oui' : 'none',
        Sexe: animal.sex,
        'Est une mère':  animal.isMother ? 'oui' : 'non',

        // etc...
    }));

    return unparse(csvData, {
        // Ajoutez vos en-têtes spécifiques
        header: true,
        fields: ['id', 'name', /* etc... */],
    });
};

export const downloadData = (data, filename = 'export_kappze.csv') => {
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, filename);
};


import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCitiesSector } from '../../features/citiesSector/citySectorSlice';
import { fetchAnimalsByCanal } from '../../features/animals/animalSlice';
import { fetchCanalUsers } from '../../features/canals/canalUsersSlice';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'animate.css';
import BackButton from '../../components/general/BackButton';
import LoadingPage from '../../components/general/LoadingPage';
import AnimalFilters from '../../components/animals/AnimalFilters';

const Signalements = () => {
    const { id } = useParams();
    const { data: canals } = useSelector((state) => state.canals);
    const { isAuthenticated, uid } = useSelector((state) => state.auth);
    const { data: users } = useSelector((state) => state.canalUsers);
    const [editableFields, setEditableFields] = useState([]);
    const [userRoles, setUserRoles] = useState({});
    const [canal, setCanal] = useState(canals.find((asso) => asso.id === id));
    const [updatedUsers, setUpdatedUsers] = useState([]);
    const [userIsAdmin, setUserRole] = useState({});
    const [isCopied, setIsCopied] = useState(false);
    const { data: animals } = useSelector((state) => state.animals)
    const unpublishedAnimals = animals.filter((animal) => animal.published === false);
    const archiveType = 'canal';
    const animalsStatus = useSelector((state) => state.animals.status);
    const canalsStatus = useSelector((state) => state.canals.status);

    const dispatch = useDispatch();
    useEffect(() => {
        if (isAuthenticated) {
            dispatch(fetchCitiesSector(id));
            dispatch(fetchAnimalsByCanal(id))
            dispatch(fetchCanalUsers(id));
        }
    }, [canal, dispatch, id, isAuthenticated]);

    useEffect(() => {
        if (canal && canal.id === id) {
            const fields = [
                { key: 'name', label: 'Nom', value: canal.name },
                { key: 'email', label: 'Email', value: canal.email },
                { key: 'city', label: 'Ville', value: canal.city },
                { key: 'postalCode', label: 'Code Postal', value: canal.postalCode },
            ];

            setEditableFields(fields);
        } else {
            // Animal not available or ID mismatch, reset editableFields
            setEditableFields([]);
        }
    }, [canal, dispatch])

    useEffect(() => {
        // Initialiser userRoles avec les rôles actuels des utilisateurs
        const initialRoles = {};
        users.forEach(user => {
            initialRoles[user.id] = user.isAdmin;
        });
        setUserRoles(initialRoles);
    }, [users]);

    useEffect(() => {
        const currentCanal = canals.find((asso) => asso.id === id);
        if (currentCanal !== canal) {
            setCanal(currentCanal);
        }
    }, [canals]);

    useEffect(() => {
        setUpdatedUsers(users);
    }, [users]);

    useEffect(() => {
        // Initialiser userRoles avec les rôles actuels des utilisateurs
        users.forEach(user => {

            if (user.id === uid) {
                setUserRole(user.isAdmin);
            }
        });


    }, [users]);


    if (animalsStatus === 'loading' || canalsStatus === 'loading') {
        return <LoadingPage isLoading={true} />;
    }

    return (
        <div className='kappze-page canal-page filtered-page'>
            <BackButton />
            <LoadingPage isLoading={false} />
            <div className="page-main">
                <div className="canal-page_content">
                    <div className="canal-page_content-title" style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem' }}>
                        <h1 className='page-title'>Signalements à modérer</h1>
                        <h1 className='page-title'>{canal.name}</h1>
                    </div>
                </div>

                <div className="canal-users">
                    {
                unpublishedAnimals.length ? (
                    <div className='section-animalFilters section-animalFilters_signalements'>
                        <AnimalFilters animals={unpublishedAnimals} archiveType={archiveType} />
                    </div>) : (<div className='no-animals'><p> Il n'y a pas d'animaux à modérer.</p>
                    </div>)
            }
                </div>
            </div>
        </div>
    );
};


export default Signalements;

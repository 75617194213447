export const getFilteredAnimals = (animals, selectedCity, selectedColor, selectedPattern, selectedHair, selectedRace, filterByDisease, filterBySterilization, filterByIdentification, filterByOwner, filterBySex, filterByMom, filterByName, filterById, filterByDeath, selectedSituation, selectedCanalId) => {
    return animals.filter((animal) => {
        // Filtre par secteur
        if (selectedCity && selectedCity !== 'Tous' && animal.citySectorName !== selectedCity) {
            return false;
        }

        if (selectedCanalId && selectedCanalId !== 'Tous' && animal.canalId !== selectedCanalId) {
            return false;
        }

        // Filtre par couleur
        if (selectedColor && selectedColor !== 'Toutes' && !animal.colors?.includes(selectedColor)) {
            return false;
        }

        // Filtre par motifs
        if (selectedPattern && selectedPattern !== 'Tous' && !animal.patterns?.includes(selectedPattern)) {
            return false;
        }

        // Filtre par situation
        if (selectedSituation && selectedSituation !== 'Toutes' && animal.situationCat !== selectedSituation) {
            return false;
        }

        // Filtre par poil
        if (selectedHair && selectedHair !== 'Tous' && !animal.hairs?.includes(selectedHair)) {
            return false;
        }

        // Filtre par rqce
        if (selectedRace && selectedRace !== 'Toutes' && !animal.races?.includes(selectedRace)) {
            return false;
        }
        // Filtre par maladie
        if (filterByDisease !== null && animal.isSick !== filterByDisease) {
            return false;
        }

        // Filtre par mort
        if (filterByDeath !== null && animal.death !== filterByDeath) {
            return false;
        }


        // Filtre par stérilisation
        if (filterBySterilization !== null && animal.isSterilise !== filterBySterilization) {
            return false;
        }

        // Filtre par identification
        if (filterByIdentification !== null && animal.hasIdNumber !== filterByIdentification) {
            return false;
        }

        // Filtre par propriétaire
        if (filterByOwner !== null && animal.isBelonged !== filterByOwner) {
            return false;
        }

        // Filtre par sexe
        if (filterBySex && filterBySex !== 'Tous' && !animal.sex.toLowerCase().includes(filterBySex.toLowerCase())) {
            return false;
        }

        // Filtre par mère
        if (filterByMom !== null && animal.isMother !== filterByMom) {
            return false;
        }

        // Filtre par nom
        if (filterByName && !animal.name.toLowerCase().includes(filterByName.toLowerCase())) {
            return false;
        }

        // Filtre par id
        if (filterById && !animal.idNumber.toLowerCase().includes(filterById.toLowerCase())) {
            return false;
        }

        return true;
    });
};

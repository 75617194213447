import React, { useEffect, useState } from 'react';
import logocat from '../../assets/kappze_logo_circle_noir_roigne.png';

const LoadingPage = ({ isLoading }) => {
    const [showLoading, setShowLoading] = useState(true);

    useEffect(() => {
        if (!isLoading) {
            // Si les données sont chargées avant la fin de l'animation, attendez la fin de l'animation pour cacher le composant
            setTimeout(() => {
                setShowLoading(false);
            }, 600); // 2 secondes pour correspondre à la durée de l'animation
        }
    }, [isLoading]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (!showLoading) {
        return null;
    }

    return (
        <div className="loading-container">
            <img src={logocat} alt="Loading logo" className="loading-logo" />
        </div>
    );
};

export default LoadingPage;

import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // utilise le localStorage par défaut
import authReducer from '../features/user/userSlice.js';
import licenceReducer from '../features/licences/licenceSlice.js';
import canalsReducer from '../features/canals/canalSlice.js';
import citiesReducer from '../features/citiesSector/citySectorSlice.js';
import subSectorsReducer from '../features/citiesSector/subSectorSlice.js';
import animalsReducer from '../features/animals/animalSlice.js';
import canalsUsersReducer from '../features/canals/canalUsersSlice.js';
import commentsReducer from '../features/animals/commentsSlice.js';
import privateCommentsReducer from '../features/animals/privateCommentsSlice.js';
import siretReducer from '../features/siret/siretSlice.js';
import notificationSlice from '../features/notifications/notificationSlice.jsx';
import associationSlice from '../features/association/associationSlice.js';
import userSlice from '../features/user/fetchingUserSlice.js';
import subscriptionListenerMiddleware from '../middleware/subscriptionListenerMiddleware.jsx';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'canals', 'citiesSector', 'subSectors', 'animals', 'canalUsers', 'comments', 'siret', 'notifications', 'licences', 'associations'], // seulement l'état 'auth' sera persisté, ajoutez d'autres clés si nécessaire
  };
  


  const rootReducer = (state, action) => {
    if (action.type === 'RESET_STORE') {
      state = undefined;  // Effectively resets all state
    }
  
    return combineReducers({
      auth: authReducer,
      licences: licenceReducer,
      canals: canalsReducer,
      citiesSector: citiesReducer,
      subSectors: subSectorsReducer,
      animals: animalsReducer,
      canalUsers: canalsUsersReducer,
      comments: commentsReducer,
      siret: siretReducer,
      notifications: notificationSlice,
      privateComments: privateCommentsReducer,
      associations: associationSlice,
      users: userSlice
    })(state, action);
  };

  
  
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  
export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(subscriptionListenerMiddleware), // Ajoutez le middleware ici
});

  
  export const persistor = persistStore(store);
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addCanal } from '../../features/canals/canalSlice';
import { fetchPostalCodeData } from '../../features/siret/siretSlice.js';
import BackButton from '../../components/general/BackButton';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import PostalCodeFetcher from '../../components/general/PostalCodeFetcher';


const CanalForm = () => {
    const { t } = useTranslation();
    const { uid } = useSelector((state) => state.auth);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [city, setCity] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [fetchedPostalCode, setCodePostal] = useState('');
    const [fetchedCities, setCities] = useState([]);
    const [codeCommune, setCodeCommune] = useState('');
    const [nomCommmune, setNomCommune ] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleCityChange = (event) => {
        setCity(event.target.value);
    };

    const handlePostalCodeChange = (event) => {
        setPostalCode(event.target.value);
    };

    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
    };

    const handleChangeCodePostal = (e) => {
        const newCodePostal = e.target.value;
        setCodePostal(newCodePostal);



        if (newCodePostal.length === 5) {
            dispatch(fetchPostalCodeData(newCodePostal))
                .then(response => {
                    setCities(response.payload); // Supposant que la réponse est directement le tableau des villes
                })
                .catch(error => {
                    console.error("Erreur lors de la récupération des villes:", error);
                });
        }
    };

    const handleSelectCity = (e) => {
        const selectedVille = fetchedCities.find(city => city.nomCommune === e.target.value);
        // console.log("Nom de la commune : ", selectedVille.nomCommune)
        // console.log("Code commune de la commune: ", selectedVille.codeCommune)
        setCodeCommune(selectedVille.codeCommune);
        setNomCommune(selectedVille.nomCommune);
      };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            adminId: uid,
            name: name,
            email: email,
            city: city,
            postalCode: postalCode,
            phoneNumber: phoneNumber,
            role: [{ uid: uid, isAdmin: true }],
            image: {},

        };
        dispatch(addCanal({ userId: uid, canalData: data }))
            .then(() => {
                Swal.fire({
                    icon: 'success',
                    title: `${t('modal.canalCreatedWithSuccessTitle')}`,
                    text: `${t('modal.canalCreatedWithSuccess')}`,
                    confirmButtonColor: '#2F4F4F',
                    confirmButtonText: 'OK',
                    showClass: {
                        popup: 'animate__animated animate__fadeIn'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOut'
                    }
                })
                navigate('/');
            })
            .catch((error) => {
                console.error('Error adding canal: ', error);
                Swal.fire({
                    icon: 'error',
                    title: `${t('modal.canalCannotBeCreatedTitle')}`,
                    text: `${t('modal.canalCannotBeCreated')} ${error}`,
                    confirmButtonColor: '#2F4F4F',
                    confirmButtonText: 'OK',
                    showClass: {
                        popup: 'animate__animated animate__fadeIn'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOut'
                    }
                })
            });
    };

    return (
        <div className='kappze-page kappze-paw'>
            <BackButton />
            <div className="kappze-form-page">
                <h1 style={{ color: 'white' }}>Ajouter un nouveau canal</h1>
                <p>Veillez à remplir le maximum de champ possible. Vous pourrez malgré tout modifier ces infos par la suite.</p>
                <form onSubmit={handleSubmit} className='add-asso-page_section'>

                    <div className='form-input-create'>
                        <label htmlFor="name">Nom:</label>
                        <input required type="text" id="name" value={name} onChange={handleNameChange} className='auth-input' />
                    </div>

                    <input
        type="text"
        value={fetchedPostalCode}
        onChange={handleChangeCodePostal}
        placeholder="Entrez votre code postal"
      />
      {fetchedCities?.length > 0 && (
        <select onChange={handleSelectCity} defaultValue="">
          <option value="" disabled>Choisissez votre ville</option>
          {fetchedCities.map((city, index) => (
            <option key={index} value={city.nomCommune}>{city.nomCommune}</option>
          ))}
        </select>
      )}
                    <div className='form-input-create'>
                        <label htmlFor="email">Email:</label>
                        <input required type="email" id="email" value={email} onChange={handleEmailChange} className='auth-input' />
                    </div>
                    <div className='form-input-create'>
                        <label htmlFor="city">Secteur:</label>
                        <input type="text" id="city" value={city} onChange={handleCityChange} className='auth-input' />
                    </div>
                    <div className='form-input-create'>
                        <label htmlFor="postalCode">Code postal:</label>
                        <input type="text" id="postalCode" value={postalCode} onChange={handlePostalCodeChange} className='auth-input' />
                    </div>
                    <div className='form-input-create'>
                        <label htmlFor="phoneNumber">Téléphone :</label>
                        <input type="text" id="phoneNumber" value={phoneNumber} onChange={handlePhoneNumberChange} className='auth-input' />
                    </div>
                    <button className="button-general" type="submit">Ajouter</button>
                </form>
            </div>
        </div>
    );
};

export default CanalForm;
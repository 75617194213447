import React, { useEffect } from 'react';
import { Element } from 'react-scroll';
import TableGeneral from './TableGeneral';
import TableAnimals from './TableAnimals';
import TableConsult from './TableConsult';
import kappzeCroquis from '../../assets/documentation/kappze-croquis-entite.png';
import kappzeCroquisAsso from '../../assets/documentation/kappze-croquis-asso.png';
import modalCreateCanal from '../../assets/documentation/add-canal.jpg';
import modalCreateSector from '../../assets/documentation/add-sector.jpg';
import createAnimal from '../../assets/documentation/doc-addcat.png';
import profileAnimal from '../../assets/documentation/doc-profilecat.png';
import genealogyAnimal from '../../assets/documentation/profile-cat-genealogy.jpg';
import filterRepresentation from '../../assets/documentation/doc-listingcat.png';
import listingCroquis from '../../assets/documentation/kappze-animals-croquis.png';
import editionSwitch from '../../assets/documentation/edit-mode.jpg';
import editionBtn from '../../assets/documentation/edit-btn.jpg';
import notificationMode from '../../assets/documentation/notification-mode.jpg';
import paymentBtns from '../../assets/documentation/votre-offre.jpg';
import signalAnimal from '../../assets/documentation/doc-signalcat.png';
import signalProfileAnimal from '../../assets/documentation/signalProfileAnimal.png';

const DocumentationContent = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="documentation-content">


            <Element name="section1" className="section">
                <p>Si vous avez une question précise, n'hésitez pas à nous écrire : <b>support@kappze.com</b></p>
                <h2>● Introduction</h2>
                <p>Kappze est une plateforme destinée aux collectivités et acteurs œuvrant pour la gestion des animaux errants.

                    Kappze a pour objectif de faciliter tous les processus concernant la gestion de chats errants. Ainsi, il peut être utilisé dans un but de collaboration, entre vétérinaires / associations-bénévoles / mairies / administrés.

                    Objectif : lutter contre la prolifération tout en accordant la protection, via une organisation encadrée.</p>
            </Element>

            <Element name="section2" className="section">
                <h2>● Inscription</h2>
                <p>Lors de votre inscription, il vous sera demandé si vous êtes une commune ou un particulier.
                    En tant qu'association ou bénévole, vous devez vous inscrire en tant que particulier. Si vous êtes une association, vous pouvez uiliser Kappze sans l'amont de votre commune en renseignant le siret de votre association.
                </p>

            </Element>

            <Element name="section3" className="section">
                <h2>● Rôles</h2>
                <p>Rôles généraux</p>
                <TableGeneral />

                <p>Rôles animaux</p>
                <TableAnimals />
                <p>Rôles consultation</p>
                <TableConsult />
            </Element>

            {/* <Element name="section4" className="section">
                <h2>● Général</h2>
                <p>Représentation graphique de la plateforme</p>
                <p>L'entité : </p>
                <img style={{ maxWidth: '500px' }} src={kappzeCroquis} />
                <p>L'association : </p>
                <img style={{ maxWidth: '500px' }} src={kappzeCroquisAsso} />
                <p>Une entité peut avoir plusieurs canaux, chacun de ces canaux peut avoir plusieurs secteurs.</p>
                <p>Pour créer un canal, il convient d'être pourvu d'une licence (pour une entité) ou d'un identifiant donné par l'entité (pour une association).</p>
                <p></p>

            </Element> */}

            <Element name="section5" className="section">
                <h2>● Canal</h2>
                <p>Un canal peut être :
                    Une commune, un arrondissement, ou autre.
                    Par exemple, des communautés de communes peuvent disposer de plusieurs communes (et donc répartis en canaux). Les grandes villes peuvent disposer de plusieurs arrondissements.
                    </p>

                <p>Création d'un canal : </p>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <p style={{ color: 'black' }}>Outre le nom du canal, ces informations permettront aux utilisateurs de votre Canal
                        de pouvoir vous contacter rapidement.</p>
                    <img style={{ maxWidth: '500px' }} src={modalCreateCanal} />
                </div>


                <p>Une fois créé, le canal sera disponible dans votre liste. Vous pouvez y accéder et commencer à renseigner vos données.</p>
                <p>Vous pouvez par exemple ajouter dès lors un secteur, ou un animal. Vous pourrez accéder, une fois un secteur ajouté, à l'onglet secteur.</p>

            </Element>

            <Element name="section6" className="section">
                <h2>● Secteur</h2>
                <p>Un secteur est un lieu. Vous pouvez disposer de plusieurs lieux dans une commune. Par exemple, si vous avez plusieurs colonies de chats, ou simplement si un chat appartient à un secteur en particulier. </p>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <p style={{ color: 'black' }}>Il vous suffit d'ajouter un nom pour créer un secteur. Il sera ensuite disponible dans votre liste et pourra être sélectionné lors de la création d'un animal, par exemple.</p>
                    <img style={{ maxWidth: '500px' }} src={modalCreateSector} />
                </div>
            </Element>

            <Element name="section7" className="section">
                <h2>● Animal</h2>
                <p>Vous pouvez ajouter autant de chats que vous le souhaitez. Chacun des chats dispose de sa propre fiche d'informations, laquelle est remplissable lors de sa création et modifiable par la suite. </p><br />


                <img style={{ maxWidth: '500px' }} src={createAnimal} />

                <p>En plus de ses informations personnelles, vous avez la possibilité d'ajouter des documents à la fiche du chat, de les consulter, mais également d'échanger avec d'autres utilisateurs via la section commentaires. Lorsque le chat est associé à une famille et à une mère préalablement renseignée, sa généalogie s'affichera automatiquement.</p>
                <p>Vous avez aussi la possibilité de télécharger le formulaire de traçabilité professionel pour gagner du temps.</p>
                <p>Profil d'un animal : </p>
                <img style={{ maxWidth: '500px' }} src={profileAnimal} />
                <p>Généalogie d'un animal : </p>
                <img style={{ maxWidth: '500px' }} src={genealogyAnimal} />

                <p>Chacun des animaux est cliquable, que ce soit dans le listing, ou dans la généalogie.</p>

                <p>Signalement d'un animal : </p>

                <img style={{ maxWidth: '500px' }} src={signalAnimal} />

                <p>Tous les invités ou habitants peuvent signaler un chat. Une fois le signalement rempli, l'animal n'est accessible
                    qu'à la modération. Elle décidera ensuite de si le signalement peut-être publié ou non.</p>

                <p>Modération d'un animal : </p>

                <img style={{ maxWidth: '500px' }} src={signalProfileAnimal} />

                <p>Après avoir eu accès au listing des animaux à modérer, le profil d'un animal se présentera sous cette forme pour les Administrateurs.
                    Vous pouvez modifier le signalement comme si vous modifiez un chat.
                    Tant qu'il n'est pas publié, il n'apparaîtra pas dans les listings. Si vous décidez de supprimer le signalement, le choix sera définitif.</p>

            </Element>

            <Element name="section8" className="section">
                <h2>● Listing</h2>
                <p>Les listes sont un moyen de retrouver tous vos animaux et de les filtrer en conséquence.
                    Tous vos animaux appartiennent à un canal, mais également à un secteur.. </p>

                <img style={{ maxWidth: '500px' }} src={listingCroquis} />
                <p>
                    Il existe quatre différents types de listings : </p>
                <ul>
                    <li>
                        Listing de canal : Regroupe tous les animaux du canal. Vous avez la possibilité de filtrer par secteur.
                    </li>
                    <li>
                        Listing de secteur : Regroupe tous les animaux du secteur auquel vous êtes rattaché.
                    </li>
                    <li>
                        Listing de liaison : Regroupe tous les animaux uniquement lié à celui sur lequel vous êtes. Tout comme le listing de canal, vous avez la possibilité de filrer par secteur.
                    </li>
                    <li>
                        Listing d'association : Regroupe tous les animaux uniquement lié à une association. Vous avez la possibilité de filtrer par canal.
                    </li>
                </ul>
                <p>Chacunes des cartes regroupent les informations rapides, et sont cliquables pour amener directement sur la page de l'animal.</p>
                <img style={{ maxWidth: '500px' }} src={filterRepresentation} />
            </Element>

            <Element name="section9" className="section">
                <h2>● Mode édition</h2>
                <p>En tant qu'Administrateur ou Association (contributeur inclus), vous avez accès à des outils d'éditions. En tant que particulier, le mode édition est uniquement disponible pour vos propres paramètres de profil.</p>
                <p>Le mode édition est représenté par un interrupteur (switch), permettant d'activer la modification. Une confirmation des données éditées vous sera systématiquement affichée en cas de modification.</p>

                <img style={{ maxWidth: '500px' }} src={editionSwitch} />
                <p>Pour modifier un élément en particulier il vous suffira de cliquer sur l'élément suivant : </p>
                <img style={{ maxWidth: '500px' }} src={editionBtn} />
            </Element>

            <Element name="section10" className="section">
                <h2>● Notifications</h2>
                <p>Kappze propose un système de notifications : </p>
                <p>Pour les administrateurs : </p><ul>
                    <li>Ajout d'un chat</li>
                    <li>Signalement d'un chat</li>
                    <li>Signalement validé d'un chat</li>
                    <li>Nouveau commentaire publique</li>
                    <li>Nouveau commentaire privé</li>
                    <li>Nouveau document</li>
                </ul>
                <p>Si vous avez installé l'application MyKappze, vous recevrez les notifications directement sur votre téléphone. Sinon, elles vous seront envoyées par e-mail à l'adresse avec laquelle vous vous êtes inscrit. Vous avez la possibilité d'activer ou de désactiver ces notifications à partir de vos paramètres de profil.
                    Cette option est disponible pour les administrateurs de canaux.</p>
                <img style={{ maxWidth: '500px' }} src={notificationMode} />
            </Element>

            <Element name="section11" className="section">
                <h2>● Paiements</h2>
                <p>Comme vous le savez, Kappze nécessite des ressources pour la maintenance et le personnel. L'objectif principal de Kappze est de faciliter le travail de chaque partie prenante. C'est pourquoi Kappze propose un abonnement Kappze+, afin de permettre à tous de travailler dans les meilleures conditions possibles.
                    La licence est valable pour une année. En cas de non-renouvellement, tous les avantages seront retirés. Pour le moment, Kappze accepte uniquement les paiements par carte bancaire.
                    Sur votre page de profil, vous avez accès à trois boutons : "Acheter / Renouveler","Vos derniers paiements", "Annuler mon abonnement".
                    Sur la page de vos derniers paiements, vous avez accès à vos reçu et vos factures.
                </p>

                <img style={{ maxWidth: '500px' }} src={paymentBtns} />

            </Element>

            <Element name="section12" className='section'>
                <h2>● Kappze+</h2>
                <p>Il existe des offres au mois, et à l'année. Le renouvellement se fait à date d'anniversaire. En cas de non-renouvellement, si votre nombre d'animaux est supérieur à 30, vos animaux seront en lecture seule uniquement.</p>
                <p>La version gratuite de Kappze pour les créateurs de canaux est limitée à 30 animaux (canaux dont vous êtes le créateur uniquement).</p>
                <p>Vous pouvez annuler votre abonnement à tous moment via votre page de profil. Vous garderez les avantages jusqu'à la date anniversaire.</p>

            </Element>
        </div>
    );
}

export default DocumentationContent;

import React, { Component, useEffect } from 'react';
import logocat from '../../assets/kappze_logo_circle_noir_roigne.png';
import { NavLink } from 'react-router-dom';
import Donation from './Donation';
import StripeButton from './Donation';

class Footer extends Component {


    render() {



        // Function will execute on click of button 
        const downloadCGV = () => {
            // using Java Script method to get PDF file 
            fetch('CGV.pdf', { method: 'GET' }).then(response => {
                response.blob().then(blob => {
                    // Creating new object of PDF file 
                    const fileURL = window.URL.createObjectURL(blob);
                    // Setting various property values 
                    let alink = document.createElement('a');
                    alink.href = fileURL;
                    alink.download = 'CGV.pdf';
                    alink.click();
                })
            })
        }

        const downloadMentionsLegales = () => {
            // using Java Script method to get PDF file 
            fetch('Mentionslegales.pdf').then(response => {
                response.blob().then(blob => {
                    // Creating new object of PDF file 
                    const fileURL = window.URL.createObjectURL(blob);
                    // Setting various property values 
                    let alink = document.createElement('a');
                    alink.href = fileURL;
                    alink.download = 'Mentionslegales.pdf';
                    alink.click();
                })
            })
        }

        return (
            <footer style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.1)', padding: '16px' }}>
                <div>
                    <img src={logocat} alt="Logo" style={{ height: '90px' }} />
                </div>

                {/* <StripeButton /> */}

                <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', columnGap: '1rem' }}>
                    <a href="https://kappze.com" target="_blank" rel="noreferrer" style={{ color: '#000' }} className="footer-elt"><b>Kappze.com</b></a>
                    <p style={{ color: '#000' }} className="footer-elt"><b>Copyright @ Kappze - {new Date().getFullYear()}</b></p>
                    <NavLink to={`/documentation/`} style={{ color: '#000' }} className="footer-elt">
                        <b>Aide</b>
                    </NavLink>
                    <a style={{ color: '#000', textDecoration: 'underline' }} className="footer-elt" href="mailto:support@kappze.com"><b>support@kappze.com</b></a>
                    <a style={{ color: '#000' }} onClick={downloadMentionsLegales} className="footer-elt">
                        <b>Mentions légales</b>
                    </a>
                    <a style={{ color: '#000' }} className="footer-elt" onClick={downloadCGV}>
                        <b>CGV</b>
                    </a>
                </div>
            </footer>
        );
    }
}

export default Footer;
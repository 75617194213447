// export const COLORS = {
//     "noir": "Noir",
//     "noire": "Noir",
//     "bleuGris": "Bleu / Gris",
//     "chocolat": "Chocolat",
//     "cannelle": "Cannelle",
//     "fauve": "Fauve",
//     "roux": "Roux",
//     "rousse": "Roux",
//     "creme": "Crème",
//     "blanc": "Blanc",
//     "blanche": "Blanc",
// };
export const COLORS = {
    noir: { name: "Noir", imagePath: "/catsSelect/cat-black.jpg" },
    bleuGris: { name: "Bleu-Gris", imagePath: "/catsSelect/cat-grey-blue.jpg" },
    chocolat: { name: "Chocolat", imagePath: "/catsSelect/cat-brown.jpeg" },
    cannelle: { name: "Cannelle", imagePath: "/catsSelect/cat-cinnamon.jpg" },
    fauve: { name: "Fauve", imagePath: "/catsSelect/cat-fawn.jpg" },
    roux: { name: "Roux", imagePath: "/catsSelect/cat-orange.jpg" },
    creme: { name: "Crème", imagePath: "/catsSelect/cat-cream.jpeg" },
    blanc: { name: "Blanc", imagePath: "/catsSelect/cat-white.jpg" },
};

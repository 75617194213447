import React, { useState, useEffect } from 'react';
import AnimalCard from './AnimalCard';
import ChevronLeft from '../../assets/chevron-back-outline.svg'
import ChevronRight from '../../assets/chevron-forward-outline.svg'
import CatNotFound from '../../assets/cat-not-found.png'

const AnimalList = ({ animals, status, error, isAdmin, sectorsEnabled }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(8); // Nombre d'éléments à afficher par page

    const [filteredAnimalCount, setFilteredAnimalCount] = useState(0);
    useEffect(() => {
        // Update the filtered animal count when animals prop changes
        setFilteredAnimalCount(animals.length);
        setCurrentPage(1)
    }, [animals]);

    // Index du premier et du dernier élément de la page actuelle
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = animals.slice(indexOfFirstItem, indexOfLastItem);


    const paginate = (pageNumber) => {
        // Check if the number of filtered animals has changed
        if (filteredAnimalCount !== animals.length) {
            setCurrentPage(1); // Reset pagination to the first page
            setFilteredAnimalCount(animals.length); // Update the filtered animal count
        } else {
            setCurrentPage(pageNumber);
        }
    };

    return (
        <>

            {status === 'loading' ? (
                <p>Loading...</p>
            ) : status === 'failed' ? (
                <p>Error: {error}</p>
            ) : currentItems.length === 0 ? (
                <div className="no-result-found">
                    <div className="no-result-found-container">
                        <div className="no-result-found-text">
                            <p>Les filtres ne font part d'aucun chat.</p>
                            <img src={CatNotFound} alt="" className="logos-result" />
                        </div>
                        <div className="no-result-found-logos">

                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className='list cards'>
                        {currentItems.map((animal) => (
                            <AnimalCard key={animal.id} animal={animal} isAdmin={isAdmin} sectorsEnabled={sectorsEnabled}/>
                        ))}
                    </div>
                    <div className='pagination'>
                        {animals.length > itemsPerPage && (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <button className='paginationButton' onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
                                    <img src={ChevronLeft} alt="Précédent" style={{ width: '20px' }} />
                                </button>
                                <span className='paginationText' style={{ color: '#ddd' }}>
                                    {currentPage} / {Math.ceil(animals.length / itemsPerPage)}
                                </span>
                                <button className='paginationButton' onClick={() => paginate(currentPage + 1)} disabled={currentPage === Math.ceil(animals.length / itemsPerPage)}>
                                    <img src={ChevronRight} alt="Suivant" style={{ width: '20px' }} />
                                </button>
                            </div>
                        )}
                    </div>
                </>
            )}
        </>
    );

};

export default AnimalList;


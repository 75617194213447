import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import d3LocaleFr from '../../data/fr-FR.json';
import d3LocaleEn from '../../data/en-EN.json';
import { useTranslation } from 'react-i18next';

const SignalementsCurvesGraph = ({ animals, year }) => {
    const d3Container = useRef(null);
    const { t } = useTranslation();
    const currentLanguage = t('currentLanguage') ; // ou t('currentLanguage') si vous stockez la langue actuelle dans vos ressources i18n

    const d3Locale = currentLanguage === 'fr' ? d3.timeFormatLocale(d3LocaleFr) : d3.timeFormatLocale(d3LocaleEn);

    useEffect(() => {
        d3.select(d3Container.current).selectAll("*").remove();
        if (animals && d3Container.current) {

            const previousYear = (parseInt(year) - 1).toString();

            

            // Compter les signalements par mois pour l'année courante
            const signalementsCounts = Array.from({ length: 12 }, (_, i) => {


                return animals.filter(animal =>
                    animal?.addedDate?.startsWith(`${year}-${String(i + 1).padStart(2, '0')}`) &&
                    animal?.signal === true &&
                    animal?.published === true &&
                    animal?.signalByOwner === false
                ).length;
            });

            // Compter les signalements par mois pour l'année précédente
            const signalementsCountsPreviousYear = Array.from({ length: 12 }, (_, i) => {
                return animals.filter(animal =>
                    animal?.addedDate?.startsWith(`${previousYear}-${String(i + 1).padStart(2, '0')}`) &&
                    animal?.signal === true &&
                    animal?.published === true &&
                    animal?.signalByOwner === false
                ).length;
            });

            // Configuration de base pour le graphique D3
            const margin = { top: 20, right: 30, bottom: 140, left: 90 };
            const width = 700 - margin.left - margin.right;
            const height = 470 - margin.top - margin.bottom;

            const svg = d3.select(d3Container.current)
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .append('g')
                .attr('transform', `translate(${margin.left},${margin.top})`);

            // Créer l'échelle X
            const x = d3.scaleTime()
                .domain([new Date(year, 0, 1), new Date(year, 11, 31)])
                .range([0, width]);

            svg.append('g')
                .attr('transform', `translate(0,${height})`)
                .call(d3.axisBottom(x).tickFormat(d3Locale.format("%B")))
                .style("color", "black");

            // Créer l'échelle Y
            const maxY = Math.max(d3.max(signalementsCounts), d3.max(signalementsCountsPreviousYear));
            const y = d3.scaleLinear()
                .domain([0, maxY])
                .range([height, 0]);

            svg.append("g")
                .call(d3.axisLeft(y))
                .style("stroke", "black");

            // Fonction de tracé des lignes
            const line = d3.line()
                .x((_, i) => x(new Date(year, i)))
                .y(d => y(d))
                .curve(d3.curveMonotoneX);
            // Dessiner les courbes
            svg.append('path')
                .data([signalementsCounts])
                .attr('fill', 'none')
                .attr('stroke', '#122')
                .attr('stroke-width', 2)
                .attr('d', line);

            svg.append('path')
                .data([signalementsCountsPreviousYear])
                .attr('fill', 'none')
                .attr('stroke', 'green')
                .attr('stroke-width', 2)
                .attr('d', line);

            // Ajouter des points sur les courbes
            ['color1', 'color2'].forEach((colorClass, index) => {
                const data = index === 0 ? signalementsCounts : signalementsCountsPreviousYear;
                svg.selectAll(`circle.${colorClass}`)
                    .data(data)
                    .enter()
                    .append('circle')
                    .attr('class', colorClass)
                    .attr('fill', index === 0 ? '#122' : 'green')
                    .attr('cx', (_, i) => x(new Date(year, i)))
                    .attr('cy', d => y(d))
                    .attr('r', 3);
            });

            // Ajouter la légende de l'axe X
            svg.append('text')
                .attr('transform', `translate(${width / 2}, ${height + margin.top + 40})`)
                .style('text-anchor', 'middle')
                .text(`${t('graph.reports.monthsOfTheYear')}`)
                .style('fill', 'black')
                .style('font-family', 'Wix Madefor Display');

            // Ajouter la légende de l'axe Y
            svg.append('text')
                .attr('transform', 'rotate(-90)')
                .attr('y', 0 - margin.left + 20)
                .attr('x', 0 - (height / 2))
                .attr('dy', '1em')
                .style('text-anchor', 'middle')
                .text(`${t('graph.reports.numberOfReports')}`)
                .style('fill', 'black')
                .style('font-family', 'Wix Madefor Display');

            // Ajouter la légende des couleurs
            const legend = svg.selectAll('.legend')
                .data([{ color: '#122', text: `${t('graph.reports.reports')}` + ' ' + year }, { color: 'green', text: `${t('graph.reports.reports')}` + ' ' + previousYear }])
                .enter().append('g')
                .attr('class', 'legend')
                .attr('transform', (d, i) => `translate(0, ${i * 20})`)
                .style('font-family', 'Wix Madefor Display');

                const barLegend = svg.append("g")
                .attr("class", "bar-legend")
                .attr("transform", `translate(${width - 150}, 20)`)// Ajuster la position si nécessaire

                barLegend.append("text")
                .attr("x", -490)
                .attr("y", 390)
                .attr("dy", ".35em")
                .text(`${t('graph.reports.reportsCheckedByAnAdmin')}`)
                .style("font-size", "12px")
                .style("fill", "black")
                .style('font-family', 'Wix Madefor Display')

            legend.append('rect')
                .attr('x', width - 18)
                .attr("y", 380)
                .attr('width', 18)
                .attr('height', 18)
                .style('fill', d => d.color);

            legend.append('text')
                .attr('x', width - 24)
                .attr("y", 390)
                .attr('dy', '.35em')
                .style("font-size", "12px")
                .style('text-anchor', 'end')
                .text(d => d.text)
                .style('fill', 'black');

        }
    }, [animals, year, currentLanguage]);

    return <svg ref={d3Container}></svg>;
};

export default SignalementsCurvesGraph;

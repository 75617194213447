import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { joinCanal } from '../../features/canals/canalSlice';
import BackButton from '../../components/general/BackButton';

const getCurrentFormattedDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};



const JoinCanal = () => {

    const [canalId, setCanalId] = useState('');
    const { uid } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const handleJoinCanal = async () => {
        if (!canalId) {
            alert('Veuillez entrer un ID d\'canal');
            return;
        }

        const joiningDate = getCurrentFormattedDate();
        const resultAction = await dispatch(joinCanal({ userId: uid, canalId, joiningDate: joiningDate }));

        if (resultAction.success) {
            alert('Canal rejoint avec succès !');
            setCanalId('');
        } else {
            if (resultAction.payload) {
                // Si une erreur personnalisée a été rejetée avec `rejectWithValue`
                alert(`Erreur lors de la jointure du canal : ${resultAction.payload}`);

            } else {
                // Juste pour être sûr, affichez également une erreur générique
                alert(`Erreur lors de la jointure du canal : ${resultAction.error.message}`);
            }
        }
    };


    return (
        <>
        <div className='kappze-page'>

        <div className="background-asso" style={{background: '#26242d'}}>
        <BackButton needsBackground={false} />
        </div>
            <div className="join-asso-page_section auth-page" style={{ padding: '3rem' }}>
            <h2>Rejoindre une canal : </h2>
                <input required
                    type="text"
                    value={canalId}
                    onChange={(e) => setCanalId(e.target.value)}
                    placeholder="ID du canal"
                />
                <button style={{marginLeft: '0'}} onClick={handleJoinCanal} >Rejoindre</button>
            </div>

        </div>
        </>
    );
};

export default JoinCanal;

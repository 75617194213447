import React from 'react';
import { useTranslation } from 'react-i18next';

const AddedSectorsGraph = ({ sectors, year }) => {

    const previousYear = (parseInt(year) - 1).toString();
    const { t } = useTranslation();
    const countSectors = () => {
        return sectors.filter(citySector => {
            if (!citySector || typeof citySector?.addedDate !== 'string') {
                console.warn('Invalid citySector or addedDate:', citySector);
                return false;
            }
            return citySector?.addedDate?.startsWith(year);
        }).length;
    };

    const totalSectors = countSectors();

    const countSectorsLastYear = () => {
        const previousYear = (new Date().getFullYear() - 1).toString(); // Ensure previousYear is a string
        return sectors.filter(citySector => {
            if (!citySector || typeof citySector?.addedDate !== 'string') {
                console.warn('Invalid citySector or addedDate:', citySector);
                return false;
            }
            return citySector?.addedDate?.startsWith(previousYear);
        }).length;
    };
    
    const totalSectorsLastYear = countSectorsLastYear();
    const calculatePercentageDifference = (current, previous) => {
        if (previous === 0) return 'N/A'; // Pour éviter la division par zéro
        let difference = (((current - previous) / previous) * 100).toFixed(2);
        let sign = difference >= 0 ? '+' : ''; // Ajoute un '+' si la différence est positive ou zéro
        return `${sign}${difference}%`;
    };

    const percentageTotal = calculatePercentageDifference(totalSectors, totalSectorsLastYear);


    return (
        <div className="signalements-graph">




            <div style={{ display: 'flex', flexDirection: 'row', columnGap: '1rem' }}>
                <div style={{ textAlign: 'center' }}>
                    <div className="signalements-count">
                        {totalSectors}
                    </div>
                    <div className="signalements-label">
                        {t('graph.sectors.numberOfSectorsAdded')} {t('in')} {year}
                    </div>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <div className="signalements-count">
                        {percentageTotal}
                    </div>
                    <div className="signalements-label">
                        {totalSectorsLastYear} {t('graph.sectors.sectorsAdded')} {t('in')} {previousYear}
                    </div>
                </div>

            </div>

        </div>
    );
};

export default AddedSectorsGraph;

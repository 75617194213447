import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { addCitySector } from '../../features/citiesSector/citySectorSlice';


const AddCitySectorForm = (props) => {
    const { uid } = useSelector((state) => state.auth);
    const [name, setName] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            userId: uid,
            name: name,
            canalId: props.id
        };
        dispatch(addCitySector(data))
            .then(() => {

                if (props.needsReturn) {
                    navigate(-1, { from: navigate.location });

                } else {
                    props.onClose();
                }
            })
            .catch((error) => {
                console.error('Error adding canal: ', error);
            });
    };

    const handleCancel = () => {
        if (props.needsReturn) {
            navigate(-1, { from: navigate.location });
        } else {
            props.onClose();
        }
    };

    return (
        <div style={{ padding: '2rem' }}>
            <h2 style={{ color: 'white', paddingBottom: '1rem' }}>Ajouter un nouveau secteur à votre canal</h2>
            <label htmlFor="name">Nom du secteur : </label>
            <input required type="text" id="name" value={name} onChange={handleNameChange} className='auth-input' />
            <div className="btns-modify-supp">
                <button className="btn-modify" type="submit" onClick={handleSubmit}>Ajouter le secteur</button>
                <button className="btn-supp" onClick={handleCancel}>Annuler</button>
            </div>

        </div>
    );
};

export default AddCitySectorForm;
import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import d3LocaleFr from '../../data/fr-FR.json'; // Assurez-vous d'avoir ce fichier ou le module correspondant
import d3LocaleEn from '../../data/en-EN.json';
import { useTranslation } from 'react-i18next';

const CapturesGraph = ({ animals, year }) => {
    const d3Container = useRef(null);
    const { t } = useTranslation();

    const currentLanguage = t('currentLanguage'); // ou t('currentLanguage') si vous stockez la langue actuelle dans vos ressources i18n

    const d3Locale = currentLanguage === 'fr' ? d3.timeFormatLocale(d3LocaleFr) : d3.timeFormatLocale(d3LocaleEn);
    const formatMonth = d3Locale.format("%B");

    useEffect(() => {

        d3.select(d3Container.current).selectAll("*").remove();
        if (animals && d3Container.current) {
            // Filtrer les animaux et compter les captures par mois
            const capturesByMonth = Array.from({ length: 12 }, () => 0);



            animals.forEach(animal => {
                if (animal?.isTrapped && animal?.trappedDate?.startsWith(year)) {
                    const date = new Date(animal?.trappedDate);
                    const month = date.getMonth();
                    capturesByMonth[month]++;
                }
            });


            // Configuration de base pour le graphique D3
            const margin = { top: 20, right: 30, bottom: 140, left: 90 };
            const width = 700 - margin.left - margin.right;
            const height = 470 - margin.top - margin.bottom;

            // Créer un SVG
            const svg = d3.select(d3Container.current)
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .append("g")
                .attr("transform", `translate(${margin.left},${margin.top})`)
                .style('fill', 'black') // Couleur du texte en blanc


            // Créer l'échelle X
            const x = d3.scaleBand()
                .range([0, width])
                .domain(capturesByMonth.map((_, i) => formatMonth(new Date(year, i)))).padding(0.1)

            svg.append("g")
                .attr("transform", `translate(0,${height})`)
                .call(d3.axisBottom(x))
                .selectAll("text")
                .attr("transform", "translate(-10,0)rotate(-45)")
                .style("text-anchor", "end")
                .style('fill', 'black')
                .style('font-family', 'Wix Madefor Display')

            // Créer l'échelle Y
            const y = d3.scaleLinear()
                .domain([0, d3.max(capturesByMonth)])
                .range([height, 0])


            // Ajouter l'axe Y
            const yAxis = svg.append("g")
                .call(d3.axisLeft(y))
                .style("stroke", "black");

            // Changer la couleur des lignes (ticks) de l'axe Y
            yAxis.selectAll(".tick line")
                .style("stroke", "black");

            // Changer la couleur du chemin de l'axe Y
            yAxis.select(".domain")
                .style("stroke", "black")

            // Créer et ajouter l'axe X
            const xAxis = svg.append("g")
                .attr("transform", `translate(0,${height})`)
                .call(d3.axisBottom(x));

            // Changer la couleur de la ligne de l'axe X en noir
            xAxis.select(".domain")
                .style("stroke", "black");

            // Changer la couleur du texte de l'axe X en noir
            xAxis.selectAll(".tick text")
                .style("fill", "transparent")
                .style('font-family', 'Wix Madefor Display')


            // svg.selectAll("mybar")
            //     .data(capturesByMonth)
            //     .join("rect")
            //     .attr("x", (_, i) => x(formatMonth(new Date(year, i))))
            //     .attr("y", d => y(d))
            //     .attr("width", x.bandwidth())
            //     .attr("height", d => height - y(d))
            //     .attr("fill", "#69b3a2");


            // svg.selectAll("text.bar")
            //     .data(capturesByMonth)
            //     .join("text")
            //     .attr("class", "bar")
            //     .attr("text-anchor", "middle")
            //     .attr("x", (_, i) => x(formatMonth(new Date(year, i))) + x.bandwidth() / 2)
            //     .attr("y", d => y(d) + 20)
            //     .text(d => d === 0 ? '' : d)
            //     .style("fill", "white")
            //     .style('font-family', 'Wix Madefor Display');

            svg.selectAll(".mybar")
    .data(capturesByMonth.filter(d => d > 0)) // Filtrer les données pour exclure les valeurs 0
    .join("rect")
    .attr("x", (_, i) => x(formatMonth(new Date(year, i))))
    .attr("y", d => y(d))
    .attr("width", x.bandwidth())
    .attr("height", d => height - y(d))
    .attr("fill", "#69b3a2");

svg.selectAll(".textbar")
    .data(capturesByMonth.filter(d => d > 0)) // Appliquer le même filtre pour les textes
    .join("text")
    .attr("class", "bar")
    .attr("text-anchor", "middle")
    .attr("x", (_, i) => x(formatMonth(new Date(year, i))) + x.bandwidth() / 2)
    .attr("y", d => y(d) - 5) // Ajustez cette valeur si nécessaire pour positionner correctement le texte au-dessus de la barre
    .text(d => d)
    .style("fill", "white")
    .style('font-family', 'Wix Madefor Display');



            // Ajouter la légende de l'axe X
            svg.append('text')
                .attr('transform', `translate(${width / 2}, ${height + margin.top + 40})`)
                .style('text-anchor', 'middle')
                .text(`${t('graph.captures.monthsOfTheYear')}`)
                .style('fill', 'black') // Couleur du texte en blanc
                .style('font-family', 'Wix Madefor Display')

            // Ajouter la légende de l'axe Y
            svg.append('text')
                .attr('transform', 'rotate(-90)')
                .attr('y', 0 - margin.left + 20)
                .attr('x', 0 - (height / 2))
                .attr('dy', '1em')
                .style('text-anchor', 'middle')
                .text(`${t('graph.captures.numberOfAnimals')}`)
                .style('fill', 'black') // Couleur du texte en blanc
                .style('font-family', 'Wix Madefor Display')


            // Ajouter la légende des barres
            const barLegend = svg.append("g")
                .attr("class", "bar-legend")
                .attr("transform", `translate(${width - 150}, 20)`)// Ajuster la position si nécessaire

            barLegend.append("rect")
                .attr("x", 0)
                .attr("y", 380)
                .attr("width", 18)
                .attr("height", 18)
                .style("fill", "#69b3a2")
                .style('border-radius', '5px')

            barLegend.append("text")
                .attr("x", 24)
                .attr("y", 390)
                .attr("dy", ".35em")
                .text(`${t('graph.captures.numberOfCaptures')}`)
                .style("font-size", "12px")
                .style("fill", "black")
                .style('font-family', 'Wix Madefor Display')
        }



    }, [animals, year, currentLanguage]);

    return (
        <svg ref={d3Container} id="svg-1"></svg>
    );
};

export default CapturesGraph;
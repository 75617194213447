import { useEffect } from 'react';
import Sidebar from '../components/documentation/Sidebar.jsx';
import DocumentationContent from '../components/documentation/DocumentationContent.jsx';
import DocumentationContentMobile from '../components/documentation/DocumentationContentMobile.jsx';

const Documentation = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    
    return (
        <div className="documentation-container">
        
            <Sidebar className="sidebar"/>
            <DocumentationContent className="documentation-content"/>
            <DocumentationContentMobile />
        </div>
    );

}

export default Documentation;
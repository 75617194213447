import React from 'react';
import { getAuth, sendEmailVerification } from 'firebase/auth';

const ResendVerificationEmail = () => {
  const handleResendEmail = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      try {
        await sendEmailVerification(user);
        alert('Email de vérification renvoyé ! Vérifiez votre boîte mail.');
      } catch (error) {
        console.error('Erreur lors de l\'envoi de l\'email de vérification :', error);
        alert('Erreur lors de l\'envoi de l\'email. Veuillez réessayer plus tard.');
      }
    }
  };

  return (
    <div className="container">
      <p>Vous n'avez pas encore validé votre email. N'oubliez pas de vérifier dans vos spams.</p>
      <button onClick={handleResendEmail}>Renvoyer l'email de vérification</button>
    </div>
  );
};

export default ResendVerificationEmail;

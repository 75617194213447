import React from 'react';
import { useSelector } from 'react-redux';
import { PaymentsScreen } from "./Payments.jsx";
import BackButton from '../components/general/BackButton';


const Invoices = () => {
    const stripeCustomerId = useSelector((state) => state.auth.stripeCustomerId);

    return (
        <div className='kappze-page'>
        <BackButton />
        <PaymentsScreen  userCustomerId={stripeCustomerId} />
        </div>
    );
};

export default Invoices;

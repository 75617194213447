import React, { useEffect, useRef, useMemo } from 'react';
import * as d3 from 'd3';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const PieChart = () => {
    const { data: animals } = useSelector((state) => state.animals);
    const d3Container = useRef(null);
    const { t } = useTranslation();


    const pieData = useMemo(() => {
        const countsBySector = animals.reduce((acc, animal) => {
            const { citySectorId, citySectorName } = animal;
            const sectorKey = citySectorId || 'unknown'; // Utiliser 'unknown' si citySectorId est vide
            const sectorName = citySectorName || `${t('unknown')}`; // Utiliser 'Inconnu' si citySectorName est vide
            acc[sectorKey] = acc[sectorKey] || { sector: sectorName, count: 0 };
            acc[sectorKey].count += 1;
            return acc;
        }, {});

        return Object.values(countsBySector);
    }, [animals]);


    useEffect(() => {
        if (d3Container.current && pieData.length > 0) {
            // Créer un camembert
            const width = 800;
            const height = 400;
            const margin = 40;
            const radius = Math.min(width, height) / 2 - margin;

            const svg = d3.select(d3Container.current)
                .attr("width", width)
                .attr("height", height)
                .append("g")
                .attr("transform", `translate(${width / 2}, ${height / 2})`);

            const color = d3.scaleOrdinal()
                .domain(pieData.map(d => d.sector))
                .range(d3.schemeCategory10);

            const pie = d3.pie().value(d => d.count);
            const data_ready = pie(pieData);

            svg.selectAll('whatever')
                .data(data_ready)
                .join('path')
                .attr('d', d3.arc()
                    .innerRadius(0)
                    .outerRadius(radius)
                )
                .attr('fill', d => color(d.data.sector))
                .attr("stroke", "white")
                .style("stroke-width", "2px")
                .style("opacity", 0.7);

            // Ajouter des étiquettes aux tranches
            const label = d3.arc()
                .outerRadius(radius)
                .innerRadius(radius - 80);

            svg.selectAll('text')
                .data(data_ready)
                .join('text')
                .attr("transform", d => `translate(${label.centroid(d)})`)
                .call(text => text.append("tspan")
                    .attr("y", "-0.7em")
                    .attr("font-weight", "bold")
                    .text(d => d.data.sector))
                    .style('font-family', 'Wix Madefor Display')
                .call(text => text.append("tspan")
                    .attr("x", 0)
                    .attr("y", "1.4em")
                    .attr("fill-opacity", 0.7)
                    .text(d => d.data.count > 0 || null ? `${d.data.count}` : 0))
                    .attr("font-weight", "bold")
                .style('fill', 'black')
                .style('font-family', 'Wix Madefor Display')



            // Ajouter une légende au graphique
            const legendText = `${t('graph.sectors.catsBySector')}`;
            svg.append("text")
                .attr("x", width / 2 - 130) // Centrer horizontalement
                .attr("y", margin) // Placer en haut avec une marge
                .attr("text-anchor", "middle")
                .style("font-size", "16px")
                .text(legendText)
                .style('fill', 'black')
                .style('font-family', 'Wix Madefor Display')
        }
    }, [pieData]); // Separate useEffect that depends on pieData

    return (
        <>

        {pieData.length === 0 ? (
            <p style={{color: '#000', marginLeft: '1rem'}}>Aucune donnée</p> // Utilisez un élément p pour afficher le message "Aucune donnée"
        ) : (
            <svg ref={d3Container}></svg> // Affiche le SVG uniquement si vous avez des données
        )}
        </>
    );
};

export default PieChart;

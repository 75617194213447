// import React, { useEffect, useRef } from 'react';
// import * as d3 from 'd3';
// import d3LocaleFr from '../../data/fr-FR.json';
// import d3LocaleEn from '../../data/en-EN.json';
// import { useTranslation } from 'react-i18next';

// const IdentificationSexGraph = ({ animals, year }) => {
//     const d3Container = useRef(null);
//     const { t } = useTranslation();

//     const currentLanguage = t('currentLanguage'); // ou t('currentLanguage') si vous stockez la langue actuelle dans vos ressources i18n

//     const d3Locale = currentLanguage === 'fr' ? d3.timeFormatLocale(d3LocaleFr) : d3.timeFormatLocale(d3LocaleEn);
//     const formatMonth = d3Locale.format("%B");

//     useEffect(() => {
//         d3.select(d3Container.current).selectAll("*").remove();
//         if (animals && d3Container.current) {
//             const timeFormat = d3.timeFormatLocale(d3LocaleFr);

//             const strayCounts = Array.from({ length: 12 }, () => ({ Mâle: 0, Femelle: 0, Inconnu: 0 }));
//             const identificationCounts = Array.from({ length: 12 }, () => ({ Mâle: 0, Femelle: 0, Inconnu: 0 }));

//             animals.forEach(animal => {
//                 const month = parseInt(animal.addedDate.split('-')[1], 10) - 1;
//                 const sex = animal.sex || "Inconnu";

//                 if (animal?.addedAsStray && animal.published && animal.addedDate.startsWith(year)) {
//                     strayCounts[month][sex]++;
//                 }
//                 if (animal.dateIdentification && animal.published && animal.addedAsStray && animal.dateIdentification.startsWith(year)) {
//                     identificationCounts[month][sex]++;
//                 }
//             });

//             // Configuration de base pour le graphique D3
//             const margin = { top: 20, right: 30, bottom: 140, left: 90 };
//             const width = 700 - margin.left - margin.right;
//             const height = 470 - margin.top - margin.bottom;
//             const svg = d3.select(d3Container.current)
//                 .attr("width", width + margin.left + margin.right)
//                 .attr("height", height + margin.top + margin.bottom)
//                 .append("g")
//                 .attr("transform", `translate(${margin.left},${margin.top})`);

//             const x = d3.scaleBand()
//                 .range([0, width])
//                 .domain(strayCounts.map((_, i) => formatMonth(new Date(year, i))))
//                 .padding(0.1);
//             const y = d3.scaleLinear()
//                 .domain([0, d3.max([...strayCounts, ...identificationCounts], month => d3.max(["Mâle", "Femelle", "Inconnu"], sex => month[sex]))])
//                 .range([height, 0]);
//             // Créer les groupes de barres pour chaque mois
//             const monthGroups = svg.selectAll(".month-group")
//                 .data(strayCounts)
//                 .enter()
//                 .append("g")
//                 .attr("class", "month-group")
//                 .attr("transform", (d, i) => `translate(${x(formatMonth(new Date(year, i)))},0)`);

//             const sexOrder = ["Mâle", "Femelle", "Inconnu"];
//             const sexColors = { "Mâle": "blue", "Femelle": "pink", "Inconnu": "gray" };
//             ["Mâle", "Femelle", "Inconnu"].forEach(sex => {
//                 monthGroups.each(function (monthData, monthIndex) {
//                     const group = d3.select(this);
//                     let cumulativeHeight = 0;

//                     sexOrder.forEach(sex => {
//                         const count = identificationCounts[monthIndex][sex];

//                         if (count > 0) {
//                             const rectHeight = y(0) - y(count);
//                             const rectY = y(cumulativeHeight);

//                             group.append("rect")
//                                 .attr("y", rectY - rectHeight) // Commence en haut et descend
//                                 .attr("height", rectHeight)
//                                 .attr("width", x.bandwidth())
//                                 .attr("fill", sexColors[sex]);

//                             group.append("text")
//                                 .attr("x", x.bandwidth() / 2)
//                                 .attr("y", rectY - rectHeight / 2) // Position du texte dans le rectangle
//                                 .attr("fill", "white")
//                                 .attr("text-anchor", "middle")
//                                 .text(count);

//                             cumulativeHeight += count; // Mise à jour de la hauteur cumulative
//                         }
//                     });
//                 });
//             });



//             svg.append("g")
//                 .attr("transform", `translate(0,${height})`)
//                 .call(d3.axisBottom(x))
//                 .selectAll("text")
//                 .attr("transform", "translate(-10,0)rotate(-45)")
//                 .style("text-anchor", "end")
//                 .style('fill', 'black')
//                 // .style("stroke", "red")
//                 .style('font-family', 'Wix Madefor Display')



//             // Ajouter l'axe Y
//             const yAxis = svg.append("g")
//                 .call(d3.axisLeft(y))
//                 .style("stroke", "black");

//             // Changer la couleur des lignes (ticks) de l'axe Y
//             yAxis.selectAll(".tick line")
//                 .style("stroke", "black");

//             // Changer la couleur du chemin de l'axe Y
//             yAxis.select(".domain")
//                 .style("stroke", "black")


//             // Créer et ajouter l'axe X
//             const xAxis = svg.append("g")
//                 .attr("transform", `translate(0,${height})`)
//                 .call(d3.axisBottom(x));

//             // Changer la couleur de la ligne de l'axe X en noir
//             xAxis.select(".domain")
//                 .style("stroke", "black");

//             // Changer la couleur du texte de l'axe X en noir
//             xAxis.selectAll(".tick text")
//                 .style("fill", "transparent")
//                 .style('font-family', 'Wix Madefor Display')



//             // Barres
//             svg.selectAll("mybar")
//                 .data(monthGroups)
//                 .join("rect")
//                 .attr("x", (_, i) => x(timeFormat.format("%B")(new Date(year, i))))
//                 .attr("y", d => y(d))
//                 .attr("width", x.bandwidth())
//                 .attr("fill", "#69b3a2")

//             // Ajouter la légende de l'axe X
//             svg.append('text')
//                 .attr('transform', `translate(${width / 2}, ${height + margin.top + 40})`)
//                 .style('text-anchor', 'middle')
//                 .text(`${t('graph.identifications.monthsOfTheYear')}`)
//                 .style('fill', 'black') // Couleur du texte en blanc
//                 .style('font-family', 'Wix Madefor Display')

//             // Ajouter la légende de l'axe Y
//             svg.append('text')
//                 .attr('transform', 'rotate(-90)')
//                 .attr('y', 0 - margin.left + 20)
//                 .attr('x', 0 - (height / 2))
//                 .attr('dy', '1em')
//                 .style('text-anchor', 'middle')
//                 .text(`${t('graph.identifications.numberOfAnimalsIdentificatedBySex')}`)
//                 .style('fill', 'black') // Couleur du texte en blanc
//                 .style('font-family', 'Wix Madefor Display')

//             // Ajouter la légende des couleurs
//             const legend = svg.selectAll('.legend')
//                 .data([{ color: 'blue', text: 'Mâle' }, { color: 'pink', text: 'Femelle' }, { color: 'gray', text: 'Inconnu' }])
//                 .enter().append('g')
//                 .attr('class', 'legend')
//                 .attr('transform', (d, i) => `translate(0, ${i * 20})`)
//                 .style('font-family', 'Wix Madefor Display')




//             legend.append('rect')
//                 .attr('x', width - 18)
//                 .attr("y", 380)
//                 .attr('width', 18)
//                 .attr('height', 18)
//                 .style('fill', d => d.color);

//             legend.append('text')
//                 .attr('x', width - 24)
//                 .attr("y", 390)
//                 .attr('dy', '.35em')
//                 .style("font-size", "12px")
//                 .style('text-anchor', 'end')
//                 .text(d => d.text)
//                 .style('fill', 'black') // Couleur du texte en blanc

//         }


//     }, [animals, year, currentLanguage]);

//     return <svg ref={d3Container}></svg>;
// };

// export default IdentificationSexGraph;


import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import d3LocaleFr from '../../data/fr-FR.json';
import d3LocaleEn from '../../data/en-EN.json';
import { useTranslation } from 'react-i18next';

const IdentificationSexGraph = ({ animals, year }) => {
    const d3Container = useRef(null);
    const { t } = useTranslation();

    const currentLanguage = t('currentLanguage'); // ou t('currentLanguage') si vous stockez la langue actuelle dans vos ressources i18n
    const d3Locale = currentLanguage === 'fr' ? d3.timeFormatLocale(d3LocaleFr) : d3.timeFormatLocale(d3LocaleEn);
    const formatMonth = d3Locale.format("%B");

    useEffect(() => {
        d3.select(d3Container.current).selectAll("*").remove();
        if (animals && d3Container.current) {
            const data = Array.from({ length: 12 }, () => ({ Mâle: 0, Femelle: 0, Inconnu: 0 }));
            animals.forEach(animal => {
                if (animal?.dateIdentification && animal?.dateIdentification?.startsWith(year)) {
                    const month = parseInt(animal?.dateIdentification?.split('-')[1], 10) - 1;
                    const sex = animal.sex || "Inconnu";
                    data[month][sex]++;
                }
            });

            // Configuration de base pour le graphique D3
            const margin = { top: 20, right: 30, bottom: 140, left: 90 };
            const width = 700 - margin.left - margin.right;
            const height = 470 - margin.top - margin.bottom;
            const svg = d3.select(d3Container.current)
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .append("g")
                .attr("transform", `translate(${margin.left},${margin.top})`);

            const x = d3.scaleBand()
                .range([0, width])
                .domain(data.map((_, i) => formatMonth(new Date(year, i))))
                .padding(0.1);
            const y = d3.scaleLinear()
                .domain([0, Math.ceil(d3.max(data, d => d.Mâle + d.Femelle + d.Inconnu))]) // Utiliser Math.ceil pour obtenir des valeurs entières
                .range([height, 0]);

            // Calcul des positions cumulées
            data.forEach(monthData => {
                let cumulative = 0;
                ["Mâle", "Femelle", "Inconnu"].forEach(sex => {
                    monthData[sex + "_cumulative"] = cumulative;
                    cumulative += monthData[sex];
                });
            });

            const monthGroups = svg.selectAll(".month-group")
                .data(data)
                .enter()
                .append("g")
                .attr("class", "month-group")
                .attr("transform", (d, i) => `translate(${x(formatMonth(new Date(year, i)))},0)`);
            const sexColors = { "Mâle": "#325288", "Femelle": "#D96098", "Inconnu": "gray" };

            ["Mâle", "Femelle", "Inconnu"].forEach(sex => {
                monthGroups.each(function (d) {
                    if (d[sex] > 0) {
                        const bar = d3.select(this).append("rect")
                            .attr("y", d => y(d[sex + "_cumulative"] + d[sex]))
                            .attr("height", d => y(d[sex + "_cumulative"]) - y(d[sex + "_cumulative"] + d[sex]))
                            .attr("width", x.bandwidth())
                            .attr("fill", sexColors[sex]);

                        const barY = bar.attr("y");
                        const barHeight = bar.attr("height");

                        // Ajouter un texte seulement si le nombre est supérieur à 0
                        d3.select(this).append("text")
                            .attr("x", x.bandwidth() / 2)
                            .attr("y", +barY + +barHeight / 2)
                            .attr("dy", ".35em")
                            .attr("text-anchor", "middle")
                            .text(d[sex])
                            .attr("fill", "white");
                    }
                });
            });

            svg.append("g")
                .attr("transform", `translate(0,${height})`)
                .call(d3.axisBottom(x))
                .selectAll("text")
                .attr("transform", "translate(-10,0)rotate(-45)")
                .style("text-anchor", "end")
                .style("fill", "black")
                .style("font-family", "Wix Madefor Display");

            // Ajouter l'axe Y
            const yAxis = svg.append("g")
                .call(d3.axisLeft(y).ticks(y.domain()[1])) // Définir les ticks de l'axe Y pour qu'ils soient des entiers
                .style("stroke", "black");

            // Changer la couleur des lignes (ticks) de l'axe Y
            yAxis.selectAll(".tick line")
                .style("stroke", "black");

            // Changer la couleur du chemin de l'axe Y
            yAxis.select(".domain")
                .style("stroke", "black");

            // Ajouter la légende de l'axe X
            svg.append("text")
                .attr("transform", `translate(${width / 2}, ${height + margin.top + 40})`)
                .style("text-anchor", "middle")
                .text(`${t("graph.captures.monthsOfTheYear")}`)
                .style("fill", "black")
                .style("font-family", "Wix Madefor Display");

            // Ajouter la légende de l'axe Y
            svg.append("text")
                .attr("transform", "rotate(-90)")
                .attr("y", 0 - margin.left + 20)
                .attr("x", 0 - height / 2)
                .attr("dy", "1em")
                .style("text-anchor", "middle")
                .text(`${t("graph.identifications.numberOfAnimalsIdentificatedBySex")}`)
                .style("fill", "black")
                .style("font-family", "Wix Madefor Display");

            // Ajouter la légende des couleurs
            const legend = svg
                .selectAll(".legend")
                .data([{ color: "#325288", text: "Mâle" }, { color: "#D96098", text: "Femelle" }, { color: "gray", text: "Inconnu" }])
                .enter()
                .append("g")
                .attr("class", "legend")
                .attr("transform", (d, i) => `translate(0, ${i * 20})`)
                .style("font-family", "Wix Madefor Display");

            legend.append("rect")
                .attr("x", width - 18)
                .attr("y", 380)
                .attr("width", 18)
                .attr("height", 18)
                .style("fill", (d) => d.color);

            legend.append("text")
                .attr("x", width - 24)
                .attr("y", 390)
                .attr("dy", ".35em")
                .style("font-size", "12px")
                .style("text-anchor", "end")
                .text((d) => d.text)
                .style("fill", "black"); // Couleur du texte en blanc
        }
    }, [animals, year, currentLanguage]);

    return <svg ref={d3Container}></svg>;
};

export default IdentificationSexGraph;

import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';

const ErrorBoundaryWrapper = ({ children, isProduction }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <ErrorBoundary location={location} navigate={navigate} isProduction={isProduction}>
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryWrapper;
